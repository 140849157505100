import React, { useState, useEffect} from 'react';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import NavigableSearchbar from '../Common/NavigableSearchbar';
import Loading from '../Loading/Loading';

import LINKHELPERS from '../../helpers/LinkHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import SearchHelpers from '../../helpers/SearchHelpers';
import { appInsightUIMapping, logs,page } from '../../helpers/log';
import { get } from 'lodash';

import { useSearchResultContext } from './../../contexts/SearchResultContext';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import HorizontalScrollbar from './../Article/HorizontalScrollbar';
import CollectionSearchResult from './CollectionSearchResult';
import ReactHtmlParser from 'react-html-parser';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoNotifiation from '../Notification/InfoNotifiation';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ErrorCodes, ErrorMessages, ErrorTypes } from '../Constants/Errors';
import useSearchService from '../../services/useSearchService';

const CollectionSearch = ({
    isWideMode,
    publicationType,
    catalogCollection,
    handleSearchSubmit
}) => {
    const { getLabel } = usePageContextLabels();
    const {
        context, currentSearch, searchPhrase, setSearchPhrase,
        memberFirm, language, knowledgeDomain, pageControllerType,  setPageURLWhereSearchInnitiated, setNotification
    } = usePageContext();
    const navigate = useNavigate();
    const [searchTerms, setSearchTerms] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const location = useLocation();
    const [updateRecentSearches, setUpdateRecentSearches] = useState(0);
    const {
        searchResults,
        setSearchResults,
        filteredSearchResults,
        setFilteredSearchResults,
        areSearchResultsLoading,
        setAreSearchResultsLoading,
        setActiveFilters,
        setSearchTimeStamp,
        showCollectionSearchLimitNotification
    } = useSearchResultContext();
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    const searchService = useSearchService();

    useEffect(() => {
        window.onscroll = function () {
            var ele = document.getElementById('collection-page-search');
            if (ele) {
                if (window.pageYOffset > 60) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };
    }, [])


    useEffect(() => {
        async function getSearchResults() {
            try {
                setAreSearchResultsLoading(true);
                var results = await searchService.getCollectionSearchResponses(context.pageType, currentSearch, get(window,'DART.ENV.REACT_APP_COLLECTION_SEARCH_LIMIT',0), `publication_type/${publicationType}`);

                if (SearchHelpers.hasSearchResults(results)) {
                    setSearchResults(results);
                    setPageURLWhereSearchInnitiated(location.pathname + location.search);
                }
                else {
                    setSearchResults([]);
                    setFilteredSearchResults([]);
                    setAreSearchResultsLoading(false);
                }
            }
            catch (err) {
                logs.error(page.Collection, 'GetSearchResults', ErrorMessages.searchResultsCollection, err,{eventId:ErrorCodes.Collection});
            }
        }

        if (catalogCollection) {
            const catalogCollectionLinks = get(catalogCollection, 'CatalogCollection[0].Content.links', [])
            if ((!catalogCollectionLinks || catalogCollectionLinks?.length === 0)) {
                if (currentSearch.length > 2) {
                    if (SearchHelpers.IsSearchPrefixWithWildcards(currentSearch)) {
                        setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'CollectionSearch' });
                        return;
                    }
                    setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
                    getSearchResults();
                }
            }
        }

        return () => {
            setSearchResults([]);
            setFilteredSearchResults([]);
            setAreSearchResultsLoading(false);
            setActiveFilters([]);
           // setTocControl([]);
        }

    }, [catalogCollection, currentSearch]); //eslint-disable-line


    useEffect(() => {
        //setIsWideMode(false);
        if (!areSearchResultsLoading && filteredSearchResults?.length > 0) {

            const resultsContainer = document.querySelector('.search-results');
            const searchTerms = resultsContainer?.querySelectorAll('em');

            setSearchTerms(searchTerms);
            //setIsFixed(true);
        }

        return () => {
            setSearchTerms([]);
        }
    }, [searchResults, filteredSearchResults]); //eslint-disable-line

    const handleRecentSearchBarSubmit = (event, searchPhraseFromNavigationBarRecentSearches) => {
        if (event) {
            event.preventDefault()
        }

        const _searchPhrase = (searchPhraseFromNavigationBarRecentSearches ? searchPhraseFromNavigationBarRecentSearches : searchPhrase)?.trim();

        setSearchTimeStamp(Date.now())
        if (UTILITIESHELPER.isStringNullorEmpty(_searchPhrase) || _searchPhrase.length < 3) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }

        setUpdateRecentSearches(updateRecentSearches + 1)

        const currentLocation = window.location.pathname;
        if ((currentLocation.indexOf("/collection") > 0) ||
            (currentLocation.indexOf("/manual/") > 0) ||
            (currentLocation.indexOf("/standards/") > 0) ||
            (currentLocation.indexOf("/roadmap/") > 0) ||
            (currentLocation.indexOf("/folio/") > 0)) {

            const parsedSearchPhrase = SearchHelpers.parse(_searchPhrase);
            const readableSearchPhrase = SearchHelpers.toHumanReadable(parsedSearchPhrase);

            setSearchPhrase(readableSearchPhrase);

            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: _searchPhrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }

        return;
    };


    const getSearchTitle = () => {
        return currentSearch ? ReactHtmlParser(`${getLabel("s_SearchResults", "Search Results")} ${getLabel("s_SearchResultsFor", "for")} <em>${SearchHelpers.toHumanReadable(currentSearch)}</em>`) : '';
    }

    function handleClose() {
        setSearchPhrase('')
    }
    function isAllOnOffButtonExist(){
        return document.querySelectorAll('.toc-side-pannel-all-on-off-toggle').length > 0
    }
    function resolveUrlForCollectionSearchResult()
    {
        var resolvedUrlForCollectionSearchResult=[];
        filteredSearchResults.length > 0 && filteredSearchResults.forEach((result, idx) => {
            //There seem to be a LOT of null results, how are null result items getting into the array?!
            if (result !== null) {
                if (result.document?.publicationId === null) {
                    logs.info(page.Collection, 'filteredSearchResults', "Getting null in the publicationId which may break some functionality and hide toc", result);
                }
                let url = LINKHELPERS.resolveCollectionPageSearchLink(result, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection);
                if (url !== undefined && url !== "#") {
                    //Above line will a new property in result ex result.url = /global/en-EN/auditing/manual/publicationNavTitle/publicationGuid/topcNavTitle/topicGuid(which may include spine-target-pub-GUID)
                    resolvedUrlForCollectionSearchResult.push(<CollectionSearchResult key={`csr_${idx}_${result.id}`} {...result} url={url} />);
                }
                else {
                    logs.info(page.Collection, 'filteredSearchResults', "Unresolved search result (BE cannot get resolved target for this search result)", result);
                }
            }
        })
        return resolvedUrlForCollectionSearchResult;
    }
    if (areSearchResultsLoading) return <Loading />;

    return (
        <>
            <ShowIf condition={!areSearchResultsLoading && filteredSearchResults?.length > 0}>
                <HorizontalScrollbar shouldBeFixed={isFixed} />
            </ShowIf >
            <div
                className={cx("article-container cf-right", {
                    "article-container-wide-mode": isWideMode,
                    'invisible': false,
                })}
            >
                <div className={cx("search-results-container", { "search-results-container-wide-mode": isWideMode })}>
                    <form onSubmit={handleSearchSubmit}>
                        <NavigableSearchbar
                            placeholder={`${getLabel("s_Search", "Search")}`}
                            value={searchPhrase}
                            setValue={setSearchPhrase}
                            terms={searchTerms}
                            className={"collection-search-navigablebar"}
                            isExpanded={true}
                            isFixed={isFixed}
                            onClose={handleClose}
                            filteredResultCount={filteredSearchResults?.length}
                            submitFromRecentSearch={handleRecentSearchBarSubmit}
                            triggerUpdateRecentSearches={updateRecentSearches}
                            calledFrom={page.Collection}
                            style={isFixed ? { top: '1.75rem' } : { top: '.5rem', right: '17px' }}
                        />
                    </form>
                    <h1 className="collection-search-result-titles">{getSearchTitle()}</h1>
                    <ShowIf condition={(!filteredSearchResults.length || !searchResults.length) &&  !isAllOnOffButtonExist()}>
                        <div>{getLabel("s_SearchReturnedNoResults", "Your search returned no results")}</div>
                    </ShowIf>
                    <ShowIf condition={(!filteredSearchResults.length || !searchResults.length) && isAllOnOffButtonExist()}>
                        <div className='search-message-ontoggle-alloff'>{getLabel("s_SearchReturnedNoResultsDueToAllOnOff", "Please turn on a toggle in the Table of Contents to view results.")}</div>
                    </ShowIf>
                    <ShowIf condition={showCollectionSearchLimitNotification}>
                        <InfoNotifiation message={getLabel("s_CollectionSearchResultLimitNotification", "The number of search results have been limited to not exceed the page limit. Please search within a specific folio to view all of its results..") } />
                    </ShowIf>
                    <div className="search-results" data-testid="collection-search-results">
                        {resolveUrlForCollectionSearchResult()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionSearch;