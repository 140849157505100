import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ARTICLEHELPER from '../../helpers/ArticleHelpers';
import Figure from './Figure';
import StructuredSection from './StructuredSection';
import StructuredLabels from './StructuredLabels';
import Annotations from './Annotations';
import AssociatedContent from './AssociatedContent';
import RelatedLinks from './RelatedLinks';
import HTMLContent from '../Common/HTMLContent';
import ShowIf from '../Common/ShowIf';
import { isFigureOrSidebar, isFile, isSection } from '../../helpers/getComponentInfo';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { useArticleContext } from '../../contexts/ArticleContext';
import { useEffect } from 'react';
import File from './File';
import { usePageContextLabels } from '../../contexts/PageContext';

// Object Type Constants
const OBJECT_TYPES = {
	RPGuidanceContentBody: 'Deloitte.InK.ResearchPortal.Api.Models.ContentBodyModels.RPGuidanceContentBody',
	PolicyContentBody: 'Deloitte.InK.ResearchPortal.Api.Models.ContentBodyModels.PolicyContentBody',
	RequirementsContentBody: 'Deloitte.InK.ResearchPortal.Api.Models.ContentBodyModels.RequirementsContentBody'
};


function RenderAnnotations(props) {
	if (props.level > 0) {
		return (
			<Annotations item={props.item} publication={props.publication} />
		);
	}

	return null;
}

function RenderRelatedLinks(props) {
	if (props.level > 0) {
		return (
			<RelatedLinks item={props.item} />
		);
	}

	return null;
}

function RenderAssociatedContent(props) {
	if (props.level > 0) {
		return (
			<AssociatedContent item={props.item} publication={props.publication} />
		);
	}

	return null;
}


function RenderItemContent(props) {
	const { searchText } = useArticleContext();

	if (props.level > 0 && props.item?.content) {
		if (isFigureOrSidebar(props.item.content_type)) {
			return <Figure markup={props.markup} item={props.item} isEmbedded={props.isEmbedded} pageType={props.pageType} level={props.level} />
		}

		if ([
			OBJECT_TYPES.RPGuidanceContentBody,
			OBJECT_TYPES.PolicyContentBody,
			OBJECT_TYPES.RequirementsContentBody
		].includes(props.item.content.object_type)
			&& props.level === 1
		) {
			return null;
		}

		return (
			<ShowIf condition={get(props, 'markup')}>
				<div className={props.item.content_type === '18735284'? 'policy' : '' } >
					<HTMLContent>{getTextWithSearchHighlight(props.markup, searchText)}</HTMLContent>
				</div>
			</ShowIf>
		);
	}

	return null;
}

function RenderStructuredLabels(props) {
	//We need/want the labels starting at the first level
	if (props.level > 0 && props.titleRendered) {
		return (
			<StructuredLabels item={props.item} publication={props.publication} />
		);
	}

	return null;
}

function RenderStructuredSections(props) {
	if (props.level > 0 && props.item?.content) {
		var panels = props.item.subItems;

		// To avoid Example's content being repeated (the content_type was added)
		// LEGACY: (props.item.content.object_type === 'Deloitte.InK.ResearchPortal.Api.Models.ContentBodyModels.PolicyContentBody' && props.level === 1)
		if (
			(
				props.item.content.object_type === OBJECT_TYPES.RPGuidanceContentBody
				&& props.level === 1
				&& props.item.content_type !== "48693746"
			) || (
				(props.item.content.object_type === OBJECT_TYPES.RequirementsContentBody
					|| props.item.content.object_type === OBJECT_TYPES.PolicyContentBody)
				&& props.level === 1
			)
		) {
			panels = [props.item];
		}

		//When we have more then 1 section, then we can start with them contracted (when we are on Templates/Q&A)
		const sections = 0 + panels?.length;
		const renderedPanels = panels.map((subItem, index) => (
			<StructuredSection
				key={subItem.id + index}
				item={subItem}
				level={props.level + 1}
				parentTitle={props.parentTitle}
				publication={props.publication}
				isEmbedded={props.isEmbedded}
				pageType={props.pageType}
				sections={sections} />
		))

		return renderedPanels;
	}

	return null;
}

export const StructuredPanel = (props) => {
	const { getLabel } = usePageContextLabels();

	useEffect(() => {
		let figures = document.getElementsByTagName('figure');
		if (figures.length) {
			for (const data of figures) {
				if (data.classList.contains('dots') || data.classList.contains('keys')) {
					const newFig = data;
					const clone = newFig.cloneNode(true);
					let mainDiv = document.createElement('div');
					mainDiv.classList.add("connecting-dots-main");
					let imageDiv = document.createElement('div');
					let image = document.createElement('div');
					imageDiv.appendChild(image);
					image.classList.add("connecting-dots-img");
					let div = document.createElement('div')
					let para = document.createElement("p");
					para.classList.add("connecting-dots");
					if (data.classList.contains('dots')) {
						para.innerText = getLabel("hh_ConnectingTheDots", "Connecting the Dots");
					} else if (data.classList.contains('keys')) {
						para.innerText = getLabel("hh_KeyTakeaways", "Key Takeaways");
					}
					div.appendChild(para);
					div.appendChild(clone);
					mainDiv.appendChild(imageDiv);
					mainDiv.appendChild(div);
					data.replaceWith(mainDiv);
				}
			}
		}
	}, []);//eslint-disable-line

	if (isFile(props.item.content_type, props.item.containsBinary) && !isSection(props.item.content_type)) {
		return (
			<File
				binaryItemGUID={props.item.id}
				fileTitle={props.item.content.display_name || props.item.content.title}
				key={props.item.id}
				format={props.item.content.format}
				date={props.item.content.current_date}
				displayName={props.item.content.display_name}
				description={props.item.content.display_name_desc}
				templateNote={props.item.content.template_note}
				bytes={props.item.content.binary_length}
				uri={props.item.content.uri}
				hasSubItemsOrSiblings={props.item.subItems.length > 0 || props.item.subItems.length > 1}
				shouldIndent={true}
				isDownloadPdfView={true}
				text={props.item.content.text}
				displayDownload={props.item.content.display_download}
			/>
		);
	}
	else {
		var markup = ARTICLEHELPER.createMarkup(props.item)
		// if the components is indexed and has a parent title, 
		// we prepend an index title for display
		const indexTitle = props.isIndexed && props.parentTitle ? props.parentTitle : null;

		// If we ahve an indexTitle and the markup has html
		// Then insert indexTitle into first text item (div.p)
		if (indexTitle !== null && markup.__html) {

			// create temp doc fragment
			const tmpFragment = document.createDocumentFragment();

			// create temp mouting point, set markup as innnerHTML, append to fragment
			const tmpMountPoint = document.createElement('div');
			tmpMountPoint.id = "tmpMount";
			tmpMountPoint.innerHTML = markup.__html;
			tmpFragment.appendChild(tmpMountPoint);

			// create index title element
			const tmpTitle = document.createElement('strong');
			tmpTitle.classList.add('title-indexed-component');
			tmpTitle.innerHTML = indexTitle;

			let firstContentElement = tmpFragment.querySelector('.p:first-child');
			//let firstContentElement = tmpFragment.querySelector('p:first-of-type');
			if (firstContentElement) {
				// firstContentElement.firstChild.prepend(tmpTitle);
				firstContentElement.innerHTML = tmpTitle.outerHTML + ' ' + firstContentElement.innerHTML;
				// update markup object with updated html
				markup = tmpFragment.childNodes[0].innerHTML;

			}
			else {
				//In "compare" it is NOT able to find this first P in some cases, but there "is" content, and there "is" a title to "add" to the output...
				firstContentElement = tmpFragment.querySelector('p:first-of-type');
				if (firstContentElement && firstContentElement.length > 0) {
					firstContentElement.firstChild.prepend(tmpTitle);
					// update markup object with updated html
					markup = tmpFragment.childNodes[0].innerHTML;
				}
			}
		}
		else if (markup !== null && markup.__html !== null)
		{
			markup = markup.__html;
		}
	}



	return (
		<div
			className={props.level <= 1 ? `structured-article-panel ${props.item.subItems.length ? 'subpanel-indentation' : ''}` : `structured-article-subpanel ${props.item.content_type === "48082120" ? "information-topics" : ''}`} >
			<ShowIf condition={!isFigureOrSidebar(props?.item?.content_type)}>
				<RenderStructuredLabels item={props.item} level={props.level} publication={props.publication} titleRendered={props.titleRendered} />
			</ShowIf>
			<RenderItemContent markup={markup} item={props.item} level={props.level} pageType={props.pageType} />
			<RenderStructuredSections item={props.item} level={props.level} parentTitle={props.parentTitle} publication={props.publication} isEmbedded={props.isEmbedded} pageType={props.pageType} />
			{props.level > 1 ?
				(<>
					<RenderAnnotations item={props.item} level={props.level} publication={props.publication} />
					<RenderAssociatedContent item={props.item} level={props.level} publication={props.publication} />
					<RenderRelatedLinks item={props.item} level={props.level} />
				</>
				)
				: null}
		</div>
	);
}

StructuredPanel.propsTypes = {
	title: PropTypes.string,
	typeName: "StructuredPanel"
}

export default StructuredPanel;
