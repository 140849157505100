
import React, { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { logs, page } from '../../helpers/log';

import RETRIEVALSERVICES from '../../services/rp-service';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import TOCHELPERS from '../../helpers/TocHelpers';

import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import JournalArticle from './JournalArticle';
import Loading from '../Loading/Loading';
import Publication from '../Publication';
import TableOfContentsItem from '../Publication/TableOfContentsItem';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import { useLocation } from 'react-router-dom';
import BookmarkService from '../../helpers/BookmarkServices';
import ANNSERVICES from '../../helpers/AnnotationServices';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';


const Journal = () => {
    const navigate = useNavigate();
    const { currentTier, currentIndustry, context, getMemberFirmID } = usePageContext();
    const { journalBrand, knowledgeDomain, language, memberFirm } = useParams();
    const path = "/:memberFirm/:language/:knowledgeDomain/:journalType/:journalBrand";
    const url = `/${memberFirm}/${language}/${knowledgeDomain}/${context.pageControllerType}/${journalBrand}`;
    const routeInfo = useMatch(path);
    const  isExact = routeInfo ? routeInfo.pattern.end : false;


    const { toc, setToc, setTocAnnotations,selectedItemTOC, setSelectedItemTOC, setTocIconBookmark } = useTocContext();//eslint-disable-line
    const { setSearchResults, setFilteredSearchResults, setActiveFilters,setTocControl } = useSearchResultContext();//eslint-disable-line
    const { isAnnotationsEnabled, isBookmarksEnabled,isRestrictedAccessMemberfirm } = usePageContextSettings();
    const routeInfo2 = useMatch('/:memberFirm/:language/:knowledgeDomain/:journalType/:journalBrand/:volume/:uniquePub/*');
    const volume = routeInfo2 ? routeInfo2.params?.volume : context.pageSelectedPublicationGUID;
    const { state: annState, dispatch } = useUserPreferencesContext();
    const location = useLocation();

    useEffect(() => {
        const retreiveAnnotationList = async (_url) => {
            try {
                setTocAnnotations([]);

                const annotations = await ANNSERVICES.fetchAnnotations(location.pathname, getMemberFirmID());
                if (annotations) {
                    setTocAnnotations(annotations);
                }
            }
            catch (err) {
                logs.error(page.JournalArticle,'Journal', ErrorMessages.annotationTOC,err,{eventId:ErrorCodes.JournalArticle});
            }
        }
        const retreiveBookmarkList = async (_url) => {
            try {
                setTocIconBookmark([]);
                const bookmarks = await BookmarkService.fetchBookmarks(location.pathname, getMemberFirmID());
                if (bookmarks) {
                    setTocIconBookmark(bookmarks);
                }
            }
            catch (err) {
                logs.error(page.JournalArticle,'Journal', ErrorMessages.bookmarkTOC,err,{eventId:ErrorCodes.JournalArticle});
            }
        }

        const retrieveJournal = async () => {
            try {
                setToc({});
                const res = await RETRIEVALSERVICES.retrieveJournal();

                if( memberFirm && knowledgeDomain){
                    if(UTILITIESHELPER.isNullOrUndefined(annState.recentHistory) || annState?.recentHistory?.length === 0) {
                        // fetch latest records from DB when list is empty
                        const freshHistoryPayload = await RecentViewedService.fetchHistory(location.pathname, getMemberFirmID());
                        // state create reducer from history from DB
                        dispatch({
                            type: ACTIONS.RECENT_HISTORY_CREATE,
                            payload: freshHistoryPayload
                        });
                    }
                }
                if (res.tableOfContents && res.tableOfContents.subItems.length > 0) {
                    const hashedToc = TOCHELPERS.buildTocHashes(res.tableOfContents);
                    const selectedTocItem = TOCHELPERS.getSelectedTocNode(hashedToc);
                    setToc(hashedToc);

                    if(!UTILITIESHELPER.isStringNullorEmpty(volume))
                    {
                        if(selectedTocItem!==null){
                            
                            setSelectedItemTOC({ item: selectedTocItem });
                        }
                        else{
                             navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href,false));
                        }
                    }
                }
                else {
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href,true));
                }            
            }
            catch (err) {
                logs.error(page.JournalArticle,'Journal', ErrorMessages.journalPublication,err,{eventId:ErrorCodes.JournalArticle});
                navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href,true));
            }
        }

        if (isAnnotationsEnabled() && isRestrictedAccessMemberfirm()) {
            retreiveAnnotationList(url);
        }

        if (isBookmarksEnabled() && isRestrictedAccessMemberfirm()) {
            retreiveBookmarkList(url);
        }

        retrieveJournal();

        return () => {
            //Unload Entire Journals
            setToc({});
            //Unload Search
            sessionStorage.removeItem('searchPhraseParam');
            setSearchResults([]);
            setFilteredSearchResults([]);
            setActiveFilters([]);
            //setTocControl([]);
        }  
        //eslint-disable-next-line
    }, [journalBrand, language, memberFirm, currentTier, currentIndustry])

    const renderTocBody = (contextTOC) => {
        if (!contextTOC || !contextTOC?.subItems?.length) {
            return null;
        }

        return contextTOC.subItems.map((subItem, index) => {
            return (
                <TableOfContentsItem
                    key={`TOC_${subItem.id}_${index}`}
                    item={subItem}
                />
            )
        })
    }

    const pageProps = {
        publicationType: 'journal',
        tocBody: renderTocBody(toc),
        basePath: path,
        pubData: toc,
        url,
        isExact,
        pubLandingUrl: url,
        navTitle: get(toc, 'nav_title', ''),
        pubLandingTitle: get(toc, 'title', ''),
        preface: get(toc, 'abstract', ''),
        hasAllTocDataLoaded: !!toc?.subItems,
        renderArticle: (props) => {
            return <JournalArticle {...props} />
        },
    };

    if (!toc || !toc.subItems) {
        return <Loading />;
    }

    return (
        <Publication {...pageProps} />
    )
};

export default Journal