import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import IconX from '../Common/IconX';
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import RecentSearches from './RecentSearches';
import { useLocation } from 'react-router';
import RECENTSEARCHSERVICES from './../../helpers/RecentSearchService';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import { page } from '../../helpers/log';


function NavigableSearchbar({
    isExpanded,
    isFixed,
    style,
    className,
    placeholder,
    showSearchLink,
    value = "",
    setValue,
    terms,
    onClose,
    triggerUpdateRecentSearches,
    submitFromRecentSearch,
    disableRecentSearch = false,
    calledFrom,
    ...props
}) {
    const inputRef = useRef();
    const [isActive, setIsActive] = useState(isExpanded); //start off with search closed
    const { getLabel } = usePageContextLabels();
    const [localValue, setLocalValue] = useState("") // !!!!!
    const [searchTermIndex, setSearchTermIndex] = useState(-1);
    const [searchTerms, setSearchTerms] = useState(terms);
    
    //const { setOpenMultipleExpandedSections } = useArticleContext();
    const [hasChanged, setHasChanged] = useState(false);
    const { pubInfo, toc } = useTocContext();
    const { context, searchPhrase, pageURLWhereSearchInnitiated, setPageURLWhereSearchInnitiated, getMemberFirmID } = usePageContext();
    const [showRecentHistory, setShowRecentHistory] = useState(false);
    const { state: annState, dispatch } = useUserPreferencesContext();
    const location = useLocation();
    const showRecentSearch = (calledFrom === page.Collection || calledFrom === page.Article) ? true : null;
    const { isRecentSearchEnabled } = usePageContextSettings();
    const isProdSite = UTILITIESHELPER.checkIsProdSite()
    const [height,setHeight] =useState(0);

    const handleClickOutsideSearchInput = useCallback((event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowRecentHistory(false);
        }
    }, [inputRef]);

    const handleFocus = (e) => {
        e.preventDefault()
        if (showRecentHistory === false) {
            setShowRecentHistory(true)
        }
    }

    const handlePrevNext = useCallback((direction, event) => {
        event.preventDefault();

        let index = searchTermIndex;

        if (index >= 0) {
            searchTerms[index].classList.remove('current');
        }

        index += (direction === 'prev') ? -1 : 1;

        if (index < 0) {
            index = searchTerms.length - 1;
        }

        if (index > searchTerms.length - 1) {
            index = 0;
        }

        if (index >= 0) {
            searchTerms[index].classList.add('current');
        }

        setSearchTermIndex(index);

        // if selected term is hidden, expand section
        if (searchTerms[index].offsetParent === null) {
            expandParentSections(index);
            const resultsContainer = document.querySelector('.article-content-container');
            const searchTerms = resultsContainer?.querySelectorAll('.highlight-search');
            setSearchTerms(searchTerms)
            searchTerms[index].classList.add('current');
        }

        setTimeout(() => moveTo(index), 0);
    }, [searchTermIndex, searchTerms])

    const expandParentSections = (index) => {
        const el = searchTerms[index];
        const sectionsToExpand = [];
        let currentEl = el;

        while (currentEl !== null) {
            if (currentEl.hasAttribute('data-expandable-uuid') && !currentEl.classList.contains('open')) {
                sectionsToExpand.push(currentEl.dataset.expandableUuid);
            }
            currentEl = currentEl.parentElement.closest('[data-expandable-uuid]');
        }

        if (sectionsToExpand.length) {
            //setOpenMultipleExpandedSections(sectionsToExpand, true);
        }
    }

    const moveTo = (index) => {
        let offset = searchTerms[index].getBoundingClientRect().top + window.pageYOffset + -130;

        window.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth'
        });
    }

    async function updateRecentSearches(searchphrase) {
        const updatedRecentSearchList = await RECENTSEARCHSERVICES.createOrUpdateRecentSearch(searchphrase,location.pathname)

        dispatch({
            type: ACTIONS.RECENT_SEARCH_LOAD,
            payload: updatedRecentSearchList,
        });
    }

    useEffect(() => {
        setSearchTerms(document?.querySelector('.search-results')?.querySelectorAll('em'));
    }, [props.filteredResultCount]);

    useEffect(() => {
        setIsActive(isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (isActive && inputRef.current) inputRef.current.focus();
        if (!isActive && searchPhrase === "") { //!location.search.includes('searchPhrase')
            if (document.getElementsByClassName('search-badge-article').length === 0) {
                setLocalValue("")
                setValue(localValue);
                sessionStorage.removeItem('searchPhraseParam');
            }
        }
        //eslint-disable-next-line
    }, [isActive])

    useEffect(() => {

        if (!UTILITIESHELPER.isArrayNullorEmpty(terms)) {
            setSearchTerms(terms);
        }

        setSearchTermIndex(-1);
    }, [terms]);

    useEffect(() => {
        UTILITIESHELPER.setLocalStorage("rp-is-search-start", false);
    }, [isFixed]);

    useEffect(() => {
        setSearchTermIndex(-1);
    }, [localValue]);

    useEffect(() => {

        async function getRecentSearches() {
            const RecentSearchdata = await RECENTSEARCHSERVICES.getRecentSearch(location.pathname, getMemberFirmID());
            const RecentSearchList = RecentSearchdata != null ? (RecentSearchdata ?? []) : []

            dispatch({
                type: ACTIONS.RECENT_SEARCH_LOAD,
                payload: RecentSearchList,
            });
        }

        if (isRecentSearchEnabled() && UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch)) {
            getRecentSearches();
        }

        setLocalValue(searchPhrase);

        if (isRecentSearchEnabled() && !UTILITIESHELPER.isStringNullorEmpty(searchPhrase)) {
            updateRecentSearches(searchPhrase);
        }

        document.addEventListener('click', handleClickOutsideSearchInput, false)

        return () => {
            document.removeEventListener('click', handleClickOutsideSearchInput, false)
        }
        //eslint-disable-next-line
    }, [])


    function handleChange(event) {
        if (!UTILITIESHELPER.isNullOrUndefined(pageURLWhereSearchInnitiated) && pageURLWhereSearchInnitiated !== '') {
            const url = new URL(UTILITIESHELPER.getBaseDomain() + pageURLWhereSearchInnitiated);
            const params = new URLSearchParams(url.search);
            params.delete('searchPhrase')
            params.append('searchPhrase', event.target.value);
            var updatedSearchURL = url.pathname + "?" + params.toString();
            setPageURLWhereSearchInnitiated(updatedSearchURL)
        }
        setLocalValue(event.target.value);
        setValue("")
        setHasChanged(true);
        UTILITIESHELPER.setLocalStorage("rp-is-search-start", false);
    }


    const handleClose = () => {
        setValue("")
        setLocalValue("")
        setIsActive(prevVal => !prevVal);
        onClose();
    }

    function getTitle() {
        if (pubInfo?.title?.title) {
            return CONTEXTHELPERS.generateFolioTitleFromFilename(get(context, 'pageFileName', ''));
        }

        return toc?.nav_title || "<unknown>";
    }

    function RecentSearchClickHandler(searchSelected) {
        submitFromRecentSearch(false, searchSelected);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 32) {
            e.preventDefault();
            const { startString, endString } = UTILITIESHELPER.handleSpaceBarOnSearchSubmit(e);
            setLocalValue(startString + ' ' + endString);
            return false;
        }
        if (e.keyCode === 13) {
            setLocalValue(localValue);
            setValue(localValue)
            if (isRecentSearchEnabled()) { 
                updateRecentSearches(localValue);
            }
        }
    }
    
    useEffect(()=>{
        var navHeight=0;
        let previewHeight=document.getElementsByClassName("preview-warning")[0]?.clientHeight
        if(!previewHeight)
        {
            previewHeight=0; 
        }
        let heightOfCrum=document.getElementsByClassName("crumbtrail")[0]?.clientHeight;

        navHeight=previewHeight+ heightOfCrum;
        setHeight(navHeight);
    },[])

    const searchIconStyling= { cursor: isActive ? 'default' : 'pointer' }
    const  imageStyling= { margin: isActive ? 'auto 5px auto 0' : 'auto' }
    const inputSearchboxStyling= { width: isActive ? '100%' : 0, padding: isActive ? '' : 0 }



    return (
        <div className="d-flex">
            <div id="collection-page-search"
                 style={{
                    top: isFixed? height : 15 }}
                className={cx("article-search-container",
                    {
                        "article-search-container-expanded": isActive,
                        "article-search-container-fixed": isFixed,
                        "article-search-box-fixed": isFixed && !isProdSite,
                    }, className
                )}
            >
                <div className="article-search-border">
                    <div className="btn-search-icon-article d-flex ml-auto" style={searchIconStyling} title={isActive ? '' : getLabel('s_Search', 'Search')}>
                        <div className="d-flex w-100 justify-center items-center input-search-container">
                            <button className="search-click d-flex flex-grow justify-center items-center btn-search-click" data-testid="navigable-search-button" onClick={() => setIsActive(true)}>
                                <img style={imageStyling} src="/images/search-default.svg" alt="Search" />
                                <input ref={inputRef} className="input-search-article" placeholder={placeholder} value={localValue || ""} style={inputSearchboxStyling} onChange={handleChange} onKeyDown={handleKeyDown} onClick={(e) => handleFocus(e)} />
                            </button>
                            <ShowIf condition={isActive}>
                                <div className="next-prev-btns d-flex items-stretch">
                                    <button
                                        type="button"
                                        className="next-prev-down"
                                        onClick={(e) => handlePrevNext('next', e)} disabled={!searchTerms?.length}
                                    >
                                        <img src="/images/chevron-right-bl.svg" alt="down" />
                                    </button>
                                    <button
                                        type="button"
                                        className="next-prev-up"
                                        onClick={(e) => handlePrevNext('prev', e)} disabled={!searchTerms?.length}
                                    >
                                        <img src="/images/chevron-right-bl.svg" alt="up" />
                                    </button>
                                </div>
                                <div className="d-flex items-center">
                                    <IconX className="icon-close-x"
                                        onClick={handleClose}
                                    />
                                </div>
                            </ShowIf>
                        </div>
                    </div>
                </div>

                {showRecentHistory && !UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch) &&
                    <RecentSearches recentHistoryList={annState.recentSearch} handleClick={RecentSearchClickHandler} globalRecentSearches={showRecentSearch} />
                }

                <ShowIf condition={isActive && showSearchLink}>
                    {localValue && !hasChanged && (<div className="allresults"><a className="next-prev-more" href={pageURLWhereSearchInnitiated}>{getLabel('s_BackToResults', 'Back to search results')}</a></div>)}
                    {localValue && hasChanged && (<div className="allresults"><a className="next-prev-more" href={pageURLWhereSearchInnitiated}>{getLabel('s_SeeAllResultsIn', 'See all results in')} {getTitle()}</a></div>)}
                </ShowIf>
            </div>
        </div>
    )
}


NavigableSearchbar.defaultProps = {
    // placeholder: "",
    // style: {},
    // className: '',
    // terms: []
    isExpanded: false,
    isFixed: false,
    style: {},
    className: '',
    placeholder: '',
    showSearchLink: false,
    value: '',
    setValue: () => { },
    terms: [],
    onClose: () => { },
    returnUrl: null
}

export default NavigableSearchbar