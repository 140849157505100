export const ErrorCodes = {
  Default:1000,
  LoginPage: 1,
  Annotation: 1010,
  Highlight: 1011,
  Bookmark: 1012,
  Article: 1020,
  Catalog: 1030,
  Collection: 1031,
  Common:1040,
  Header: 1041,
  Link: 1042,
  Search: 1043,
  Folio:1050,
  FolioSectionTOC: 1051,
  FolioArticle: 1052,
  Guidebook:1050,
  GuidebookSectionTOC: 1051,
  GuidebookArticle: 1052,
  Home:1060,
  Journal:1070,
  JournalArticle: 1071,
  Loading:1080,
  Manual:1090, 
  Publication:2000,
  RelatedContent:2010,
  ResearchBoard:2020,
  Search:2025,
  Settings:2030,
  Toolbar:2040,
  Context: 2050,
  PageContext: 2051,
  Helpers:2060,
  History: 2061,
  Services:3000,
  RPService: 3001,
};

export const ErrorTypes = {
  Error: 'error',
  Warning: 'warning',
}

export const ErrorMessages = {
  opmInfo: 'Error checking user profile access',
  tokenAquisitionError : 'Error Acquiring Token for logged in User',
  userRoles : 'Error fetching user roles',
  //download file
  retrieveWholePublicationDownload: "DownloadPanel:Error retreiving whole publication for download:",
  downloadWord: "DownloadPanel:Error converting package to a word document:",
  downloadBinary: "DownloadPanel:Error requesting a binary:",
  downloadZip: "DownloadPanel:Error converting package to a zip document:",
  convertWord : "RP-Services:: Error in Convert word api",  

  //ShareModal
  shareModalCopyText: "CopyText:Unable to copy text:",

  //Collection
  collectionData: "getData::Collection:Error loading collection data",
  //collectionSearch
  searchResultsCollection: "Error loading search results for collection",

  //Catalog/index
  catalogData: "fetchCatalog::Catalog:Error fetching Catalog data",
    recentHistoryData: "fetchRecentHistory::Catalog:Error fetching recent history data",
  catalogSections:"getCatalogSections:Error fetching Catalog data",
    folioSections: "getFolioSections:Error fetching Folio Section data",

  //linkResolver
  //linkResolver: "LinkHandler::Error resolving link:",

  //header
  header: "retreiveResource::Error getting resource",

  //dropdownPreference
  industryList: "Framework and/or Industry Taxonomy was not able to be loaded",

  //contentArticle/index
  contentArticle: "fetchArticle::Error getting content article ",

  //guidebokArticle
  recentlyViewed: "RecentlyViewedHistory:Error Processing RecentlyViewed:",
  guidebookArticle: "retrievePublication::Error getting guidebook publication",
  publicationSearch: "PublicationSearch: Error retreiving search results",

  //guidebbokSectionTOC
  guidebookSection: "Error Getting GuideBookSection",

  //guidebook/index
  guidebookPublication: "retrieveGuidebook::Error setting GuidebookPublication, tPubInfo",

  //homeScreen
  homeScreen: "fetchLanding::Error tracking PageView",

  //journal/index
  annotationTOC: "retreiveAnnotationList::Error setting TocAnnotations",
  bookmarkTOC: "Error setting TocIconBookmark",
  journalPublication: "retrieveJournal::Error getting Journals",

  //journalArticle
  recentlyViewedUpdate: "RecentlyViewedHistory:Error Updating RecentlyViewed",
  journalSearch: "JournalSearch: Error mapping search results",

  //Manual/index
  ManualPublication: "retrieveManual:Error retrieving manual",

  //ManualArticle
  manualArticle: "retrievePublication:Error setting PubData",
  manualSearch: "ManualSearch: Error mapping search results",

  //HighlightOverlay
  highlightOverlay: "saveHighlightHandler:Error saving highlights",

  //ArticlereadingMode
  pdfHandler: "pdfHandler:Error No download delegate is available on page",

  //publicationListing
  PublicationListing: "getTocSectionTitle:Error getGuidebookSectionData", //original
  
  //PublicationListingresults
 // tocSectionTitle: "getTocSectionTitle: Error retreiving TocSectionTitle",
  //publicationSearch: "PublicationSearch: Error retreiving search results",

  //publicationSearch
 // publicationSearch: "PublicationSearch: Error retreiving search results",

  //tocItem
  tocItem: "expandIfChildIsSelected:Error expandIfChildIsSelected",

  //pageCOntext
  settings: "Settings and Member Firms cannot be loaded.",
  resource: "Resource cannot be loaded.",
  footer: "Footer Cannot be Retrieved",

  //annotationServices
  annotationFetch: "Error while retrieving the annotation",
  annotationCreate: "Error while creating the annotation",
  annnotationUpdate: "Error while updating the annotation",
  annotationDelete: "Error while deleting the annotation",

  //ArticleHelper
  tocSectionTitle: "getTocSectionTitle: Error retreiving TocSectionTitle",

  //BookmarkServices
  bookmarkFetch: "Error while retrieving the Bookmark",
  bookmarkCreate: "Error while creating the Bookmark",
  bookmarkDelete: "Error while deleting the Bookmark",

  //CategoryServices
  categoryFetch: "Error while retrieving the Categories",
  categoryCreate: "Error while creating the Categories",
  categoryDelete: "Error while deleting the Categories",
  categoryUpdate: "Error while updating the Categories",

  //JumpLinkHelpers
  jumplinks: "[Error JumpLinkHelpers:hasJumpLinks]",

  //LinkHelper
  searchLinkResolve: "resolveSearchLink::result does NOT have a subject, so cannot be resolved:",
  folioLinkResolve: "Error trying to resolve Folio Link:",
  searchLinkResolve_Folio: "resolveSearchLink::Folio search result does NOT have a found subject, so search result link cannot be resolved:",
  tocLinkResolve: "Cannot find target in the current Table Of Contents on this Page. Therefore cannot resolve/navigate to that location.",
  linkResolver: "LinkHelper:Error resolving Link:",

  //RecentlyViewedServices
  recentlyViewedFetch: "Error while retrieving the recently viewed history",

  //RP-service
  fetchHandleError: "RPService::fetchAndHandleErrors:Error",
  contentErrorMessage: "Oh no, something has gone wrong! Please try again later. If the problem persists, please contact support. Code – ",

};