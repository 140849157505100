
const getIconSrc = (_icon) => {
    switch (_icon) {
        case 'folio':
            return '/images/icon-folios.svg';
        case 'guidebook':
            return '/images/icon-folios.svg';
        case 'journal':
		case 'deloitte-communications':
		case 'professional-news':
            return '/images/icon-communications.svg';
		case 'standards':
			return '/images/icon-refrences.svg';
		case 'roadmap':
        case 'manual':
        default:
            return '/images/icon-roadmaps.svg';
    }
}

// icon change location

export default getIconSrc;
