import { ErrorCodes, ErrorMessages } from "../components/Constants/Errors";
import RETRIEVALSERVICES from "../services/rp-service";
import CONTEXTHELPERS from "./ContextHelpers";
import { logs, page } from "./log";

async function fetchHistory(url, memberFirmId) {
    try {
        const recentSearchUserPreference = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset()
        };
        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistory(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

async function fetchCatalogHistory(url, memberFirmId, resultLimit) {
    try {
        const recentSearchUserPreference = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset(),
            request_count: resultLimit ? resultLimit : 3
        };
        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistory(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

async function fetchHistoryDashboard(url, memberFirmId) {
    try {
        const recentSearchUserPreference =
        {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset(),
            request_count: 50
        };

        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistoryDashbaord(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

async function updateHistoryObject(url, title, memberFirmId) {
    const recentSearchUserPreference = {
        query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
        relative_url: url,
        title: title
    };

    RETRIEVALSERVICES.updateRecentHistory(recentSearchUserPreference);
}

const RecentViewedService = {
    fetchHistory,
    updateHistoryObject,
    fetchHistoryDashboard,
    fetchCatalogHistory
};

export default RecentViewedService;
