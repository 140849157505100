import { useQuery } from 'react-query'
import { get } from 'lodash'
import useDCSService from '../services/useDCSService'
import useSearchService from '../services/useSearchService'
import { usePageContext } from '../contexts/PageContext'
import {
  KNOWLEDGE_DOMAIN,
  PUBTYPES_CATALOG_PAGE,
} from '../components/Constants/Constants'

//['search',catalogSearchPhrase]

const useCatalogSearchQuery = (catalogSearchPhrase, isSearchSubmit) => {
  const { generateQuerySpec, getQueryKey } = useDCSService()
  const { context, getMemberFirmID } = usePageContext()
  const searchService = useSearchService()
  // Convert search phrase to lowercase for cache key
  const normalizedCatalogSearchPhrase = catalogSearchPhrase
    ? catalogSearchPhrase.toLowerCase()
    : ''
  let querySpec = generateQuerySpec([], catalogSearchPhrase)
  let queryKey = querySpec
    ? getQueryKey(querySpec, `CatalogSearch-${normalizedCatalogSearchPhrase}`)
    : `${context.knowledgeDomain}-${getMemberFirmID()}`

  let results

  const retrieveCatalogSearchResults = async (catalogSearchPhrase) => {
    if (context.knowledgeDomain === KNOWLEDGE_DOMAIN.accounting) {
      const [Manuals, Standards, Journals, ExternalNews] = await Promise.all([
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[1],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[2],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[3],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[4],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
      ])

      results = {
        '60194866': Manuals, // Manuals,
        '60194865': Standards, // Standards
        '60194864': Journals, // Deloitte-communications/Journals
        '65511719': ExternalNews, //professional-news
      }
    } else {
      const [Folios, Manuals, Standards, Journals] = await Promise.all([
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[0],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[1],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[2],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
        await searchService.getCatalogSearchResponses(
          PUBTYPES_CATALOG_PAGE[3],
          catalogSearchPhrase,
          get(window, 'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT', 3)
        ),
      ])

      results = {
        folio: Folios, // Folios
        '60194866': Manuals, // Manuals,
        '60194865': Standards, // Standards
        '60194864': Journals, // Deloitte-communications/Journals
      }
    }
    return results
  }

  return useQuery(
    queryKey,
    () => retrieveCatalogSearchResults(catalogSearchPhrase),
    {
      enabled:
        catalogSearchPhrase?.length > 1 &&
        catalogSearchPhrase !== '' &&
        isSearchSubmit === true,
    }
  )
}

export default useCatalogSearchQuery
