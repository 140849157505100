import RETRIEVALSERVICES from "../services/rp-service";
import CONTEXTHELPERS from "./ContextHelpers";


const getRecentSearch = async (url, memberFirmId) => {
    try {
        const recentSearchRequestPayload = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset(),
            request_count: 5
        };

        const RecentSearchData = await RETRIEVALSERVICES.getRecentSearches(recentSearchRequestPayload);

        return RecentSearchData;
    } catch (err) {
        console.warn("Error while retrieving the recentSearch", err);
    }
};

const createOrUpdateRecentSearch = async (searchphrase, url) => {
    try {

        const createOrUpdateRecentSearchRequestPayload = {
            search_phrase: searchphrase,
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url),
            timezone_offset: new Date().getTimezoneOffset()
        }
         var RecentSearchData = await RETRIEVALSERVICES.createOrUpdateRecentSearch(createOrUpdateRecentSearchRequestPayload)

        return RecentSearchData;
    } catch (err) {
        console.warn("Error while updateing the RecentSearches", err);
    }
};

const RECENTSEARCHSERVICES = {
    getRecentSearch,
    createOrUpdateRecentSearch,
};

export default RECENTSEARCHSERVICES;
