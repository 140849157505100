import { useEffect, useState,useRef } from 'react';
import { useQuery } from 'react-query';
import RETRIEVALSERVICES from './rp-service';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import CONTEXTHELPERS from "../helpers/ContextHelpers";
import { FOLIO_SUBJECT_ACCOUNT, FOLIO_SUBJECT_NON_ACCOUNT } from '../components/Constants/Constants';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';
import ProfileHelpers from '../helpers/ProfileHelpers';
import { logs, page } from '../helpers/log';
import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';
import { usePageContext } from '../contexts/PageContext';

/**
 * Custom hook to get User Information API using bearer token obtained from MSAL
 * @returns
 */
function useDCSService() {
    const { apiCall } = useFetchWithMsal();
    const { context, getMemberFirmIDs, getMemberFirmLanguages, getMemberFirmID } = usePageContext();

    const DCSEndPoint = window.DART.ENV.REACT_APP_DCS_ENDPOINT_API;
    const KnowledgeDomain = context.knowledgeDomain;
    const memberfirm = context.memberFirm;
    const language = context.language;
    const SearchEndPoint = window.DART.ENV.REACT_APP_SEARCH_ENDPOINT_API;
    const customheaders = { "INK_CONTENT_PRERELEASE": (window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE ? window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE : window.DART.ENV.RP_REACT_APP_PREVIEW) };
    /**
     * Execute a fetch request with the given options
     * @returns JSON response
     * @param publicationType
     */
    // Used to prevent state updates after unmount
    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    //PRIVATE FUNCTIONS:
    const formatLink = (item, querySpec, collectionType) => {
        if (collectionType) {
            const linkExternal = [collectionType, (item.url.replaceAll('%2f','/'))].join("/");
            const url = [window.location.origin,memberfirm,language,KnowledgeDomain,linkExternal].join("/");
            return {
                linkText: (collectionType === "deloitte-communications" || collectionType === "professional-news") && item?.journal_brand ? item.journal_brand + ": " + item.title : item.title,
                PublicationGUID: item.id,
                PublicationType: UTILITIESHELPER.isArrayNullorEmpty(querySpec.publication_type) ? null : querySpec.publication_type[0],
                linkInternal: {
                    Id: item.id,
                    LinkUrl: (item.url.replaceAll('%2f','/')),
                    PublicationGUID: item.id
                },
                linkDocs: null,
                linkExternal,
                linkAltText: item.alt_title,
                url:url,
                context : item.context
            }
        }

        return item;
    }


    //PUBLIC FUNCTIONS
    const getQueryKey = (querySpec,keyprefix) => {
        let queryKey = `${keyprefix}-${[...querySpec.knowledge_domain]}-${querySpec.member_firm ? [...querySpec.member_firm] : getMemberFirmIDs()}`;
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.publication_type)) {
            queryKey = `${queryKey}-${querySpec.publication_type}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.tier)) {
            queryKey = `${queryKey}-${[...querySpec.tier]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.industry)) {
            queryKey = `${queryKey}-${[...querySpec.industry]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_account)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_account]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_nonaccount)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_nonaccount]}`;
        }
        return queryKey
    }

    const generateQuerySpec = (publicationType, result_limit) => {
        const knowledgeDomainID = CONTEXTHELPERS.getknowledgeDomainId(context.knowledgeDomain);

        let audit_workflow_area_account = [];
        let audit_workflow_area_nonaccount = [];

        //Dynamic queries are only there in Guidebooks (for now)
        if (context.pageControllerType === "folio")
        {
            //e.g. Direction-Supervision-and-Review_66019141-66029848
            const pageDynamicQuery = context.pageFileName.split("_");
            if (pageDynamicQuery.length > 1)
            {
                //e.g. 66019141-66029848
                const pageDynamicCategory = pageDynamicQuery[1].split("-");
                if (pageDynamicCategory.length > 1)
                {
                    //e.g. 66019141
                    switch (pageDynamicCategory[0])
                    {
                        case FOLIO_SUBJECT_ACCOUNT.accountBalance:
                        case FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios:
                        case FOLIO_SUBJECT_ACCOUNT.accountingFolios:
                            audit_workflow_area_account = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;

                        case FOLIO_SUBJECT_NON_ACCOUNT.nonAccountBalance:
                        case FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios:
                        case FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios:
                            audit_workflow_area_nonaccount = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;
                    }
                }
            }
        }
        
        return {
            toc: false,
            result_limit: result_limit,
            member_firm: getMemberFirmIDs(),
            default_language: context.language,
            languages: getMemberFirmLanguages(),
            knowledge_domain: [knowledgeDomainID],
            publication_type: publicationType ? [publicationType] : null,
            tier: CONTEXTHELPERS.getTierIds(context.memberFirm, context.knowledgeDomain),
            industry: CONTEXTHELPERS.getIndustryIds(context.memberFirm, context.knowledgeDomain),
            audit_workflow_area_account,
            audit_workflow_area_nonaccount,
            effective_date: new Date(),
            version: 0
        }
    }

    async function getCatalogSections(requestbody, collectionType) {
        let dataFormated = [];
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        if(KnowledgeDomain === "accounting"){
            collectionType = collectionType === "manual" ? "guidance" : collectionType;
        }
        else{
            collectionType = collectionType === "guidance" ? "manual" : collectionType;
 
        }
        let request = {
            url: `${DCSEndPoint}/catalog/${KnowledgeDomain}/${collectionType}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (UTILITIESHELPER.isNullOrUndefined(data) || data.length === 0) ? [] : data.map(item => formatLink(item, requestbody, collectionType));
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:[retrieveLinks collectionType, dataFormated]:", collectionType, dataFormated);
        }
        catch (error) {
            logs.debug(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections DEBUG ERROR:[collectionType, request, error]:", collectionType, request, error);
            logs.error(page.DCSService, 'getCatalogSections', "useDCSService::getCatalogSections:" + ErrorMessages.catalogSections + " for  collection " + collectionType, error, { eventId: ErrorCodes.Catalog });
            dataFormated = null;
        }

        return dataFormated;
    };

    async function getFolioSection(requestbody, sectionFriendlyPath) {
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(requestbody);
        let request = {
            url: `${DCSEndPoint}/folio/${sectionFriendlyPath}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;
            //logs.debug(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection[sectionFriendlyPath, dataFormated]:", sectionFriendlyPath, dataFormated);
            return dataFormated;
        }
        catch (error) {
            logs.debug(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection DEBUG ERROR:[sectionFriendlyPath, request, error]:", sectionFriendlyPath, request, error);
            logs.error(page.DCSService, 'getFolioSection', "useDCSService::getFolioSection:" + ErrorMessages.folioSections + " for section " + sectionFriendlyPath, error, { eventId: ErrorCodes.FolioSectionTOC });
            //dataFormated = null;
            return [];
        }

    };

    async function retrieveChatBotSearchResult(payload) {
  

        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(payload);
        let request = {
            url: `${SearchEndPoint}/Chatbot/search`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }
       
        //retrieve file and trigger download on user's side
        //Added a try catch and await ,since Promise  .then is not resolving here 
        var response=null;
        try {
             response = await apiCall(request, customheaders);
            
        }
        catch {
            logs.error(page.DCSService, 'RPService', "Error in chatBot search api", request.url);
        }
        finally {
            return response;
        }
    }


    //NOTE: YOU HAVE TO INCLUDE YOUR USE CODE EXACTLY AS THEY APEAR:
    //const { generateQuerySpec, getQueryKey, getCatalogSections, getFolioSection } = useDCSService();
    return {
        //Public Functions
        generateQuerySpec,
        getQueryKey,
        //Retreival Functions
        getCatalogSections,
        getFolioSection,
        retrieveChatBotSearchResult
    };
};

export default useDCSService;
