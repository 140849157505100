

import React, { useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query'
import { get } from 'lodash';
import { logs, page } from '../../helpers/log';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import FolioSectionTOC from './FolioSectionTOC';
import FolioArticle from './FolioArticle';
import Loading from '../Loading/Loading';
import Publication from '../Publication';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import BookmarkService from '../../helpers/BookmarkServices';
import ANNSERVICES from '../../helpers/AnnotationServices';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import { queryClient } from "../../config/queryClientConfig";

const Folio = () => {
    const [hasAllTocDataLoaded, setHasAllTocDataLoaded] = useState(false);
    const [hasLogged, setHasLogged] = useState(false);
    const { isReadingMode, setIsReadingMode } = useReadingModeContext();
    const { context, memberFirm, language, knowledgeDomain,currentTier,currentIndustry} = usePageContext();
    const {  guidebookPublication, setGuidebookPublication, setGuidebookSectionsLoaded, guidebookSections, setGuidebookSections, guidebookToc, setGuidebookToc, guidebookTocLoaded, setGuidebookTocLoaded } = useGuidebookTocContext();
    const { setPubInfo, setToc, setTocAnnotations, setTocIconBookmark} = useTocContext();
    const { setSearchResults, setFilteredSearchResults, setActiveFilters } = useSearchResultContext();
    const { isAnnotationsEnabled, isRecentlyViewedEnabled, isBookmarksEnabled ,isRestrictedAccessMemberfirm} = usePageContextSettings();
    const path = '/:memberFirm/:language/:knowledgeDomain/folio/:guidebookFileName';
    const { state: annState, dispatch } = useUserPreferencesContext();
    const [wasReadingModeOn] = useState(isReadingMode);

    //Handling of dynamic Folios, we need to pull the title from the url rather than the toc in this case.
    function getFolioTitle(folioTitle) {
        if (!UTILITIESHELPER.isStringNullorEmpty(context?.pageFileName) && context?.pageFileName != "Test" && context?.pageFileName != "test") {
            folioTitle = context.pageFileName
                .replace(".cshtml", "")
                .split('_')[0]
                .replaceAll("_", " ")
                .replaceAll("---", "~") //temporary change so that the --- can be ignored by the - removal
                .replaceAll("-", " ")
                .replaceAll("~", " - ");

            //Dynamic folio title comes from teh URL now (we maintain it from the catalog page)
            //folioTitle = UTILITIESHELPER.titleCase(folioTitle);
        }
        return UTILITIESHELPER.isStringNullorEmpty(folioTitle) ? "" : decodeURI(folioTitle);
    }

    const getGuidebookTocBody = (_guidebookSections) => {
        return (
            _guidebookSections.map(currentSection => {
                const sectionFriendlyPath = get(currentSection, 'sectionFriendlyPath');

                return (
                    <FolioSectionTOC
                        key={`gbs_${sectionFriendlyPath}`}
                        basePath={context.pageBaseURL}
                        sectionFriendlyPath={sectionFriendlyPath}
                    />
                )
            })
        )
    }

    useEffect(() => {
        const retreiveAnnotationList = async (_url) => {
            try {
                setTocAnnotations([]);
                const annotations = await ANNSERVICES.fetchAnnotations(context.pageBaseURL);
                annotations && setTocAnnotations(annotations);

            }
            catch (err) {
                logs.error(page.Folio,'Guidebook', ErrorMessages.annotationTOC, err, {eventId:ErrorCodes.Folio});
            }
        }
        const retreiveBookmarkList = async (_url) => {
            try {
                setTocIconBookmark([]);
                const bookmarks = await BookmarkService.fetchBookmarks(context.pageBaseURL);
                bookmarks && setTocIconBookmark(bookmarks);

            }
            catch (err) {
                logs.error(page.Folio,'Guidebook', ErrorMessages.bookmarkTOC, err, {eventId:ErrorCodes.Folio});
            }
        }

        const retrieveGuidebook = async (_url) => {
            setHasLogged(false);
            setHasAllTocDataLoaded(false);
            setGuidebookPublication({});
            setPubInfo({});
            
            //If the reading mdoe was ON, we need to toggle it OFF so that the page loads, and we will toggle it back on when the TOC has loaded!
            if (isReadingMode) {
                setIsReadingMode(false);
            }
            
            try {
                if (Array.isArray(guidebookSections) && guidebookSections.length > 0) {
                    setGuidebookSections([]);
                    setGuidebookToc({});
                    setGuidebookTocLoaded(false);
                    setToc(null);

                    if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
                        setTocAnnotations([]);
                    }
                    if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
                        setTocIconBookmark([]);
                    }
                }
                const res = await RETRIEVALSERVICES.retrieveGuidebook(_url);
                logs.debug(page.Folio, 'retrieveGuidebook', "retrieveGuidebook:Context, res ", context, res);
                setGuidebookPublication(res);
                setPubInfo(res);
            }
            catch (err) {
                logs.error(page.Folio, 'Guidebook', ErrorMessages.guidebookPublication, err, {eventId:ErrorCodes.Folio});
            }
        }

        const retriveRecentlyViewed = async () => {
            if( memberFirm && knowledgeDomain){
                if(UTILITIESHELPER.isNullOrUndefined(annState.recentHistory) || annState?.recentHistory?.length == 0) {
                    // fetch latest records from DB when list is empty
                    const freshHistoryPayload = await RecentViewedService.fetchHistory(context.pageBaseURL);
                    // state create reducer from history from DB
                    dispatch({
                        type: ACTIONS.RECENT_HISTORY_CREATE,
                        payload: freshHistoryPayload
                    });
                }
            }
        }

        (async () => {
            await retrieveGuidebook(context.pageBaseURL);
            if (!isRestrictedAccessMemberfirm()) {
                if (isAnnotationsEnabled()) {
                    await retreiveAnnotationList(context.pageBaseURL);
                }
                if (isBookmarksEnabled()) {
                    await retreiveBookmarkList(context.pageBaseURL);
                }
                if (isRecentlyViewedEnabled()) {
                    await retriveRecentlyViewed();
                }
            }

        })()

        return () => {
            //Unload Search
            sessionStorage.removeItem('searchPhraseParam');
            setSearchResults([]);
            setFilteredSearchResults([]);
            setActiveFilters([]);
            //Unload Guidebook Folio
            setGuidebookSections([]);
            setGuidebookToc({});
            setGuidebookTocLoaded(false);
            setToc(null);
            setGuidebookSectionsLoaded(0);

            if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
                setTocAnnotations([]);
            }
            if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
                setTocIconBookmark([]);
            }
        }
    }, [memberFirm, language, knowledgeDomain,currentTier,currentIndustry])

    useEffect(() => {
        if (Array.isArray(guidebookSections) && guidebookSections.length > 0) {
            const currentGuidebookTocBody = getGuidebookTocBody(guidebookSections)
            setGuidebookToc(currentGuidebookTocBody);
        }

        return() => {
            setGuidebookToc();
        }
    }, [guidebookSections])

    useEffect(() => {
        //In readMode - this never gets loaded, so we never actually get this being true!
        if (guidebookTocLoaded) {
            setToc(guidebookSections);
            setHasAllTocDataLoaded(true);

            //If the reading mode was on - turn it back on (now that the TOC is loaded, we can now load the rest of the page)
            //  the user will see the page load, and the TOC present for a millisecond before th reading mode is entered!
            if (wasReadingModeOn) {
                setIsReadingMode(true);
            }

            //We only log if we have not already, and when we land ONLY on the Folio itself... NOT in a section or have an article selected
            //SO - if we land on an article/content, or if we refresh out page, we will NOT be re-logging (as the logging of that article is logged on the article itself!)
            if (!hasLogged && context.pageSelectedSection === "") {
                setHasLogged(true);

                const folioTitle = getFolioTitle(get(guidebookPublication, 'title.title', ''));

                logs.trackPageView({
                    name: 'Folio - ' + folioTitle,
                    properties: {
                        memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                        serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                        level: UTILITIESHELPER.getSessionStorage('Level')
                    }
                })
            }
        }
    }, [guidebookTocLoaded]);

    const pageProps = {
        basePath: path,
        publicationType: 'folio',
        //INHERIT:
        tocBody: guidebookToc,
        //INHERIT:
        pubData: guidebookPublication,
        url:context.pageBaseURL,
        pubLandingUrl: context.pageBaseURL, //WHY Do we need both of these?!!
        isExact: (context.pageSelectedSection === ""),
        navTitle: getFolioTitle(get(guidebookPublication, 'title.title', '')),
        pubLandingTitle: getFolioTitle(get(guidebookPublication, 'title.title', '')),
        preface: "",
        hasAllTocDataLoaded,
        renderArticle: (props) => {
            return <FolioArticle {...props} />
        },
    };
   
    if (!Array.isArray(guidebookToc) || guidebookToc.length === 0) {
        return <Loading />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Publication {...pageProps} />
        </QueryClientProvider>
    )

};

export default Folio;
