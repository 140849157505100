import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";

const TriggerEl = ({
    placeholder,
    getSearchWord,
    searchValue,
    showContainerValue,
}) => {
    const inputRef = useRef(null);

    useOutsideClick(inputRef, () => {
        showContainerValue(false);
    });

    function triggerOnClickHandler() {
        showContainerValue((preVal) => {
            !preVal === false && inputRef.current.blur();
            return !preVal;
        });
    }

    function onEnterKeyPress(event) {
        if (event.key === "Enter") {
            inputRef.current.focus();
            showContainerValue(true);
        }
    }

    return (
        <div
            className="input-dropdown"
            tabIndex="0"
            onClick={triggerOnClickHandler}
            onKeyPress={onEnterKeyPress}
        >
            <input
                ref={inputRef}
                type="text"
                className="collection-dropdown-input"
                autoCapitalize="none"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                aria-autocomplete="list"
                placeholder={placeholder}
                onChange={(e) => getSearchWord(e.target.value)}
                value={searchValue}
                tabIndex="-1"
            />
        </div>
    );
};

const CollectionDropdown = ({ placeholder, options }) => {
    const [value, setValue] = useState("");
    const [isShowContainer, setIsShowContainer] = useState(false);

    function ListItem({ item }) {
        return (
            <Link
                to={item.href}
                tabIndex="-1"
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        window.location.href = item.href;
                    }
                }}
            >
                <div className="collection-menu-option" tabIndex="0">
                    {item.name}
                </div>
            </Link>
        );
    }

    const OptionsList = ({ options }) => {
        let filteredList = options.filter(
            (option) =>
                option.name.toLowerCase().includes(value.toLowerCase()) || !value
        );
         if (filteredList.length > 0) {
            filteredList = [...filteredList].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
            );
        }
        return (
            <>
                {filteredList.length > 0 ? (
                    filteredList.map((item) => <ListItem item={item} key={item.name} />)
                ) : (
                    <div className="collection-menu-option no-options">{"No option"}</div>
                )}
            </>
        );
    };

    function getSearchWord(val) {
        setValue(val);
    }

    function getContainerShowVal(isShow) {
        setIsShowContainer(isShow);
    }

    const onKeyboardUpAndDown = useCallback((e) => {
        const itemList = document.querySelectorAll(".collection-menu-list a");
        if (itemList && itemList.length) {
            const items = Array.from(itemList);
            if (!items.length) return
            let index = -1;
            if (e.target.type !== 'text')
                index = items.indexOf(e.target);
            if (e.keyCode === 38 && index > 0) {
                index--;
                addFocusStyleSheet(index, items);
            } else if (e.keyCode === 40 && index < items.length - 1) {
                index++;
                addFocusStyleSheet(index, items);
            }
            if (e.keyCode === 40 || e.keyCode === 38) {
                e.preventDefault();
            }
        }
    },[]);

    function addFocusStyleSheet(index, items) {
        items.forEach((item) => { item.getElementsByTagName('div')[0].classList.remove("collection-menu-option-focus-style"); });
        items[index].getElementsByTagName('div')[0].classList.add("collection-menu-option-focus-style");
        items[index].focus();
    }

    useEffect(() => {
        const dropdownListContainer = document.querySelector(".catalog-box");
        dropdownListContainer.addEventListener('keydown', onKeyboardUpAndDown);
        return () => {
            dropdownListContainer.removeEventListener('keydown', onKeyboardUpAndDown);
        }
    }, [onKeyboardUpAndDown])

    return (
        <div className="dd__collection-trigger ">
            <TriggerEl
                placeholder={placeholder}
                getSearchWord={getSearchWord}
                searchValue={value}
                showContainerValue={getContainerShowVal}
            />

            {isShowContainer && (
                <div className="collection-menu">
                    <div className="collection-menu-list">
                        <OptionsList options={options} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CollectionDropdown;
