import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { get } from 'lodash';
import { getArticleTitle } from './Article';
import { useTocContext } from '../../contexts/TocContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import Loading from '../Loading/Loading';
import PublicationLayout from './PublicationLayout';
import PublicationLandingMainPannel from './PublicationLandingMainPannel';
import PublicationSearch from './PublicationSearch';
import PublicationListing from './PublicationListing';
import RenderAllContainer from '../Article/RenderAllContainer';
import TableOfContentsItem from './TableOfContentsItem';
import FolioTableOfContentsItem from '../Folio/FolioTableOfContentsItem';
import TocItemRelatedContent from '../RelatedContent/TocItemRelatedContent';
import ArticleCompareVersions from '../ArticleCompareVersions';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import { usePageContext } from '../../contexts/PageContext';
import TocHelpers from '../../helpers/TocHelpers';

const getTocItems = (_context, _selectedItemTOC, basePath, isRelatedLinksPage) => {
    if (_selectedItemTOC.item !== undefined && _selectedItemTOC.item?.tableOfContents !== undefined) {
        // Filter out invalid tableOfContents content by checking if tableOfContents object has values or not
        _selectedItemTOC.item.tableOfContents = _selectedItemTOC.item.tableOfContents?.filter(tocContent => tocContent !== null);
    }

    switch (_context.pageControllerType) {
        case 'journal':
        case "deloitte-communications":
        case "professional-news":
            if (_selectedItemTOC.item !== undefined && _selectedItemTOC.item?.subItems !== undefined) {
                //TODO: See if we combine the two TOC methods of presentation?
                return get(_selectedItemTOC.item, 'subItems', []).map((subItem, index) => {
                    return (
                        <TableOfContentsItem
                            key={`LST_${subItem.id}_${index}`}
                            item={subItem}
                            isListingPage={true}
                        />
                    )
                })
            }
            break;

        case 'folio':
            if (isRelatedLinksPage) {
                //If we have a selected a section and need to group all links (Manuals/Standards)
                return _selectedItemTOC.item.context?.map(item => {
                    if (_context.pageSelectedPublicationGUID === "" || _context.pageSelectedPublicationGUID === item.publicationType.Key) {                            
                        if (_selectedItemTOC.item.tableOfContents.some((tableOfContentsItem) => tableOfContentsItem.publication_type === item.publicationType.Key)) {
                            item.subItems = _selectedItemTOC.item.tableOfContents.filter(tableOfContentsItem => tableOfContentsItem.publication_type == item.publicationType.Key)

                            return (
                                <TocItemRelatedContent key={item.publicationType.Key} {...item} sectionData={_selectedItemTOC.item} basePath={basePath} includeRoot={_context.pageSelectedPublicationGUID === ""} isRelatedLinksPage={isRelatedLinksPage} shouldHighlightSelected={false} />
                            )
                        }
                    }
                });
            }
            if (get(_selectedItemTOC.item, 'tableOfContents', false)) {
                if (_context.pageSelectedSection === "templates" && _selectedItemTOC.item?.tableOfContents[0]?.publication_type !== "66029858") {
                    //Check and remove duplicates, and sort...
                    let templates = [];
                    let included = "";
                    _selectedItemTOC.item.tableOfContents.map((tableOfContentItem) => {
                        tableOfContentItem.subItems.map((subItem) => {
                            if(included.indexOf(subItem.id) < 0) {
                                subItem.tocID = tableOfContentItem.id;
                                subItem.basePath = tableOfContentItem.url;
        
                                included = `${included}|${subItem.id}`;
                                templates.push(subItem);
                            }
                        })
                    })
                    templates.sort((a, b) => a.nav_title.localeCompare(b.nav_title));
        
                    return templates.map((subItem, index) => {
                        return (
                            <FolioTableOfContentsItem
                                key={`TOC_${subItem.tocID}_${subItem.id}_${index}`}
                                basePath={`${subItem.basePath}`}
                                item={subItem}
                                includeSubItems={false}
                                sectionFriendlyPath={templates}
                                isPubExpandable={false}
                            />
                        )
                    })
                }
                return _selectedItemTOC.item.tableOfContents.map((toc, index) => {
                    //Templates, Non-TEO Guide publicaitons, need to be a list
                    if (_context.pageSelectedSection === "templates" && toc.publication_type !== "66029858") {
                        return toc.subItems.map((subItem) => {
                            return (
                                <FolioTableOfContentsItem
                                    key={`TOC_${toc.id}_${subItem.id}_${index}`}
                                    basePath={`${toc.url}`}
                                    item={subItem}
                                    includeSubItems={false}
                                    sectionFriendlyPath="templates"
                                    isPubExpandable={false}
                                />
                            )
        
                        })
                    }
                    else {
                        return (
                            <FolioTableOfContentsItem
                                key={`LST_${toc.id}_${index}`}
                                item={toc}
                                includeSubItems={_context.pageSelectedSection !== "alerts"}
                                isListingPage={true}
                                shouldHighlightSelected={false}
                                isPubExpandable={toc.isExpandable}
                            />
                        )
                    }
                })
            }
            return get(_selectedItemTOC.item, 'subItems', []).map((subItem, index) => {
                return (
                    <FolioTableOfContentsItem
                        key={`LST_${subItem.id}_${index}`}
                        item={subItem}
                        includeSubItems={false}
                        isListingPage={true}
                        shouldHighlightSelected={false}
                        basePath={_selectedItemTOC.item.url} 
                    />
                )
            });
            

        case 'guide':
            if (get(_selectedItemTOC.item, 'tableOfContents', false)) {
                return _selectedItemTOC.item.tableOfContents.map((toc, index) => {
                    return (
                        <TableOfContentsItem
                            key={`LST_${toc.id}_${index}`}
                            item={toc}
                            includeSubItems={true}
                            isListingPage={true}
                            shouldHighlightSelected={false}
                            isRelatedLinksPage={isRelatedLinksPage}
                        />
                    )
                })
            }
            return get(_selectedItemTOC.item, 'subItems', []).map((subItem, index) => {
                return (
                    <TableOfContentsItem
                        key={`LST_${subItem.id}_${index}`}
                        item={subItem}
                        includeSubItems={_context.pageControllerType !== 'folio' || !!_selectedItemTOC?.isFolioSection}
                        isListingPage={true}
                        shouldHighlightSelected={false}
                        isRelatedLinksPage={isRelatedLinksPage}
                    />
                )
            });

        default:
            return get(_selectedItemTOC.item, 'subItems', []).map((subItem, index) => {
                return (
                    <TableOfContentsItem
                        key={`LST_${subItem.id}_${index}`}
                        item={subItem}
                        includeSubItems={_context.pageControllerType !== 'folio' || !!_selectedItemTOC?.isFolioSection}
                        isListingPage={true}
                        shouldHighlightSelected={false}
                    />
                )
            })
    }

    return null;
}

const Publication = ({
    publicationType,
    tocBody,
    basePath,
    currentAsOfDate,
    pubData,
    url,
    isExact,
    navTitle,
    pubLandingTitle,
    preface,
    renderArticle,
    pubLandingUrl,
    hasAllTocDataLoaded,
}) => {

    const navigate = useNavigate();
    const { context, compareVersionParam, isWormholeActive, isFolioSectionlisting, isRelatedLinksPage, isSpinePage } = usePageContext();
    const { toc, selectedItemTOC, setSelectedItemTOC, isLandingPageSelected } = useTocContext();
    const { setIsArticle } = useArticleContext();
    const { isReadingMode } = useReadingModeContext();
    const [shouldBeFixed, setShouldBeFixed] = useState(false);
    const [isJumpLinksOpen, setIsJumpLinksOpen] = useState(false);
    const [isWideMode, setIsWideMode] = useState(false)
    const [hasFetchedArticleData, setHasFetchedArticleData] = useState(false);
    const [yAxisOfToc] = useState(0);
    const [tocMaxHeight] = useState('none');

    const routeInfo = useMatch(basePath);
    const isLandingPage = routeInfo ? routeInfo.pattern.end : false;
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    
    //TODO: This should be part of SearchResultContext!
    //TODO: We should have two flags, isSearchPage, and isSearchActive (one to know we are on the search page, the other to know we have an active search, so we can "keep" the search bar alive)
    //Sets Session Storage's 'searchPhraseParam' to the publication-level search query value, so it can be fed through to the article
    const searchPhraseParam = queryParams.get('searchPhrase');
    if (searchPhraseParam !== null) {
        sessionStorage.setItem('searchPhraseParam', searchPhraseParam);
    }
    //If we have a selected article, then we are NOT "on" a search page, we are coming from search into the article, so need to present as article, with the search
    var isSearchPage = context.pageSelectedContentItemGUID === "" && searchPhraseParam !== null;
    const selectedItemContentType = get(selectedItemTOC, 'item.content_type', 'BLANK');

    //This just means the current TOC has children (does NOT mean that the current item may/may-not be a spine-target-publication/topic/article page!!!)
    const hasSubItems = (
        (
            context.pageControllerType === 'folio'
            && !!get(selectedItemTOC, 'item.tableOfContents', false)
            && selectedItemTOC.item.tableOfContents.length > 0
        )
        || (
            !!selectedItemTOC
            && !!get(selectedItemTOC, 'item.subItems', false)
            && selectedItemTOC.item.subItems.length > 0
        ));

    const isListingCandidate = (
        isRelatedLinksPage
        || ['folio'].indexOf(context.pageControllerType) > -1
        || get(selectedItemTOC, 'includeSubItems', false)
        || selectedItemTOC?.item?.isExpandable
        || selectedItemTOC?.hasOwnProperty('sectionFriendlyPath')
    );

    //Listing Pages: 
    //  [17377912] section topic - expand toc, display title and listing page links as article (No article content)
    //  [65469055] section with intro topic - expand toc, display title, body, and listing page links
    //Non-Listing Pages
    //  [60247324] pub context topic - expand toc, display title, pub context as article on right (No Listing) (can be a parent OR a leaf!)
    //  [*63887580] article - expand toc, display title, body  (No Listing) [NB - This one only gets handled right if it was a leaf node!]
    //  [17323604] Template - expand toc, display title, body - like article but has a binary instead of text (No Listing)
    const explicitArticleOverride = (isSpinePage && "63887580|60247324|17323604".indexOf(selectedItemContentType) > -1);

    var isSectionlisting = isFolioSectionlisting;
    var isJournalListing = false;
    var isJournalArticle = false;

    switch (context.pageControllerType) {
        case 'folio':
            //If we are in the folio, and we are in the alerts section (Treat as Journals)
            if (context.pageSelectedSection === "alerts") {
                if (context.pageSelectedPublicationGUID === "") {
                    //We have NO seleected journal/alert, so we are on the listing page
                    isJournalListing = true;
                }
                else {
                    //We have a selected journal/alert, so we are on the article page
                    isJournalArticle = true;
                    isSectionlisting = false;
                    isSearchPage = false;
                }
            }
            break;
            
        case 'journal':
        case "deloitte-communications":
        case "professional-news":
            if (context.pageSelectedPublicationGUID !== "" && context.pageSelectedContentItemGUID === "") {
                isJournalListing = true;
            }
            if (context.pageSelectedPublicationGUID !== "" && context.pageSelectedContentItemGUID !== "") {
                isJournalArticle = true;
                isSectionlisting = false;
            }
            break;
        default :
           break;
    }

    const isListingPage = isSectionlisting || (!explicitArticleOverride && !isJournalArticle && (isJournalListing || (hasSubItems && isListingCandidate)));
    //This checks ONLY for a valid TOC... which could be Section or Publicaiton or even topic! It does NOT mean that we have an article!
    const validSelectedItem = (!UTILITIESHELPER.isObjectNullorEmpty(selectedItemTOC?.item) ?Object.keys(selectedItemTOC?.item).length > 0 : false ); //(selectedItemContentType !== "BLANK");
    //If we have a selected publication AND content GUID, then we are ON AN ARTICLE (or in the case of manuals, could still be on a child-listing page)!! 
    //  Exceptions are:
    //      when we are on a folio/alert/pub(which is the content, but thats why we will also check for isJournalArticle first in the next check)
    //      when we are in a manual/child/child - PARENT (manual/child, when child has a child) - Then we are a listing page! (isListingPage should be tru there, so isArticle should then still be false)
    //const isArticleCandidate = (context.pageSelectedPublicationGUID !== "" && context.pageSelectedContentItemGUID !== "");
    // ?? explicitArticleOverride
    const isArticlePage = isJournalArticle || (!isExact && !isListingPage && validSelectedItem); // && isArticleCandidate);

    useEffect(() => {
        setIsArticle(isArticlePage)
    }, [isArticlePage]); //setIsArticle

    useEffect(() => {
        return () => {
            //This is for when we LEAVE the controller (Folio/Manual/Standard/etc)
            setSelectedItemTOC({});
        }
    }, [context.pageControllerType]);
    
    
    const pageProps = {
        publicationType,
        tocBody,
        basePath,
        currentAsOfDate,
        pubData,
        url,
        pubLandingUrl,
        shouldBeFixed, setShouldBeFixed,
        isJumpLinksOpen, setIsJumpLinksOpen,
        isWideMode, setIsWideMode,
        isArticlePage,
        navTitle,
        pubLandingTitle,
        preface,
        hasFetchedArticleData,
        setHasFetchedArticleData,
        hasAllTocDataLoaded,
        yAxisOfToc,
        tocMaxHeight
    }

    if (compareVersionParam) {
        return <ArticleCompareVersions />
    }

    const wormHole = () => {
        //If we have no selected TOC in the current page, find out if we "needed" to have one selected for the current page type...
        //  if there was no "article" selected, then we are perhaps landing on the landing page, so no need to wormhole!
        //  but for example: if we were attempting to land on an article (context.pageSelectedContentItemGUID) and we do not have a TOC item "selected"
        //      then we wormhole out (if we were on a section, or a publicaiotn root, and the publicaion is not there, then we also may have an issue!)        
        switch (context.pageType) {
            //case "journal" :
            //case "standards":
            //case "manual":
            case "folio": {
                if (isLandingPageSelected) {
                    return false;
                }
                //Below logic will wormhole out when TOC do not have publication
                if (!UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedPublicationGUID)){
                    const matchedTocItem = TocHelpers.getFlatTocArray(toc)?.find(x => decodeURI(location.pathname) === decodeURI(x?.url));
                    if (!matchedTocItem) {
                        return true;
                    }
                }
                //Below logic will wormhole out when TOC do not have section
                else if (!UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedSection)) {
                    let tocArray = toc;
                    if (!Array.isArray(tocArray)) {
                        tocArray = [tocArray];
                    }
                    let item = tocArray?.find(x => x.sectionFriendlyPath === context.pageSelectedSection);
                    if (!item) {
                        return true;
                    }
                }
                //For a folio, if we had a selected content item, then we wormhole!
                return !UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedContentItemGUID);
            }
            default: return false;
        }
    };
    
    const renderMainPannel = (
        _isSearchPage,
        _isListingPage,
        _isArticlePage,
        _isReadingMode,
        _isRelatedLinksPage
    ) => {

        //If we are on the search/landing page, or have wormhole, then we present the search landing page.
        if ((_isSearchPage && !_isListingPage) || isWormholeActive) {
            //BDP:TODO: This gets hit a few times, presents search before listing and then article - all are presented at some point when clicking on a search result to alerts!
            //console.log("renderMainPannel::_isSearchPage && !_isListingPage _isArticlePage, selectedToc, context", _isSearchPage, _isListingPage, _isArticlePage, selectedItemTOC, context)
            return <PublicationSearch {...pageProps} />
        }

        try {
            //This gets "hit" several times while the page is loading, but we really can ONLY process it when we have the selectedItemTOC object!
            //While we do not have a selectedItemTOC (while the toc/page is loading) - we will present the shimmer
            if (!selectedItemTOC?.item && (selectedItemTOC?.item === undefined || Object.keys(selectedItemTOC?.item).length === 0)) {
                //If we have loaded the TOC already (and still don't yet have a selectedItemTOC?.item)
                if (toc !== null) {
                    //WE do not have to search to "find" the context.pageSelectedPublicationID/context.pageSelectedContentID in the current TOC...
                    //  If the toc has loaded, and there is still no selectedItemTOC.item, then we know that it could not be found there already!
                    //  SO... If we expect to have a selectedItemTOC because we have that in the URL, we have loaded the TOC and we still have nothing "selected"
                    if (wormHole()) {
                        //As we need to handle "all" page types, we can assume that if the current page url had a pageSelectedPublicationGUID, or a pageSelectedPublicationGUID
                        //  that we expect to then have a valid selected TOC by this time... this would mean that we were not able to select the TOC item... 
                        //  we just know then that we have to worm hole out, as we cannot load content!
                        navigate(context.pageBaseURL + "?wormhole=true");
                    }
                    else {
                        if (!UTILITIESHELPER.isStringNullorEmpty(context.pageRequestURL) && context.pageRequestURL.includes("?wormhole=true")) {
                            navigate(context.pageBaseURL);
                        }
                    }
                }

                //We may want to return the normal loading circle, but for now, we return the shimmer
                return (
                    <div className="article-container cf-right shimmerArticleCard shimmerArticleWrapper">
                        <h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine"><span className="ph"></span></h2>
                        <div className="article-content-container">
                            <div className="article">
                                <div className="body">
                                    <div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
                                    <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                    <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                    <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                    <div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        catch
        {
            navigate(context.pageBaseURL + "?wormhole=true");
        }

        if (_isListingPage) {
            const tocItems = getTocItems(context, selectedItemTOC, pubLandingUrl, isRelatedLinksPage)
            if (tocItems !== null) {
                return <PublicationListing
                    {...pageProps}
                    tocItems={tocItems}
                />
            }
        }

        if (_isArticlePage) {
            const renderArticleContent = (pubData) => <RenderAllContainer pubData={pubData} excludeBinaries={false} />;
            const _getArticleTitle = getArticleTitle;
            const getArticleHasLoaded = (pubData) => !!pubData?.content;
            return renderArticle({ basePath: url, ...pageProps, renderArticleContent, getArticleTitle: _getArticleTitle, getArticleHasLoaded, yAxisOfToc, tocMaxHeight })
        }

        // I observed that an article can take a few moments for it to load
        // and since Reading Mode is so bare bones, there was almost no content on the page before the article was fetched
        // so I thought a loading indicator would be helpful
        if (_isReadingMode) {
            return <Loading />
        }

        return null;
    };

    const renderPublicationMainLanding = () => {
        if (isLandingPage) {
            if (isSearchPage) {
                return <PublicationSearch {...pageProps} />
            } else {
                return <PublicationLandingMainPannel {...pageProps} />
            }
        }
        else {
            return renderMainPannel(isSearchPage, isListingPage, isArticlePage, isReadingMode, isRelatedLinksPage);
        }
    }

    return (
        <Fragment>
            <PublicationLayout {...pageProps}>
                {renderPublicationMainLanding()}
            </PublicationLayout>
        </Fragment>
    )
};

Publication.defaultProps = {
    renderArticle: () => { },
}

export default Publication;
