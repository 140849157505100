
import React, { useState, useEffect } from 'react';
import { throttle, get } from 'lodash';
import cx from 'classnames';
import { logs, page } from '../../helpers/log';

import HorizontalScrollbar from '../Article/HorizontalScrollbar';
import JumpLinksList from '../Article/JumpLinksList';
import ToggleReadingMode from '../Article/ToggleReadingMode';
import ShowIf from '../Common/ShowIf';
import { getIsArticlePdf } from './Article';
import { getJumpLinks, hasJumpLinks } from '../../helpers/JumpLinkHelpers';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import getIconSrc from '../../helpers/getIconSrc';
import PrevNextArticle from './PrevNextArticle';
import FootnotePopup from '../Article/FootnotePopup';
import ShareModal from '../Article/ShareModal';
import Modal from '../Modal/Modal';
import Toolbar from '../Toolbar/Toolbar';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import DownloadPanel from '../Article/DownloadPanel';
import { debounce } from 'lodash'
import DOWNLOADHELPERS from '../../helpers/DownloadHelpers';
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';
import HiddenMetadata from './HiddenMetadata';

const getInArticleTOCReadingModeElem = () => document.querySelector('.in-article-toc-reading-mode');

const ReadingModeHeader = ({
    articleTitle,
    showInArticleTOC,
    setShowInArticleTOC,
    isArticlePdf,
    setIsWideMode,
    overrideShowInArticleTOC,
    shouldBeFixed,
    setShouldBeFixed
}) => {
    const { setIsReadingMode } = useReadingModeContext();
    const { getLabel } = usePageContextLabels();

    const adjustSidebarHeight = () => {
        if (!showInArticleTOC) return;

        const el = document.querySelector('.article-container-reading-mode')
        const inArticleTocElem = getInArticleTOCReadingModeElem();
        if (!inArticleTocElem || !el) return;

        var rect = el.getBoundingClientRect();
        let fullScrollOffset = window.innerHeight - rect.bottom
        const footerHeight = 0;
        fullScrollOffset = fullScrollOffset > 0 ? fullScrollOffset - footerHeight > 0 ? fullScrollOffset - footerHeight + 1 : fullScrollOffset + 1 : 0
        inArticleTocElem.style.maxHeight = `calc(100vh - 48px - ${Math.round(fullScrollOffset)}px)`
    }

    const handleScroll = (ev) => {
        adjustSidebarHeight();
    }

    const throttledScroll = throttle(handleScroll, 16);

    const { pageControllerType } = useParams();
    const { downloadPanelOpen } = usePageContext(false);

    function isScrolledIntoView(el, _isArticlePdf) {
        if (!el) return;
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        const progressBarOffset = 8
        var isVisible = (elemTop >= progressBarOffset)
        return isVisible;
    }
    useEffect(() => {
        const el = document.querySelector('.article-container-reading-mode')
        isScrolledIntoView(el)
    }, [shouldBeFixed])

    useEffect(() => {
        const adjustOnScroll = debounce(() => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled >= 0 && scrolled < 165) {
                resetDownloadpanelHeight(scrolled);
            }

        }, 500);

        window.addEventListener('scroll', adjustOnScroll);
        return () => {
            window.removeEventListener('scroll', adjustOnScroll);
        }
    }, [])

    useEffect(() => {
        const articleContainerElem = document.querySelector('.article-container-reading-mode')
        const _shouldBeFixed = !isScrolledIntoView(articleContainerElem, isArticlePdf)
        setShouldBeFixed(_shouldBeFixed)
        const handleScroll = (ev) => {
            const _shouldBeFixed = !isScrolledIntoView(articleContainerElem, isArticlePdf)
            setShouldBeFixed(_shouldBeFixed)
        }
        const tempThrottledScroll = throttle(handleScroll, 16)
        window.addEventListener('scroll', tempThrottledScroll, { passive: true })
        return () => {
            window.removeEventListener('scroll', tempThrottledScroll);
            setShouldBeFixed(false);
        }
    }, [isArticlePdf, shouldBeFixed])

    useEffect(() => {
        if (downloadPanelOpen === true) {
            const scrolled = document.documentElement.scrollTop;
            resetDownloadpanelHeight(scrolled);
        }
    }, [downloadPanelOpen])


    useEffect(() => {
        // We're removing the event listener 1st because handleScroll needs to know the state of showInArticleTOC
        // so whenever that state changes we remove the old listener and attatch a new one
        window.removeEventListener('scroll', throttledScroll);
        window.addEventListener('scroll', throttledScroll, { passive: true })
        return () => {
            window.scrollTo(0, 0);
            window.removeEventListener('scroll', throttledScroll);
            const inArticleTocElem = getInArticleTOCReadingModeElem();
            if (!inArticleTocElem) return;
            inArticleTocElem.style.maxHeight = 'initial';
        }
    }, [showInArticleTOC])

    function resetDownloadpanelHeight(scrolled) {
        const elDownloadPanel = document.querySelector('.download-panel')
        const eldarkoverlay = document.querySelector('.screen-darken-overlay')
        if (elDownloadPanel !== null) {
            if (scrolled > 20 && scrolled < 75) {
                elDownloadPanel.style.top = (8 + scrolled) + 'px';
            } else {
                elDownloadPanel.style.top = '';
            }

            if (scrolled > 0 && scrolled < 75) {
                if (scrolled < 25) {
                    elDownloadPanel.style.height = ((scrolled - 43) + window.innerHeight) + 'px';
                }
                if (scrolled > 25) {
                    elDownloadPanel.style.height = (-25 + window.innerHeight) + 'px';
                }
                eldarkoverlay.style.height = ((scrolled - 25) + window.innerHeight) + 'px';
            }

            if (scrolled === 0) {
                elDownloadPanel.style.height = '';
            }
        }
    }


    return (
        <>
            <HorizontalScrollbar className='scrollbar-horizontal-container-reading-mode' isRedingMode={true}/>
            <div className="header-reading-mode d-flex">
                <div style={{ width: showInArticleTOC ? '32%' : '19%' }} className="d-flex">
                    <div className="pub-type-logo-reading-mode-outer-container">
                        <div>
                            <button
                                onClick={() => {
                                    setIsReadingMode('toggle');
                                    setIsWideMode(false);
                                }}
                                className="pub-type-logo-reading-mode"
                                title={getLabel('a_ReadModeToggleOff', 'Toggle Read Mode Off')}
                            >
                                <img className="pub-type-logo-reading-mode-img" src={getIconSrc(pageControllerType)} alt={getLabel('a_ReadModeToggle', 'Read Mode Toggle')} />
                            </button>
                        </div>
                    </div>
                    <ShowIf condition={!isArticlePdf && overrideShowInArticleTOC}>
                        <button
                            onClick={() => setShowInArticleTOC(prevVal => !prevVal)}
                            className={
                                cx("reading-mode-in-article-toc-toggle",
                                    showInArticleTOC
                                        ? "reading-mode-in-article-toc-toggle-on"
                                        : "reading-mode-in-article-toc-toggle-off")
                            }
                            title=""
                        ></button>
                    </ShowIf>
                </div>
                <h2 className="header-title-reading-mode">
                    {articleTitle}
                </h2>
                <ToggleReadingMode handleClick={() => setIsWideMode(false)} showInArticleTOC={showInArticleTOC} />
            </div>
        </>
    )
}


ReadingModeHeader.deaultProps = {
    setShowInArticleTOC: () => { },
    setIsWideMode: () => { },
    setShouldBeFixed: () => { },
}

export const getDisplayTitle = (pubData, isGuidebook) => {
    const displayTitle = get(pubData, 'context.title', '') === '' ? '' : get(pubData, 'context.display_title', '') + ' ';
    return displayTitle;
}

const ArticleReadingMode = (props) => {
    const {
        pubData, setIsWideMode, publicationType, articleTitle, articleContent,
        articleHasLoaded, showInArticleTOC: overrideShowInArticleTOC, shouldBeFixed, setShouldBeFixed
    } = props;
    const location = useLocation();
    const isGuidebook = location.pathname.indexOf('/folio/') !== -1;
    const { context, isJournalPage } = usePageContext();

    const isArticlePdf = getIsArticlePdf(pubData);
    const [_showInArticleTOC, setShowInArticleTOC] = useState(false);
    const [containsJumpLinks] = useState(hasJumpLinks(pubData, isGuidebook));
    const jumpLinks = getJumpLinks(pubData, isGuidebook, isJournalPage);
    const showInArticleTOC = containsJumpLinks && _showInArticleTOC && overrideShowInArticleTOC;
    const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
    const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
    const [isSectionPath, setIsSectionPath] = useState(false);
    const [closedToolbar, setClosedToolbar] = useState(true);
    const { getLabel } = usePageContextLabels();
    // The article title comes espaped (HTML), thats why we need unescape the string
    const articleTitleReplaced = decodeHtml(articleTitle).replace(/(<([^>]+)>)/ig, '');

    // Method for unescape a string
    function decodeHtml(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    function shareHandler(e) {
        setShouldShowShareModal(true);
        setShouldShowOverlay(true);
        setIsSectionPath(false);
    }
    useEffect(() => {
        function removeRMAnchorClick(e) {
            if (!e.target.matches('.article-container-reading-mode a:not(.normal-link-in-reading-mode)')) return;
            e.preventDefault();
        }
        document.addEventListener('click', removeRMAnchorClick);
        window.addEventListener('scroll', toggleVisible);
        return () => {
            document.removeEventListener('click', removeRMAnchorClick)
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);

    const guidebookDisplayTitle = getDisplayTitle(pubData, isGuidebook);

    
    const checkIfDisplayDownloadEnable = DOWNLOADHELPERS.checkIfDisplayDownloadEnable(pubData);
    const { downloadPanelOpen, setDownloadPanelOpen } = usePageContext();
    const isDownloadableArticle = ("disclosures" !== context.pageSelectedSection);

    function interactiveToolbar(e) {
        const toolbarContainer = document.querySelector('.toolbar');
        if (!toolbarContainer) return;
        const selectedText = document.getSelection();
        const textStr = selectedText.toString().trim();
        if (textStr.length > 0) {
            const articleContainer = document.querySelector('.article-container-reading-mode');
            const bounds = articleContainer.getBoundingClientRect();
            const x = e.clientX + 6;
            const y = e.clientY - bounds.top + 36;
            toolbarContainer.style.top = (y) + 'px';
            toolbarContainer.style.left = (x) + 'px';
            setClosedToolbar(false);
        } else {
            setClosedToolbar(true);
        }
    }

    function interactiveToolbarClear(e) {
        const toolbarContainer = document.querySelector('.toolbar');
        if (!toolbarContainer) return;
        setClosedToolbar(true);
        document.getSelection().empty();
    }

    function wordHandler(e) {
        e.preventDefault();
        setDownloadPanelOpen(prevVal => !prevVal);
    }
    //Handles the initial pdf download button click
    function pdfHandler(e) {
        e.preventDefault();
        // Save for future state
        // setDownloadPanelOpen(prevVal => !prevVal);
        const delegate = document.getElementById('single-pdf-download');
        if (delegate !== null) {
            delegate.click();
        } else {
            logs.error(page.Article, "ArticleReadingMode", ErrorMessages.pdfHandler, {},{eventId:ErrorCodes.Article});
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };



    return (
        <>
            <HiddenMetadata metadata={props.pubData.metadata}></HiddenMetadata>
            <ReadingModeHeader
                articleTitle={articleTitleReplaced}
                showInArticleTOC={jumpLinks.length > 0 && showInArticleTOC}
                setShowInArticleTOC={setShowInArticleTOC}
                isArticlePdf={isArticlePdf}
                setIsWideMode={setIsWideMode}
                publicationType={publicationType}
                overrideShowInArticleTOC={jumpLinks.length > 0 && overrideShowInArticleTOC}
                shouldBeFixed={shouldBeFixed}
                setShouldBeFixed={setShouldBeFixed}
            />
            <div className="d-flex">
                <ShowIf condition={showInArticleTOC}>
                    <div className="side-pannel-reading-mode">
                        <div className="in-article-toc-reading-mode">
                            {jumpLinks.length > 0 &&
                                <JumpLinksList
                                    jumpLinks={jumpLinks}
                                    className="article-jump-links-reading-mode"
                                    scrollOffsetCompensation={-55}
                                />
                            }
                        </div>
                    </div>
                </ShowIf>
                <div
                    className={cx(
                        "article-container article-container-reading-mode",
                        {
                            'article-container-reading-mode-with-toc-displaying': showInArticleTOC,
                            'article-container-reading-mode-for-pdf ': isArticlePdf
                        }
                    )}
                    onMouseDown={interactiveToolbarClear}
                    onMouseUp={interactiveToolbar}
                >
                    <ShowIf condition={!isArticlePdf}>
                        <h1 className="article-title-reading-mode">
                            {articleTitleReplaced}
                        </h1>
                    </ShowIf>
                    <FootnotePopup dependenciesToRecalculatePosition={[showInArticleTOC]} />
                    {/* <RenderAllContainer pubData={pubData} excludeBinaries={false} /> */}
                    {articleContent}
                    <ShowIf condition={!isArticlePdf}>
                        <PrevNextArticle articleHasLoaded={articleHasLoaded} />
                    </ShowIf>
                </div>
                <Toolbar
                    docsPubDisplayTitle={guidebookDisplayTitle}
                    setShouldShowShareModal={setShouldShowShareModal}
                    setShouldShowOverlay={setShouldShowOverlay}
                    setIsSectionPath={setIsSectionPath}
                    setClosedToolbar={setClosedToolbar}
                    closedToolbar={closedToolbar}
                />
                <ShowIf condition={isArticlePdf}>
                    <button className="icon-article-readingmode icon-download" onClick={(e) => pdfHandler(e)} title={getLabel('a_DownloadToPDF', 'Download to PDF')}></button>
                </ShowIf>
                <Modal modalHeader={getLabel("a_Copied", "Copied!")} modalBody={getLabel("a_CopiedToYourClipboard", "Formatted content has been copied to your clipboard. You may now paste content into documents.")} />
                
                <ShowIf condition={(isDownloadableArticle && !isArticlePdf) || isArticlePdf}>
                    {/*eslint-disable-next-line*/}
                    <button role="button" className="icon-article icon-download download-icon-reading-mode" onClick={(e) => (!checkIfDisplayDownloadEnable && isArticlePdf) ? pdfHandler(e) : wordHandler(e)} title={getLabel('a_DownloadToWord', 'Download to Word')}></button>
                </ShowIf>

                <button className="article-icon-readingmode icon-share reading-mode-btn" onClick={(e) => shareHandler(e)} title={getLabel('a_Share', 'Share')}></button>
                <div className="fixed-tools-container-bottom-reading-mode" style={{ display: visible ? 'inline' : 'none' }} >
                    <div>
                        <button className='icon-scroll-to-top-reading-mode' onClick={scrollToTop}></button>
                        <div className='icon-scroll-top-label-reading-mode'>Top of Article</div>
                    </div>
                </div>
                <ShareModal
                    modalHeader="ModalShare"
                    articleTitle={articleTitleReplaced}
                    setShouldShowShareModal={setShouldShowShareModal}
                    shouldShowShareModal={shouldShowShareModal}
                    setShouldShowOverlay={setShouldShowOverlay}
                    shouldShowOverlay={shouldShowOverlay}
                    setIsSectionPath={setIsSectionPath}
                    isSectionPath={isSectionPath}
                    setClosedToolbar={setClosedToolbar}
                />
                <ShowIf condition={downloadPanelOpen}>
                    <DownloadPanel page={props} shouldBeFixed={shouldBeFixed} className="download-readingmode" />
                </ShowIf>
            </div>
            <ShowIf condition={isArticlePdf}>
                <PrevNextArticle
                    articleHasLoaded={!!articleHasLoaded}
                    className="prev-next-article-links-container-reading-mode-pdf"
                />
            </ShowIf>
        </>
    )
};

ArticleReadingMode.defaultProps = {
    showInArticleTOC: true,
    overrideShowInArticleTOC: true,
}

export default ArticleReadingMode;
