import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShowIf from '../Common/ShowIf';
import cx from 'classnames';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { useTocContext } from '../../contexts/TocContext';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { usePageContext } from '../../contexts/PageContext';

const TocSearchBadge = ({ tocItem }) => {
	const [isActive, setIsActive] = useState(false);
	const [count, setCount] = useState('');
	const { searchResults, activeFilters, setActiveFilters, allButtonsOn, isTriggerAllOnOff, setAllButtonText, setIsShowAllOnOffButton } = useSearchResultContext();
	const { toc, tocItemTogglesStatus, setTocItemTogglesStatus } = useTocContext();
	const isSearchActive = searchResults && searchResults?.length > 0;
	const location = useLocation();
	const inArticle = !location.search.includes('searchPhrase');
	const { context } = usePageContext();
	let tocChildren = [];

	useEffect(() => {
		if (isSearchActive) {
			if (allButtonsOn) {
				setIsActive(false);
				toggleOff();
				setAllButtonText(true);
			} else {
				setIsActive(true);
				//BDP:TODO: THIS IS WHERE THE PAGE useEffect error is being caused, setting while search is not even active!
				toggleOn();
				setAllButtonText(false);
			}
		}
	}, [allButtonsOn, isTriggerAllOnOff])//eslint-disable-line
	
	let childArr = [];
	const getSubItems = ((subItems) => {
		const allToc = subItems?.forEach((subItem) => { //eslint-disable-line
			if (subItem?.tocHash) {
				childArr.push(subItem?.tocHash);
			}
			if (subItem?.subItems.length > 0) {
				getSubItems(subItem?.subItems);
			}
		})
	});

	useEffect(() => {
		if (isSearchActive) {
			if (Array.isArray(toc) && toc !== null) {
				const notUndefinedToc = toc?.filter(element => element.tableOfContents !== undefined);
				const tableOfContents = notUndefinedToc?.map((item) => { return item.tableOfContents });
				const secondFriendlyPath = notUndefinedToc.map((element) => {
					return element.sectionFriendlyPath;
				})
				let tocHasheshArr = tableOfContents?.flat().map((item) => {
					return item !== null && item?.tocHash;
				})
				const combinedArr = [...secondFriendlyPath, ...tocHasheshArr];
				let filterdTocHashesh = tocItemTogglesStatus?.filter((item) => {//eslint-disable-line
					if (combinedArr?.includes(item.item_id)) {
						return item;
					}
				})
				const allFilterStatesAreFalse = filterdTocHashesh.every(item => item.filterState === false);
				if (allFilterStatesAreFalse) {
					setAllButtonText(true);
				} else {
					setAllButtonText(false);
				}
			} else {
				childArr = [];//eslint-disable-line
				getSubItems(toc?.subItems);			
				let filterdTocHashesh = tocItemTogglesStatus?.filter((item) => {//eslint-disable-line
					if (childArr?.some((x) => x===(item.item_id))) {
						return item;
					}

				})
				const allFilterStatesAreFalse = filterdTocHashesh.every(item => item.filterState === false);
				if (allFilterStatesAreFalse) {
					setAllButtonText(true);
				} else {
					setAllButtonText(false);
				}
			}
		}
	}, [tocItemTogglesStatus])

	useEffect(() => {
        return () => {
            setIsShowAllOnOffButton(false);
			setTocItemTogglesStatus([]);
        }
    }, []);//eslint-disable-line

	useLayoutEffect(() => {
		if (searchResults && searchResults.length > 0) {
			const filteredResults = searchResults.filter(x => x.tocHash.includes(tocItem.tocHash));
			if (filteredResults.length === 1 && filteredResults[0].tocHash === tocItem.tocHash) {
				setCount('DOT');
			} else if (filteredResults.length >= 1) {
				setCount(filteredResults.length);
				let parentId = getParentOfToc(tocItem.tocHash);
				const obj = {
					"item_id": tocItem.tocHash,
					"filterState": true,
					"parent_id": parentId ? parentId : tocItem.tocHash
				};
				setTocItemTogglesStatus(prevArray => [...prevArray, obj]);
				setIsShowAllOnOffButton(true);
			} else {
				setCount('');
			}
		}
	}, [searchResults,tocItem.tocHash])//eslint-disable-line

	useEffect(() => {
		if (isSearchActive) {
			if (searchResults.length > 0) {
				if (activeFilters.length === 0) {
					setIsActive(true)
				} else {
					const matchedFilters = activeFilters.find(x => x === tocItem.tocHash && tocItem.tocHash.startsWith(x));
					setIsActive(!matchedFilters);
				}
			} else {
				setIsActive(false);
				setCount('');
			}
		}
	}, [searchResults, activeFilters.length])//eslint-disable-line

	useEffect(() => {
		if (isSearchActive) {
			const lastItemAdded = activeFilters[activeFilters.length - 1];
			if (!activeFilters.includes(tocItem.tocHash) && tocItem.tocHash.startsWith(lastItemAdded)) {
				setActiveFilters([...activeFilters, tocItem.tocHash])
			}
		}
	}, [activeFilters])//eslint-disable-line

	const updateToggleListItemStatus = (tocChildren, idToUpdate, newStatus) => {
		setTocItemTogglesStatus((prevArray) =>
		  prevArray.map((item) => {
			if ((!UTILITIESHELPER.isNullOrUndefined(tocChildren) && tocChildren.includes(item.item_id)) || item.item_id === idToUpdate) {
			  return { ...item, filterState: newStatus };
			}
			return item;
		  })
		);
	};

	function getChildrenTocHashes(currentTocItem) {
		if (currentTocItem.subItems && currentTocItem.subItems.length > 0) {
			currentTocItem.subItems.forEach(item => {
				const filteredResults = searchResults.filter(x => x.tocHash.includes(item.tocHash));
				if (filteredResults.length > 0) {
					tocChildren.push(item.tocHash)
				}
				getChildrenTocHashes(item)
			})
		} else {
			const filteredResults = searchResults.filter(x => x.tocHash.includes(currentTocItem.tocHash));
			filteredResults.forEach(item => {
				tocChildren.push(item.tocHash)
			});
		}
		return tocChildren
	}

	function getParentOfToc(tocItemHash) {
		const lastIndexParent = tocItemHash?.lastIndexOf('_GUID');
		if (lastIndexParent >= 0) {
			return tocItemHash.substring(0, lastIndexParent);
		}
		//return false;
	}

	function enableParentIfIsAnyChildEnabled(parent, filtersWithoutCurrentTocItem) {
		if(parent)
		{
			filtersWithoutCurrentTocItem = filtersWithoutCurrentTocItem.filter(filter => filter !== parent);
			const newParent = getParentOfToc(parent);
			updateToggleListItemStatus(null, parent, true);
			// If any child enabled then the corresponding parent tree needs to be enabled (recursive function)
			filtersWithoutCurrentTocItem = enableParentIfIsAnyChildEnabled(newParent, filtersWithoutCurrentTocItem);
		}
		return filtersWithoutCurrentTocItem;
	}

	function disableParentIfIsAllChildDisabled(parent, tempChildMergedArray) {
		let parentArr = [];
		let childArr = [];
		const allChildrenofParent = searchResults.filter(x => x.tocHash.includes(parent));
		const filterChildrenIds = allChildrenofParent.map((obj) => obj.tocHash);
		// This check is for audit folio publication listing
		if(!UTILITIESHELPER.isNullOrUndefined(context.pageSelectedSection)){
			if(UTILITIESHELPER.isNullOrUndefined(parent)){
				parent = tocItem.tocHash;
				childArr = getAllChilds(parent);
				filterChildrenIds.push(childArr);
				updateToggleListItemStatus(childArr, parent, false);
				parentArr = [...childArr];
			}
		}
		if(parent)
		{
			const containsAllElements =  filterChildrenIds.every((element) => tempChildMergedArray.includes(element));
			if(containsAllElements){
				updateToggleListItemStatus(null, parent, false);
				parentArr = [parent];
			}
		}
		return parentArr;
	}

	function getAllChilds(parentId){
		const childFilters = tocItemTogglesStatus.filter(item => item.item_id !== parentId && item.parent_id === parentId)
							.map(item => item.item_id);
		return childFilters;
	}

	function toggleOff() {
		setActiveFilters(prevFilters => {
			const tocItemChildren = getChildrenTocHashes(tocItem);
			// Get the Childs parent to set the Status of Parent
			const parent = getParentOfToc(tocItem.tocHash);
			const childCombinedArray = [...prevFilters,  tocItem.tocHash, ...tocItemChildren];
			const parents = disableParentIfIsAllChildDisabled(parent, childCombinedArray);
			updateToggleListItemStatus(tocItemChildren, tocItem.tocHash, false);
			return [...prevFilters, tocItem.tocHash, ...tocItemChildren, ...parents]
		})
		
	}

	function toggleOn() {
		//Check if is the last child item to enable also the parent
		setActiveFilters(prevFilters => {
			//Delete children active tocItem
			let filtersWithoutCurrentTocItem = prevFilters.filter(x => !x?.startsWith(tocItem.tocHash));
			//Get the tocItem parent
			const parent = getParentOfToc(tocItem.tocHash);
			//Get the Childrens to manipulate the All On/Off Button status
			const tocItemChildren = getChildrenTocHashes(tocItem);
			filtersWithoutCurrentTocItem = enableParentIfIsAnyChildEnabled(parent, filtersWithoutCurrentTocItem);
			updateToggleListItemStatus(tocItemChildren, tocItem.tocHash, true);
			return filtersWithoutCurrentTocItem;
		})
		
	}

	function toggleHandler() {
		if (isActive) {
			toggleOff();
		} else {
			toggleOn();
		}
	}

	return (
		<>
			<ShowIf condition={isSearchActive && count > 0}>
				{inArticle
					? <div className="search-badge-article">{count}</div>
					: (
						<button className={cx(
							{
								'search-badge': !isActive,
								'search-badge-active': isActive
							})} onClick={toggleHandler}>
							{count}
						</button>
					)}

			</ShowIf>
			<ShowIf condition={isSearchActive && isActive && count === 'DOT'}>
				<div className="search-dot">&nbsp;</div>
			</ShowIf>
		</>
	)
}

export default TocSearchBadge;
