
import React, { useCallback } from 'react';
import { Link } from "react-router-dom"
import cx from 'classnames';
import LINKHELPERS from '../../helpers/LinkHelpers';
import SidebarIconNextToTitle from "./SidebarIconNextToTitle"
import { useSearchResultContext } from '../../contexts/SearchResultContext';

const SidebarTocTitle = ({
    navTitle,
    pubLandingUrl,
    shouldBeFixed,
    isFixed,
}) => {
    const {setSearchResults} = useSearchResultContext();
    const onClick = useCallback(() => { setSearchResults([])}, []);
    return (
        <div
            className={cx(
                "d-flex",
                {'sidebar-toc-title-container-fixed': isFixed && shouldBeFixed}
            )}
        >
            <SidebarIconNextToTitle />
            <Link
                to={LINKHELPERS.sanitizeUrl(pubLandingUrl)}
                className={cx("sidebar-toc-title-link", {'sidebar-toc-title-link-fixed': isFixed})}
                onClick={onClick}
            >
                {navTitle}
            </Link>
        </div>
    )
};

export default SidebarTocTitle;
