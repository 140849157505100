import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { logs, page } from '../../helpers/log'
import RETRIEVALSERVICES from '../../services/rp-service'
import UTILITIESHELPER from '../../helpers/UtilitiesHelper'
import {
  usePageContext,
  usePageContextLabels,
  usePageContextSettings,
} from '../../contexts/PageContext'
import { useLoadingContext } from '../../contexts/LoadingContext'
import { useThemeContext } from '../../contexts/ThemeContext'
import ShowIf from '../Common/ShowIf'
import WormholeNotification from '../Notification/WormholeNotification'
import CollectionSearchbar from './CollectionSearchbar'
import CollectionSearchResultItem from './CollectionSearchResultItem'
import { useSearchResultContext } from '../../contexts/SearchResultContext'
import LINKHELPERS from '../../helpers/LinkHelpers'
import SearchHelpers from '../../helpers/SearchHelpers'
import RecentViewedService from '../../helpers/RecentlyViewedServices'
import { useLocation } from 'react-router-dom'
import RecentlyViewedHelper from '../../helpers/RecentlyViewedHelper'
import { ErrorCodes, ErrorMessages } from '../Constants/Errors'
import CatalogCards from './CatalogCards'
import useCatalogSearchQuery from '../../hooks/useCatalogSearchQuery'

const Catalog = () => {
  const params = useParams()
  const { knowledgeDomain, memberFirm, language } = params
  const [data, setData] = useState({})
  const [catalogs, setCatalogs] = useState([])
  const { isLoading, setIsLoading } = useLoadingContext()
  const [isResultLoading, setIsResultLoading] = useState(false)
  const {
    setPageTitle,
    currentTier,currentIndustry,
    searchPhrase,
    setSearchPhrase,
    contextMemberFirm,
    getMemberFirmID,
  } = usePageContext()
  const {
    isDashboardEnabled,
    isRecentlyViewedEnabled,
  } = usePageContextSettings()
  const { getLabel, getMFLabel } = usePageContextLabels()
  const { isDarkMode } = useThemeContext()
  const [isFixed] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [isSearchSubmit, setSearchSubmit] = useState(false)
  const searchProps = {
    eventType: 'search',
    searchLocation: window.location.href,
    jobTitle: UTILITIESHELPER.getSessionStorage('Level'),
    memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
    rpMemberFirm: UTILITIESHELPER.getLocalStorage('research-portal-member-firm'),
  }
  const location = useLocation()
  const {
    filteredSearchResults,
    setFilteredSearchResults,
  } = useSearchResultContext()
  const [showNoResults, setShowNoResults] = useState(false)
  const [searchOrder, setSearchOrder] = useState([
    '60194861', //folios
    '60194866', //manuals
    '60194865', //standards
    '60194864', //Deloitte-communications
    '65511719', //Announcements
  ])
  const [counterSectionsResult, setCounterSectionsResult] = useState(0)
  const [catalogSearchPhrase, setCatalogSearchPhrase] = useState('')
  const wormholeStyling = { marginRight: '35px', marginTop: '7px' }

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        setPageTitle('')
        setIsLoading(true)

        const res = await RETRIEVALSERVICES.retrieveCatalog()
        logs.debug(page.Catalog, 'retrieveCatalog', 'retrieveCatalog:res', res)

        setData(res)
        setPageTitle(get(res, 'pageTitle'))
      } catch (err) {
        logs.error(
          page.Catalog,
          'fetchRecentHistory',
          ErrorMessages.recentHistoryData,
          err,
          { eventId: ErrorCodes.Catalog }
        )
      }
    }

    fetchCatalogData()
    const mf = UTILITIESHELPER.getSessionStorage('MemberFirm')
    logs.trackPageView({
      name: 'Catalog - ' + knowledgeDomain + ' ' + mf,
      properties: {
        memberFirm: mf,
        serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
        level: UTILITIESHELPER.getSessionStorage('Level'),
      },
    })

    return () => setPageTitle('')
  }, [memberFirm, language, knowledgeDomain,currentIndustry,currentTier]) //eslint-disable-line

  const shouldShowCard = (collection) => {
    const friendlyUrl = get(collection, 'collectionType', '')
    const viewAllLink =
      friendlyUrl.indexOf('dashboard') > -1
        ? [friendlyUrl].join('/')
        : ['collection', friendlyUrl].join('/')

    let show = true
    // If the collection is a dashboard and the dashboard is disabled, hide the card
    if (viewAllLink.indexOf('dashboard') > -1 && !isDashboardEnabled()) {
      show = false
    }

    // If the collection is a recently viewed and the recently viewed is disabled, hide the card
    if (
      collection.collectionType.indexOf('history') > -1 &&
      !isRecentlyViewedEnabled()
    ) {
      show = false
    }

    // By default show the card, add exceptions above if needed
    return show
  }

  useEffect(() => {
    const fetchCatalogHistoryData = async (_data) => {
      const resultLimit = _data?.CatalogCollection?.find(
        (q) => q.collectionType === 'dashboard/history'
      )?.resultLimit
      const resCatalogHistory = await RecentViewedService.fetchCatalogHistory(
        location.pathname,
        memberFirmID,
        resultLimit
      )
      logs.debug(
        page.Catalog,
        'fetchCatalogHistory',
        'fetchCatalogHistory:resCatalogHistory',
        resCatalogHistory
      )
      const recentHistory = RecentlyViewedHelper.generateRecentHistoryLinks(
        resCatalogHistory
      )
      const dataWithHistory = RecentlyViewedHelper.setRecentHistoryLinks(
        data,
        recentHistory
      )
      let catalogCollections = get(dataWithHistory, 'CatalogCollection', [])
      const filteredCatalogs = catalogCollections.filter((catalog) =>
        shouldShowCard(catalog)
      )

      setCatalogs(filteredCatalogs)
      setIsLoading(false)
    }

    const memberFirmID = getMemberFirmID()

    if (!UTILITIESHELPER.isNullOrUndefined(memberFirmID)) {
      let catalogCollections = get(data, 'CatalogCollection', [])
      if (catalogCollections.length > 0) {
        //If recently viewed is enabled, we need to load the history into this object before we load the catalog boxes
        if (isRecentlyViewedEnabled()) {
          fetchCatalogHistoryData(data)
        } else {
          const filteredCatalogs = catalogCollections.filter((catalog) =>
            shouldShowCard(catalog)
          )

          setCatalogs(filteredCatalogs)
          setIsLoading(false)
        }
      }
    }
  }, [contextMemberFirm, data]) //eslint-disable-line

  function getCatalogTitleByKey(key) {
    let catalogTitle = ''
    let viewAllLink = ''
    let friendlyUrl = ''

    const isFolio = key === 'folio'

    catalogs.forEach((catalog) => {
      const isMatchingCatalog = isFolio
        ? catalog.collectionType === 'folio'
        : catalog?.publicationType === key

      if (isMatchingCatalog) {
        catalogTitle = getMFLabel(get(catalog, 'title'), [])
        friendlyUrl = get(catalog, 'collectionType', '')
        viewAllLink =
          (friendlyUrl.indexOf('dashboard') > -1
            ? friendlyUrl
            : [knowledgeDomain, 'collection', friendlyUrl].join('/')) +
          '?' +
          SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))
      }
    })

    return {
      catalogTitle: catalogTitle,
      viewAllLink: viewAllLink,
      friendlyUrl: friendlyUrl,
    }
  }

  const handleSearchTrigger = (typedSearchPhrase) => {
    if (
      searchPhrase !== '' &&
      typedSearchPhrase !== '' &&
      String(typedSearchPhrase)?.length > 1
    ) {
      setSearchSubmit(true)
      if (
        String(typedSearchPhrase.toUpperCase()) ===
          catalogSearchPhrase.toUpperCase() &&
        catalogSearchQueryresults
      ) {
        setFilteredSearchResults(catalogSearchQueryresults)
        setShowNoResults(
          Object.values(catalogSearchQueryresults).every(
            (arr) => Array.isArray(arr) && arr.length === 0
          )
            ? true
            : false
        )
      } else {
        setShowNoResults(false)
        setIsResultLoading(true)
        setCatalogSearchPhrase(typedSearchPhrase)
      }
    } else {
      setSearchSubmit(false)
      setIsResultLoading(false)
      setFilteredSearchResults([])
      setShowNoResults(false)
    }
  }

  const { data: catalogSearchQueryresults } = useCatalogSearchQuery(
    catalogSearchPhrase,
    isSearchSubmit
  )
  useEffect(() => {
    if (catalogSearchQueryresults && isSearchSubmit === true) {
      if (String(catalogSearchPhrase)?.length > 1 && catalogSearchPhrase) {
        searchProps.searchText = catalogSearchPhrase
        logs.event('search', searchProps)

        Object.keys(catalogSearchQueryresults).forEach((key) => {
          if (!searchOrder.some((item) => item === key)) {
            if (key === 'folio') {
              setSearchOrder([key, ...searchOrder])
            } else {
              setSearchOrder([...searchOrder, key])
            }
          }
        })

        determineCounterSectionsResult(catalogSearchQueryresults)

        if (Object.keys(catalogSearchQueryresults)?.length > 0) {
          setIsResultLoading(false)
          setFilteredSearchResults(catalogSearchQueryresults)
          setShowNoResults(
            Object.values(catalogSearchQueryresults).every(
              (arr) => Array.isArray(arr) && arr.length === 0
            )
              ? true
              : false
          )
          setSearchPhrase(catalogSearchPhrase)
          setIsSearchActive(true)
          return
        } else {
          setIsResultLoading(false)
          setFilteredSearchResults([])
          setShowNoResults(true)
          return
        }
      }
    }
  }, [catalogSearchQueryresults]) //eslint-disable-line

  function determineCounterSectionsResult(results) {
    let _counterSectionsResult = 0
    Object.keys(results).map((key) => {
      //eslint-disable-line
      const sectionResultCount = results[key]?.length ?? 0
      _counterSectionsResult = _counterSectionsResult + sectionResultCount
    })

    setCounterSectionsResult(_counterSectionsResult)
  }

  return (
    <>
      <ShowIf condition={!isDarkMode && data.backgroundImageUrl}>
        <img
          className='catalog-background'
          src={data.backgroundImageUrl}
          alt='Background Alt text'
        />
      </ShowIf>
      <div className='catalog-wormhole-div'>
        <WormholeNotification style={wormholeStyling} />
      </div>
      {/* TO DO: Move Page Title component and Searchbar into this component and out of Layout */}
      <div className='catalog-searchbar' id='catalog-page-search'>
        <CollectionSearchbar
          placeholder={`${getLabel('s_Search', 'Search')}`}
          className='catalog-searchbar-input'
          autocomplete='off'
          parentOnTrigger={handleSearchTrigger}
          isFixed={isFixed}
          calledFrom={page.Catalog}
        />
        <ShowIf condition={isResultLoading}>
          <div className='catalog-search-result-panel'>
            {searchOrder.map((key, idx) => {
              //eslint-disable-line
              const obj = getCatalogTitleByKey(key)
              if (obj.catalogTitle !== '') {
                return (
                  <div key={idx} className='catalog-search-result-container'>
                    <div className='d-flex'>
                      <div className='catalog-search-result-container-title'>
                        {obj.catalogTitle.toUpperCase()}
                      </div>
                      <div className='catalog-search-result-container-title-link-disabled'>
                        <div className='collection-search-view-all-disabled'>
                          {getLabel('dd_ViewAllResults', 'View All Results')}
                        </div>
                      </div>
                    </div>
                    {
                      <div className='cf-right shimmerArticleCard shimmerArticleWrapper catalog-search-result-panel-warapperShimmer'>
                        <div className='article-content-container catalog-search-result-panel-article-content-containerShimmer'>
                          <div className='article'>
                            <div className='body'>
                              <div className='shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine'></div>
                              <div className='shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine'></div>
                              <div className='shimmerArticleCardBG shimmerArticleCardContentLine  catalog-search-result-panel-shimmerLine'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )
              }
            })}
          </div>
        </ShowIf>

        <ShowIf
          condition={
            isSearchActive &&
            filteredSearchResults !== null &&
            !isResultLoading &&
            counterSectionsResult > 0
          }
        >
          <div className='catalog-search-result-panel-search-active'>
            {searchOrder.map((key, idx) => {
              //eslint-disable-line
              if (filteredSearchResults[key]) {
                const obj = getCatalogTitleByKey(key)
                return (
                  <div key={idx} className='catalog-search-result-container'>
                    <div className='d-flex'>
                      <div className='catalog-search-result-container-title'>
                        {obj.catalogTitle.toUpperCase()}
                      </div>
                      <div className='catalog-search-result-container-title-link'>
                        <a
                          href={LINKHELPERS.sanitizeUrl(obj.viewAllLink)}
                          className='collection-search-view-all'
                        >
                          {getLabel('dd_ViewAllResults', 'View All Results')}
                        </a>
                      </div>
                    </div>

                    {filteredSearchResults[key]?.map((result, index) => {
                      //eslint-disable-line
                      if (
                        index <
                        get(
                          window,
                          'DART.ENV.REACT_APP_CATALOG_SEARCH_LIMIT',
                          3
                        )
                      ) {
                        result['url'] = LINKHELPERS.resolveSearchLink(
                          result,
                          memberFirm,
                          language,
                          knowledgeDomain,
                          obj.friendlyUrl,
                          searchPhrase,
                          catalogs,
                          true
                        )
                        return (
                          <CollectionSearchResultItem
                            key={index + '_' + key}
                            viewAllResultsUrl={LINKHELPERS.sanitizeUrl(
                              obj.viewAllLink
                            )}
                            {...result}
                          />
                        )
                      }
                    })}
                  </div>
                )
              }
            })}
          </div>
        </ShowIf>
        <ShowIf condition={showNoResults}>
          <div className='catalog-search-result-panel'>
            <div className='catalog-search-no-results'>
              {getLabel(
                's_SearchReturnedNoResults',
                'Your search returned no results'
              )}
            </div>
          </div>
        </ShowIf>
      </div>
      <CatalogCards catalogs={catalogs} isLoading={isLoading} />
    </>
  )
}

export default Catalog
