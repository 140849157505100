
import React from 'react';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import { usePageContextLabels } from '../../contexts/PageContext';
import { usePageContext } from '../../contexts/PageContext';

const ToggleReadingMode = ({ handleClick,fixedToolContainerStyleTop }) => {

    const { isReadingMode, setIsReadingMode } = useReadingModeContext();
    const { setDownloadPanelOpen } = usePageContext();
   
    const { getLabel } = usePageContextLabels();
    //Styling for reading mode button
    const toggleReadingbutton = { top: isReadingMode ? 10 : fixedToolContainerStyleTop }
    return (
        <div className="toggle-container d-flex reading-mode-btn"
            style={toggleReadingbutton}>
            <button
                className={isReadingMode ? "toggle-reading-mode-on" : "toggle-reading-mode-off"}
                onClick={() => {
                    handleClick();
                    setIsReadingMode('toggle');
                    // Close download to word when switched from Article mode and vise versa
                    setDownloadPanelOpen(false);
                }}
                title={getLabel('a_ReadingMode', 'Reading Mode')}
            ></button>
        </div>
    )
}

ToggleReadingMode.defaultProps = {
    handleClick: () => { }
}

export default ToggleReadingMode;
