import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate, Link } from "react-router-dom";
import { get } from "lodash";
import { usePageContext, usePageContextLabels, usePageContextSettings } from "../../contexts/PageContext";
import { useArticleContext } from "../../contexts/ArticleContext";
import { useEmbeddedModeContext } from "../../contexts/EmbeddedModeContext";
import LINKHELPERS from "../../helpers/LinkHelpers";
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import cx from "classnames";
import throttle from "lodash.throttle";
import ShowIf from "../Common/ShowIf";
import ArticleSearch from "../Article/ArticleSearch";
import ArticleTitle from "../Article/ArticleTitle";
import ArticleTOC from "../Article/ArticleTOC";
import EmbeddedArticle from "./EmbeddedArticle";
import FixedNavArticle from "./FixedNavArticle";
import FootnotePopup from "../Article/FootnotePopup";
import HorizontalScrollbar from "../Article/HorizontalScrollbar";
import IconWideModeTOC from "./WideModeIconTOC";
import Modal from "../Modal/Modal";
import PrevNextArticle from "./PrevNextArticle";
import RedirectModal from '../Article/RedirectModal';
import ShareModal from "../Article/ShareModal";
import Toolbar from "../Toolbar/Toolbar";
import DownloadPanel from "../Article/DownloadPanel";
import ToggleReadingMode from "../Article/ToggleReadingMode";
import SearchHelpers from "../../helpers/SearchHelpers";
import ANNSERVICES from "../../helpers/AnnotationServices";
import ArticleNotification from "../Notification/ArticleNotification";
import ARTICLEHELPERS from "../../helpers/ArticleHelpers";
import { useUserPreferencesContext, ACTIONS as ANN_ACTIONS, ACTIONS } from "../../contexts/AnnotationsContext";
import AnnotationModal from "../Annotations/AnnotationModal";
import { debounce } from "lodash";
import { AnnotationCommentIcon } from "../Annotations/AnnotationCommentIcon";
import AnnotationPanel from "../Annotations/AnnotationPanel";
import { AnnotationHighlighter } from "../../helpers/AnnotationHighlightHelper";
import { rebuildRange } from "../../helpers/AnnotationHighlightHelper";
import { useLocation } from "react-router-dom";
import BookmarkCategory from "../Annotations/BookmarkCategory";
import ColorPicker from "../Annotations/ColorPicker";
import { BookmarkIcon } from "../Annotations/BookmarkIcon";
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers.js';
import { appInsightUIMapping, logs, page } from "../../helpers/log";
import { useTocContext } from "../../contexts/TocContext";
import TOCHELPERS from '../../helpers/TocHelpers';
import BookmarkService from "../../helpers/BookmarkServices";
import { getFallback, SCS_KEYS } from "../Constants/SCSConstants";
import DOWNLOADHELPERS from "../../helpers/DownloadHelpers";
import CategoryService from "../../helpers/CategoryServices";
import { ErrorCodes,ErrorMessages, ErrorTypes } from "../Constants/Errors";
import HiddenMetadata from "./HiddenMetadata.js";
import PublicationDetailsModal from "../Article/PublicationDetailsModal.js";
export const getIsArticlePdf = (_pubData) => {
    return (
        (get(_pubData, "content[0].content.format"))?.toLowerCase() === ".pdf" ||

        (get(_pubData, "content[0].subItems[0].content.format"))?.toLowerCase() === ".pdf"
    );
};

export const getIsArticleBinary = (_pubData) => {
    return get(_pubData, "content[0].containsBinary");
};

export const getIsTextOnlyArticle = (_pubData) => {
    return (
        !get(_pubData, 'content[0].containsBinary')
    );
}

export const getArticleTitle = (pubData, isGuidebook, isEmbedded, embeddedPubData, isReadingMode, context) => {
    //REFACTOR:Similar to UTILITIESHELPER.getContentItemTitle() perhaps we should use that?
    var articleTitle = (get(pubData, 'context.rich_text_title', '') === '') ? get(pubData, 'content[0].content.rich_text_title', '') : get(pubData, 'context.rich_text_title', '') + ' ';
    let contentType = get(pubData, 'content[0].content_type', 'BLANK');
    let pageControllerType = get(context, 'pageControllerType', 'BLANK');
    let pageSelectedSection = get(context, 'pageSelectedSection', 'BLANK');

    if (isGuidebook
        //These are actually guidebook sections, so not sure why we looking at the pageControllerType as that generally is the params.pageSelectedSection that would hold that!!
        || (pageSelectedSection && "guidance|roadmap|questions-and-answers|examples|rp-templates".indexOf(pageSelectedSection) > -1)
        //Article|Section with Intro|Template|Section
        || ("standards|manual".indexOf(pageControllerType) > -1 && "63887580|65469055|17323604|17377912".indexOf(contentType) > -1)
    ) {
        if (isEmbedded && !isReadingMode) {
            articleTitle = get(embeddedPubData, "content[0].subItems[0].content.rich_text_title", "");
        }
        else {
            if ("standards|manual".indexOf(pageControllerType) > -1 && "63887580|65469055|17323604|17377912".indexOf(contentType) > -1) {
                articleTitle = get(pubData, 'context.rich_text_title', '');
            }
            else {
                articleTitle = get(pubData, "content[0].content.rich_text_title", "");
            }

        }
    }

    articleTitle = UTILITIESHELPER.decodeSpecialCharacters(articleTitle);

    if (articleTitle !== "" && UTILITIESHELPER.IsTextHavingFnTag(articleTitle)) {
        const fnNumberGenerator = ARTICLEHELPERS.footnoteNumberGenerator();
        articleTitle = ARTICLEHELPERS.createTitleMarkupFootnote(
            articleTitle,
            fnNumberGenerator
        );
    }

    return ARTICLEHELPERS.getContentHtmlDocument(articleTitle).all[3].outerHTML;
};


export const getDisplayTitle = (pubData) => {
    const displayTitle = get(pubData, "context.title", "") === "" ? "" : get(pubData, "context.display_title", "") + " ";
    return displayTitle;
};


export const setMaxHeightTocBody = (rect) => {
    const elemTop = rect.top;
    let fullScrollOffset = window.innerHeight - rect.bottom;
    const footerHeight = 0;
    fullScrollOffset = fullScrollOffset > 0 ? fullScrollOffset - footerHeight > 0 ? fullScrollOffset - footerHeight + 1 : fullScrollOffset + 1 : 0;
    const tocSidebarHeadingHeight = "61px";
    const sidePannelBody = document.querySelector(".side-pannel-body");
    //sidePannelBody?.style?.maxHeight = `calc(100vh - ${tocSidebarHeadingHeight} - ${Math.round(fullScrollOffset)}px - ${elemTop > 0 ? Math.round(elemTop) : 1}px - ${footerHeight}px)`;


    if (sidePannelBody && sidePannelBody.style) {
        const maxHeightValue = `calc(100vh - ${tocSidebarHeadingHeight} - ${Math.round(fullScrollOffset)}px - ${elemTop > 0 ? Math.round(elemTop) : 1}px - ${footerHeight}px)px`;
        sidePannelBody.style.maxHeight = maxHeightValue;
      }
};


const Article = (props) => {
    const {
        publicationType,
        shouldBeFixed,
        isWideMode,
        setIsWideMode,
        isJumpLinksOpen,
        setIsJumpLinksOpen,
        setShouldBeFixed,
        hasFetchedArticleData,
        pubData,
        embeddedPubData,
        navTitle,
        pubLandingUrl,
        articleContent,
        articleTitle,
        isGuidebook,
        articleHasLoaded,
        showInArticleTOC,
        showSearch,
        showFixedTools,
        secondMostRecentVersion,
        isFiltered,
        yAxisOfToc
       
    } = props;

    const isArticlePdf = getIsArticlePdf(pubData);
    const isArticleBinary = getIsArticleBinary(pubData);
    const checkIfDisplayDownloadEnable = DOWNLOADHELPERS.checkIfDisplayDownloadEnable(pubData);
    const navigate = useNavigate();
    const [annotationTexts, setAnnotationTexts] = useState([]);
    const location = useLocation();
    const searchParam = location.search;
    const { isEmbeddedMode } = useEmbeddedModeContext();
    const {
        context,
        memberFirm, language, knowledgeDomain,
        downloadPanelOpen,
        setDownloadPanelOpen,
        bookmarkOpen,
        setBookmarkOpen,
        annotationPanelOpen,
        setAnnotationPanelOpen,
        categoryOn, setCategoryOn,
        setAnnotateTerms,
        searchPhrase,
        setNotification,
        getMemberFirmID,
        isMemberFirmsLoaded,         
        memberFirms
    } = usePageContext();

    const [isSearchExpanded, setIsSearchExpanded] = useState((searchParam !== '' || (!UTILITIESHELPER.isNullOrUndefined(searchPhrase) && searchPhrase !== '')) ? true : false);
    const { getLabel } = usePageContextLabels();
    const isDownloadableArticle = ("disclosures" !== context.pageSelectedSection);
    const { isAnnotationsEnabled, isCompareEnabled, isBookmarksEnabled, isRestrictedAccessMemberfirm } = usePageContextSettings();
    const { searchText, setSearchText ,articleVersion} = useArticleContext();
    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
    const [closedToolbar, setClosedToolbar] = useState(true);
    const [messageRedirectTL, setMessageRedirectTL] = useState('');
    const { selectedAnnotation, setSelectedAnnotation, state: annState, dispatch, selectedId, setSelectedId } = useUserPreferencesContext();
    const [isDupAnnotation, setIsDupAnnotation] = useState(false);
    const [isArticleUpdated, setIsArticleUpdated] = useState(false);
    //for color in bookmarks
    const [fillColors, setFillColors] = useState('none');
    const [bookmarkId, setBookmarkId] = useState();
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    const [offsetWhenAnnPanelOpened, setOffsetWhenAnnPanelOpened] = useState(0);

    const { guidebookArticle } = useGuidebookTocContext();
    const { setTocIconBookmark, setTocAnnotations } = useTocContext();

    let heighttoset = 0;
    const [selectedCategory, setSelectedCategory] = useState('');
    const [editableCategory, setEditableCategory] = useState({ category: {}, isEditable: false, });
    const [isPickerShown, setPickerShown] = useState(false);
    const [isArticleSearchActive, setIsArticleSearchActive] = useState(context.pageSearchPhrase !== "");
    const linkProps = {
        eventType: 'link',
        selectedTocItem: UTILITIESHELPER.getSessionStorage("SelectedContent"),
        //sourceLink: sourceURL,
        //targetLink: targetURL,
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        jobTitle: UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage("research-portal-member-firm"),
        sourceURL: window.location.href
    }
    const [articleAnnotation, setArticleAnnotation] = useState([]);

    const annotationUpdateStyling = {
        paddingTop: 12.5,
        paddingBottom: isArticlePdf ? 0 : 12.5,
        paddingRight: isWideMode ? 70 : '',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    }
    const url = location.pathname;

    const articleTitleStyle = { whiteSpace: 'normal' }

    const articleNotificationStyle = { paddingLeft: (!isArticlePdf) ? '55px' : '0px', width: '84%' }
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    function handleCategorySelect(category_guid) {
        setSelectedCategory(category_guid);
        document.dispatchEvent(new CustomEvent("dd:requestClose"));
    }

    function handleCategoryAddClick() {
        setPickerShown(true);
        setEditableCategory({ category: {}, isEditable: false });
    }

    function handlePickerClose() {
        setPickerShown(false);
    }

    const handlePickerSave = async (category) => {
        category.category_guid = await CategoryService.createCategory(location.pathname, category, getMemberFirmID());

        dispatch({
            type: ACTIONS.CATEGORY_CREATE,
            payload: category,
        });
        setPickerShown(false);
    };

    const handlePickerUpdate = async (category) => {
        category.category_guid = await CategoryService.updateCategory(location.pathname, category, category.category_guid, getMemberFirmID());

        dispatch({
            type: ACTIONS.CATEGORY_UPDATE,
            payload: category,
        });
        setPickerShown(false);
    };

    function isScrolledIntoView(el, _isArticlePdf) {
        if (!el) return;
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        if (!isWideMode) {
            setMaxHeightTocBody(rect);
        }
        // Only completely visible elements return true:
        const progressBarOffset = 8;
        var isVisible = elemTop >= progressBarOffset;

        // return _isArticlePdf || isVisible;
        return isVisible;
    }

    const handleDocumentClick = async (ev) => {
        closeCopyOverlay(ev);
        if (ev.target.nodeName === "path" && ev.target.id !== '') {
            ev.preventDefault();
            setAnnotationPanelOpen(false);
            setSelectedId(ev.target.id);
            setSelectedAnnotation(0);
            setAnnotationPanelOpen(true);
            scrollToAnnotation(ev.target.id);
        }
        else {
            let targetBaseUrl = `/${memberFirm}/${language}/${knowledgeDomain}`;
            const tocUrls = LINKHELPERS.getTocUrls(targetBaseUrl);

            //NB: Having an await inside an event creates a different thread. 
            //  This additional thread causes the page to "wait" for a response, 
            //  but as the response happens on a different thread(as we await it), 
            //  it waits for ever and kills the page.
            //  Therefore we add an async wrapper here to stop the page from frezing.
            (async () => {
                let navigatableLink = await LINKHELPERS.getNavigatableLink({
                    ev,
                    tocUrls,
                    memberFirm: memberFirm, language: language, knowledgeDomain: knowledgeDomain,
                    messageCannotOpenTab: getLabel("l_InternalLinkCannotBeOpenedInANewTab", "Internal link cannot be opened in a new tab/window in Pilot"),
                    messageCannotResolve: getLabel("l_LinkCannotBeResolved", "Link cannot be resolved."),
                    isSearchResultLink: false,
                    memberFirms
                });
                if (navigatableLink) {
                    let targetURL = window.location.origin + navigatableLink
                    if (LINKHELPERS.isLocalToThisPageLink(tocUrls, navigatableLink)) {
                        //If the current URL already has a hash, and the current hash is the same, then we just scroll the page "again"
                        if (window.location.hash && (navigatableLink === `${window.location.pathname}${window.location.hash}`)) {
                            ARTICLEHELPERS.scrollToHashTag(window.location.hash);
                        }
                        else {
                            navigate(navigatableLink);
                        }
                    }
                    else {
                        if (navigatableLink.split(";").length > 1) {
                            targetURL = navigatableLink.split(";")[0];
                            // TL link : Set Redirect Modal visible and send parameters from here
                            UTILITIESHELPER.setLocalStorage("isContentLoadError", false);
                            setMessageRedirectTL(getLabel("l_LinkModalViewLinkTL", "The content requested is not currently available in ORP. Would you like to see this content in Technical Library?"))
                            setRedirectURL(navigatableLink.split(";")[0]);
                            setShouldShowOverlayRedirect(true);
                            setIsRedirectModalVisible(true);
                        }
                        else {
                            //We need to call the window directly and with noreferer, so that we "disconnect"
                            //  the target browser and activity/closing from the currnet tab (or it will freeze the current tab!)
                            window.location.href = LINKHELPERS.sanitizeUrl(navigatableLink);
                        }
                    }
                    if (!UTILITIESHELPER.isStringNullorEmpty(targetURL)) {
                        linkProps.targetURL = targetURL;
                        logs.event('link', linkProps);
                    }
                }
                else {
                    if (!UTILITIESHELPER.isStringNullorEmpty(ev.target.href)) {
                        linkProps.targetURL = ev.target.href;
                        logs.event('link', linkProps);
                    }
                }
            })();
        }
    };

    useEffect(() => {
        UTILITIESHELPER.setLocalStorage("isContentLoadError", false);
        (async () => {
            var categories = await CategoryService.fetchCategories(location.pathname, getMemberFirmID());
            dispatch({
                type: ANN_ACTIONS.CATEGORY_LOAD,
                payload: categories,
            });
        })();
    }, []);//eslint-disable-line

    useEffect(() => {
        // handleResize()
        const articleContainerElem = document.querySelector(".article-container");
        isScrolledIntoView(articleContainerElem);
    }, [isWideMode, shouldBeFixed]);//eslint-disable-line

    useEffect(() => {
        const articleContainerElem = document.querySelector(".article-container");
        const _shouldBeFixed = !isScrolledIntoView(
            articleContainerElem,
            isArticlePdf
        );
        setShouldBeFixed(_shouldBeFixed);

        const handleScroll = (ev) => {
            const _shouldBeFixed = !isScrolledIntoView(articleContainerElem, isArticlePdf);
            setShouldBeFixed(_shouldBeFixed);
        };
        const throttledScroll = throttle(handleScroll, 16);
        window.addEventListener("scroll", throttledScroll, { passive: true });
        articleContainerElem.addEventListener("click", handleDocumentClick);
        document.addEventListener("keyup", isKeyPressed);
        document.oncontextmenu = function (ev) {
            //WE no longer have the data node for internal/external, so look at what is inside the URL
            //  basically if it starts with HTTP, we consider it external!
            const el = LINKHELPERS.getClosestLinkElement(ev);
            if (el?.href) {
                if (LINKHELPERS.existsInsideParentClass(el, "article-content-container")) {
                    let hrefValue = el?.href;
                    let currentHref = window.location.href;
                    let prefixLocation = currentHref.substring(0, currentHref.lastIndexOf('/') + 1);
                    //We assume if the current href contains the current domain url, that it did not start with http, i.e. it was an internal link
                    if (hrefValue.indexOf(prefixLocation) > -1) {
                        //Internal link, so we handle it...
                        ev.preventDefault();

                        //New open in new window instead of message...
                        let targetBaseUrl = `/${memberFirm}/${language}/${knowledgeDomain}`;
                        const tocUrls = LINKHELPERS.getTocUrls(targetBaseUrl);

                        //NB: Having an await inside an event creates a different thread. 
                        //  This additional thread causes the page to "wait" for a response, 
                        //  but as the response happens on a different thread(as we await it), 
                        //  it waits for ever and kills the page.
                        //  Therefore we add an async wrapper here to stop the page from frezing.
                        (async () => {
                            let navigatableLink = await LINKHELPERS.getNavigatableLink({
                                ev,
                                tocUrls,
                                memberFirm: memberFirm, language: language, knowledgeDomain: knowledgeDomain,
                                messageCannotOpenTab: getLabel("l_InternalLinkCannotBeOpenedInANewTab", "Internal link cannot be opened in a new tab/window in Pilot"),
                                messageCannotResolve: getLabel("l_LinkCannotBeResolved", "Link cannot be resolved."),
                                isSearchResultLink: false,
                                memberFirms
                            });
                            if (navigatableLink) {
                                if (navigatableLink.split(";").length > 1) {
                                    // TL link : Set Redirect Modal visible and send parameters from here
                                    UTILITIESHELPER.setLocalStorage("isContentLoadError", false);
                                    setMessageRedirectTL(getLabel("l_LinkModalViewLinkTL", "The content requested is not currently available in ORP. Would you like to see this content in Technical Library?"))
                                    setRedirectURL(navigatableLink.split(";")[0]);
                                    setShouldShowOverlayRedirect(true);
                                    setIsRedirectModalVisible(true);
                                }
                                else {
                                    //We need to call the window directly and with noreferer, so that we "disconnect"
                                    //  the target browser and activity/closing from the currnet tab (or it will freeze the current tab!)
                                    window.open(LINKHELPERS.sanitizeUrl(navigatableLink), '_blank', 'noreferrer');
                                }
                            }
                        })();
                    }
                }
            }
        };

        return () => {
            window.removeEventListener("scroll", throttledScroll);
            articleContainerElem.removeEventListener("click", handleDocumentClick);
            document.removeEventListener("keyup", isKeyPressed);
            const sidePannelBody = document.querySelector(".side-pannel-body");
            if (sidePannelBody) {
                //FE:TODO: This is compareing pathname with full url!
                //  full url: http://a.c/x/y/z
                //  path    : x/y/z
                //  Therefore it will ALWAYS be less - so not sure what this is calculating.changing on the page, but it seams that it will alsows be the first part!
                //if (window.location.pathname.split("/").length < routeInfo.url.split("/").length) {
                var sidePanelSmall = document.querySelector(".side-pannel-small");
                if (sidePanelSmall) {
                    var sidePanelSmallDim = sidePanelSmall.getClientRects();
                    if (sidePanelSmallDim) {
                        let elemTop = sidePanelSmallDim[0].top;
                        const maxHeightCalc = `calc(100vh - 61px} - ${elemTop > 0 ? Math.round(elemTop) : 1}px`;

                        sidePannelBody.style.maxHeight = maxHeightCalc;
                        //}
                        //else {
                        //    if (sidePannelBody.style.maxHeight === "" || sidePannelBody.style.maxHeight === "none") {
                        //        sidePannelBody.style.maxHeight = tocMaxHeight;
                        //    }
                        //}
                    }
                }
            }
            setIsJumpLinksOpen(false);
            setShouldBeFixed(false);
            //setAnnotationPanelOpen(false);
            //setSelectedAnnotation(0);
        }
    }, [isArticlePdf,isMemberFirmsLoaded]);//eslint-disable-line

    useEffect(() => {
        // no need to scroll if the value is zero, automatically it will be at the top
        if (yAxisOfToc !== 0) {
            scrollTocToKnowLocation(yAxisOfToc);
        }
    }, [yAxisOfToc]);

    function scrollTocToKnowLocation(yAxisOfToc) {
        var tocBody = document.getElementsByClassName("side-pannel-body")[0];

        var dir = "scrollTop";
        var res = !!tocBody[dir];

        if (!res) {
            tocBody[dir] = 1;
            res = !!tocBody[dir];
            tocBody[dir] = 0;
        }

        if (res) {
            tocBody.scrollTo(0, yAxisOfToc);
            tocBody.focus();
        }
    }

    function closeCopyOverlay(ev) {
        const toolBar = document.querySelector(".toolbar");
        if (!ev.target.closest(".cf-right") && toolBar) {
            setClosedToolbar(true);
        }
    }

    function isKeyPressed(e) {
        if (e.keyCode === 39 || e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 40 ) {
            if (document.getSelection().toString().trim().length > 0) {
                setClosedToolbar(true);
                e.preventDefault();
                interactiveToolbar(e);
            }
            else {
                setClosedToolbar(true);
            }
        }
    }

    function interactiveToolbar(e) {
        let active = false;
        const toolbarContainer = document.querySelector(".toolbar");
        if (!toolbarContainer) return;
        const selectedText = document.getSelection();
        const textStr = selectedText.toString().trim();
        const recursiveItemElements = (item) => {
            if (typeof (item.className) !== 'object') {
                if ((item.className && item.className.includes('normal-link-in-reading-mode')) || (item.className && item.className.includes('prev-next-article-links-container'))) {
                    setIsDupAnnotation(true);
                }
                else {
                    setIsDupAnnotation(false);
                }
                if (item.className && item.className.includes('annotation-panel-wrapper')) {
                    setClosedToolbar(true);
                    active = true;
                }
            }
            let subItems = item.children;
            let childItemArray = Array.from(subItems);
            if (childItemArray && childItemArray.length > 0) {
                childItemArray.forEach((subItem) => {
                    recursiveItemElements(subItem);
                })
            }
        }
        if (textStr.length > 0) {
            let range = selectedText.getRangeAt(0);
            let cloned = range.cloneContents();
            recursiveItemElements(cloned);
            if (active) return
            if (cloned?.children?.length > 1 && selectedText.extentOffset === 0) {
                const recursiveReduceSelection = () => {
                    selectedText.modify('extend', 'left', 'character')
                    if (selectedText.extentOffset === 0)
                        recursiveReduceSelection()
                }
                recursiveReduceSelection()
                if (selectedText.isCollapsed)
                    return
                range = selectedText.getRangeAt(0)
                cloned = range.cloneContents();
            }
            const duplicateSelection = cloned.querySelector(".highlighted");
            const duplicateAnnSelection = selectedText.baseNode.parentNode.className === 'highlighted';
            setIsArticleSearchActive(searchText.length > 2 ? true : false)
            if (duplicateSelection || duplicateAnnSelection) {
                setIsDupAnnotation(true);
            }
            const articleContainer = document.querySelector(".cf-right");
            const bounds = articleContainer.getBoundingClientRect();
            const x = e.clientX - bounds.left;
            const y = e.clientY - bounds.top;
            toolbarContainer.style.top = y + "px";
            toolbarContainer.style.left = x + "px";
            setClosedToolbar(false);
        } else {
            setClosedToolbar(true);
        }
    }

    function interactiveToolbarClear(e) {
        const toolbarContainer = document.querySelector(".toolbar");
        if (!toolbarContainer) return;
        setClosedToolbar(true);
        setIsDupAnnotation(false);
        document.getSelection().empty();
    }

    //Handles the initial word download button click
    function wordHandler(e) {
        e.preventDefault();

        //open download panel.
        setDownloadPanelOpen((prevVal) => !prevVal);
    }

    function bookmarkHandler(e) {
        e.preventDefault();
        setBookmarkOpen((prevVal) => !prevVal);
        setshouldShowDarkOverlay(true);
    }

    async function bookmarkIcon(e) {
        setCategoryOn(false);
        setBookmarkOpen(false);
        const bookDelete = await BookmarkService.deleteBookmarks(location.pathname, [bookmarkId]);
        const payload = {
            bookmark_guid: bookmarkId,
        }
        if (bookDelete) {
            dispatch({
                type: ANN_ACTIONS.BOOKMARK_REMOVE,
                payload: payload,
            });
        }
        setCategoryOn(false);
        setBookmarkOpen(false);
    }

    //Handles the initial pdf download button click
    function pdfHandler(e) {
        e.preventDefault();

        // Save for future state
        // setDownloadPanelOpen(prevVal => !prevVal);
        const delegate = document.getElementById("single-pdf-download");
        if (delegate !== null) {
            delegate.click();
        }
        else {
            logs.error(page.Article, "Article", ErrorMessages.pdfHandler, {},{eventId:ErrorCodes.Article});
        }
    }

    const publicationDisplayTitle = getDisplayTitle(pubData, isGuidebook);
    const _shouldBeFixed = shouldBeFixed; // && !isArticlePdf;
    const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
    const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
    const [isSectionPath, setIsSectionPath] = useState(false);
    const [FixedToolContainerStyleTop, setFixedToolContainerStyleTop] = useState(220);
    const [visible, setVisible] = useState(false);

    const [shouldShowArticlePubModal, setShouldShowArticlePubModal] = useState(false);
    const [publicationModalDetails, setPublicationModalDetails] = useState({});
    const renderEmbeddedOrNormal = () => {
        if (isEmbeddedMode) {
            return <EmbeddedArticle data={embeddedPubData} />;
        }
        return articleContent;
    };

    function shareHandler(e) {
        setShouldShowShareModal(true);
        setShouldShowOverlay(true);
        setIsSectionPath(false);
    }

    function annotationPanelHandler(e) {
        //annotation panel shows
        e.preventDefault();
        setAnnotationPanelOpen((prevVal) => !prevVal);
        if (!annotationPanelOpen) {
            setSelectedId();
        }
    }

    useEffect(() => {
        if (!hasFetchedArticleData || !articleHasLoaded) {
            return;
        }
        removeLinkStyleSheet();
        if (articleHasLoaded === true && !UTILITIESHELPER.isNullOrUndefined(searchPhrase) && searchPhrase !== '') {
            // FGP: Saving, just in case
            // function escapedSearchVal(searchVal) {
            //     return searchVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').replace(/"/g, '');
            // }
            // const searchVal = escapedSearchVal(paramSessStore);
            setSearchText(SearchHelpers.toHumanReadable(searchPhrase));
            setIsSearchExpanded(true);
            if (SearchHelpers.IsSearchPrefixWithWildcards(searchPhrase)) {
                setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'Article' });
                return;
            }
            setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
        }

        return () => {
            if (document.getElementsByClassName("search-badge-article").length === 0) {
                setSearchText("");
                sessionStorage.removeItem('searchPhraseParam');
            }
        };
        //eslint-disable-next-line
    }, [hasFetchedArticleData, articleHasLoaded]);

    useEffect(() => {
        if (downloadPanelOpen === true) {
            const scrolled = document.documentElement.scrollTop;
            resetDownloadpanelHeight(scrolled);
        }
    }, [downloadPanelOpen]);

    useEffect(() => {
        const adjustOnScroll = debounce(() => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled === 0) {
                setFixButtonOverlap();
            }
            // Top of Article button visible True/False
            if (scrolled > 300) {
                setVisible(true);
            }
            else if (scrolled <= 300) {
                setVisible(false);
            }

            resetDownloadpanelHeight(scrolled);
            resetannotationpanelHeight(scrolled);

            // Fixed side buttons scroll
            if (scrolled > 100) {
                const headerheight = 60;
                _StyleTop = scrolled - headerheight; //eslint-disable-line
                if (document.documentElement.scrollHeight - Math.ceil(document.documentElement.scrollTop) > document.documentElement.clientHeight) {
                    if (_StyleTop > 50) {
                        if (scrolled > 200) {
                            setFixedToolContainerStyleTop(heighttoset + 20);
                        }
                        else {
                            if (FixedToolContainerStyleTop > _StyleTop) {
                                setFixedToolContainerStyleTop(heighttoset + _StyleTop);
                            }
                        }
                    }
                    else {
                        setFixedToolContainerStyleTop(heighttoset + 220);
                    }
                }
            }
            else {
                setFixedToolContainerStyleTop(heighttoset + 203);
            }
        }, 500);

        window.addEventListener("scroll", adjustOnScroll);
        setFixButtonOverlap();
        return () => {
            window.removeEventListener("scroll", adjustOnScroll);
        };
    }, []);


    function removeLinkStyleSheet() {
        const itemList = document.querySelectorAll(".article-content-container a");
        if (itemList && itemList.length > 0) {
            const items = Array.from(itemList);
            items.forEach((item) => {
                const text = decodeURI(item.href)?.toLowerCase();
                if (text.includes('missing href value')) {
                    item.classList.add("remove-link-style");
                    item.removeAttribute("href");
                    item.removeAttribute("target");
                }
            });
        }
    }

    function resetBookmarks() {
        dispatch({
            type: ANN_ACTIONS.BOOKMARKS_RESET,
        });
    }

    // Fetching annotations from api on page load and memberfirm
    useEffect(() => {
        setArticleAnnotation([]);
        async function fetchAnnotations() {
            let annotations = await ANNSERVICES.fetchAnnotations(location.pathname, getMemberFirmID());
            setTocAnnotations(annotations);
            let articleAnnotations = annotations?.filter(item => item.reference_identifier === CONTEXTHELPERS.getGUIDFromContext(context));
            dispatch({
                type: ANN_ACTIONS.ANNOTATIONS_LOAD,
                payload: articleAnnotations,
            });
            setArticleAnnotation(articleAnnotations);
        }
        if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
            fetchAnnotations();
        }
        return () => {
            dispatch({
                type: ANN_ACTIONS.ANNOTATIONS_RESET
            });
        }
    }, []);//eslint-disable-line

    useEffect(() => {
        if(JSON.stringify(articleAnnotation) !== JSON.stringify(annState.annotations)) {
            setArticleAnnotation(annState.annotations);
        }
    }, [annState.annotations]);

    function addCommentToHighlighter(item, color) {
        let commentEle = document.getElementById('comment' + item.annotation_guid);
        if (commentEle !== null) {
            commentEle.innerHTML = `${ReactDOMServer.renderToString(
                <AnnotationCommentIcon
                    fillColor={color}
                    preferenceId={item.annotation_guid}
                />
            )}`;
        }
        else {
            item.range.collapse(false);
            commentEle = document.createElement("span");
            commentEle.id = "comment" + item.annotation_guid;
            commentEle.innerHTML = `${ReactDOMServer.renderToString(
                <AnnotationCommentIcon
                    fillColor={color}
                    preferenceId={item.annotation_guid}
                />
            )}`;
            let highlightedDOM = document.querySelectorAll('.highlighted');
            let highlighters = Array.from(highlightedDOM);
            const getLastMarkTag = highlighters.filter(a => a.id === item.annotation_guid).pop();
            if (getLastMarkTag) {
                getLastMarkTag.after(commentEle);
            }
        }
    }

    function removeTextFromTextAnnotations(deletedId) {
        //Create spanId to fetch all the deleted comment icons
        const spanId = '#comment' + deletedId;
        const Annotateitems = document.querySelectorAll('.highlighted');
        const items = Array.from(Annotateitems);
        //fetch the deleted mark tag deom all the annotations
        const getLastMarkTag = items?.filter(a => a.id === deletedId);
        const getSpanTag = document.querySelectorAll(spanId);
        const commentIcon = Array.from(getSpanTag);
        if (!UTILITIESHELPER.isArrayNullorEmpty(getLastMarkTag)) {
            for (var i = 0; i < getLastMarkTag.length; i++) {
                var text = getLastMarkTag[i].textContent || getLastMarkTag[i].innerText;
                var node = document.createTextNode(text);
                getLastMarkTag[i].parentNode.replaceChild(node, getLastMarkTag[i]);
                const annotationTextsCopy = [...annotationTexts]
                const annotationTextIndexToRemove = annotationTextsCopy.indexOf(text);
                annotationTextsCopy.splice(annotationTextIndexToRemove, 1);
                setAnnotationTexts([...annotationTextsCopy]);
            }
        }
        if (commentIcon.length > 0) {
            commentIcon[0].remove();
        }
        //annState.isSidePanelDelete = false;
    }

    function cleanHighlights() {
        const articleContainer = document.getElementsByClassName("article-content-container")[0];
        if (articleContainer) {
            const annotateitems = articleContainer.querySelectorAll('.highlighted');
            if (annotateitems.length > 0) {
                const highlightedItems = Array.from(annotateitems);
                if (highlightedItems) {
                    highlightedItems.forEach(item => {
                        if (!articleAnnotation.map(item => item.annotation_guid)?.includes(item.id)) {
                            removeTextFromTextAnnotations(item.id);
                        }
                    });
                }
                setAnnotateTerms(articleContainer.querySelectorAll('.highlighted'));
            }
        }
    }

    useEffect(() => {
        var articleContainer = document.getElementsByClassName(
            "article-content-container"
        )[0];
        async function isAnnotationsVersionUpdated() {
            if (articleAnnotation && articleAnnotation.length > 0) {
                articleAnnotation.filter(item => item.reference_identifier === CONTEXTHELPERS.getGUIDFromContext(context)).forEach((item) => {
                    // checking first condition if the version of the article and the annotations are equal
                    var currContentVersion = guidebookArticle?.version ?? articleVersion;
                    var annotationVersion = item.version === undefined ? 1 : item.version;

                    if (annotationVersion !== currContentVersion) {
                        item.is_annotations_version_updated = true;
                        setIsArticleUpdated(true);
                    }
                });
            }
            else {
                setAnnotateTerms([]);
            }
        }

        function renderAnnotations () {
            if ((articleVersion !== 0 || guidebookArticle?.version !== undefined) &&
                !UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedContentItemGUID) &&
                (UTILITIESHELPER.isStringNullorEmpty(searchPhrase) || searchPhrase.length <= 2) &&
                annState?.categories &&
                !(document.getElementsByClassName("search-badge-article").length > 0) && articleContainer) {
                if (articleAnnotation && articleAnnotation.length > 0) {
                    let color = '';
                    articleAnnotation.filter(item => item.reference_identifier === CONTEXTHELPERS.getGUIDFromContext(context)).forEach((item) => {
                        removeTextFromTextAnnotations(item.annotation_guid);
                        color = annState?.categories.find(cat => cat.category_guid === item.category_guid)?.color;
                        if (!UTILITIESHELPER.isStringNullorEmpty(color)) {
                            item.range = rebuildRange(item.range_position);
                            AnnotationHighlighter(item.range, articleContainer, color, item.annotation_guid);
                            if (item.annotation_type === "Comment") {
                                addCommentToHighlighter(item, color);
                            }
                        }
                    });
                }
                else {
                    setAnnotateTerms([]);
                }
            }
            //cleaning the highlights
            cleanHighlights();

            isAnnotationsVersionUpdated();
        }

        articleAnnotation && articleAnnotation.length > 0 ?  renderAnnotations():cleanHighlights();
        
    }, [searchPhrase, url, articleAnnotation, annState.categories]);//eslint-disable-line

    useEffect(() => {
        if (!hasFetchedArticleData || !articleHasLoaded || !(articleAnnotation && articleAnnotation.length > 0)) {
            return;
        }
        if (annotationPanelOpen && selectedId) {
            if ((document.documentElement.scrollTop - offsetWhenAnnPanelOpened) > -100 || (!(document.documentElement.scrollTop - offsetWhenAnnPanelOpened) > 100)) {
                scrollToAnnotation(selectedId);
            }

        }
    }, [annotationPanelOpen, articleAnnotation])//eslint-disable-line

    useEffect(() => {
        if (!hasFetchedArticleData || !articleHasLoaded) {
            return;
        }
        if (selectedAnnotation !== 0) {
            setAnnotationPanelOpen(true);
            setSelectedId(selectedAnnotation.annotation_guid);
        }

        return () => {
            setAnnotationPanelOpen(false);
            setSelectedAnnotation(0);
        }
    }, [])//eslint-disable-line

    function scrollToAnnotation(annId) {
        const resultsContainer = document.querySelector('.article-content-container');
        const Annotateitems = resultsContainer?.querySelectorAll('.highlighted');
        const items = Array.from(Annotateitems);
        const objIndex = items.findIndex((obj) => obj.id === annId);
        const articleContainer = document.querySelector('.article-container');
        let articleOffset = articleContainer.getBoundingClientRect().top;
        let offset = Annotateitems[objIndex]?.getBoundingClientRect().top - articleOffset;
        setOffsetWhenAnnPanelOpened(offset)
        window.scrollTo({ top: offset, left: 0, behavior: "instant", });
    }
   
    useEffect(() => {
        async function fetchBookmarks() {
            resetBookmarks();
            const bookmarkData = await BookmarkService.fetchBookmarks(location.pathname, getMemberFirmID());
            if (bookmarkData?.length > 0) {
                dispatch({
                    type: ANN_ACTIONS.BOOKMARKS_LOAD,
                    payload: bookmarkData,
                });
            }
        }

        let tocBody = document.getElementsByClassName("side-pannel-body")[0];
        let selectedElement = document.querySelector(".toc-item-text-container-selected");

        TOCHELPERS.scrollParentToChild(tocBody, selectedElement);
        if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
            fetchBookmarks();
        }
    }, [])//eslint-disable-line

    useEffect(() => {
        setTocIconBookmark(annState?.bookmarks);
        const guid = CONTEXTHELPERS.getGUIDFromContext(context);
        var bookmarked = annState?.bookmarks?.find(q => q.reference_identifier === guid);
        if (bookmarked) {
            var catColor = "";
            if (annState?.categories?.length > 0) {
                const category = annState.categories.find(
                    (obj) => obj.category_guid === bookmarked.category_guid
                );
                if (category) {
                    catColor = category.color;
                }
                setFillColors(catColor);
            }
            setBookmarkId(bookmarked.bookmark_guid);
            setCategoryOn(true);
            setSelectedCategory(bookmarked.category_guid);
        }
        else {
            setBookmarkId();
            setCategoryOn(false);
            setSelectedCategory('');
        }
    }, [annState?.bookmarks, annState?.categories])//eslint-disable-line

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };    
    
    const handleKeyDown = (event) => {        
        if (event.altKey && event.shiftKey && event.key === 'K') {
            publicationModalDetails.publicationType = pubData.publication_type;
            publicationModalDetails.title = pubData.title;
            publicationModalDetails.publicationVersion = pubData.version;
            publicationModalDetails.industry = pubData.industry;
            publicationModalDetails.memberFirm = pubData.metadata.member_firm.join(",");
            publicationModalDetails.knowledgeDomain = pubData.context.knowledge_domain.join(",");
            publicationModalDetails.auditWorkflowAreaAccount = pubData.metadata.audit_workflow_area_account;
            publicationModalDetails.auditWorkflowAreaNonAccount = pubData.metadata.audit_workflow_area_nonaccount;     
            publicationModalDetails.publicationReleaseDate = new Date(pubData.release_date).toLocaleString();
            setShouldShowArticlePubModal(true);          
        }
      };      
    var _StyleTop;

    function resetDownloadpanelHeight(scrolled) {
        const elDownloadPanel = document.querySelector(".download-panel");
        const elheader = document.querySelector(".header-container");
        var headerheight = elheader !== null ? 88 : 50;
        if (elDownloadPanel !== null) {
            if (scrolled === 0) {
                if (headerheight === null) {
                    elDownloadPanel.style.height = "calc(99vh - 44px)";
                }
                else {
                    elDownloadPanel.style.height = window.innerHeight - headerheight + document.documentElement.scrollTop + 3 + "px";
                }
            }
            // else if (scrolled < 300) {
            //     elDownloadPanel.style.height = (window.innerHeight - headerheight + document.documentElement.scrollTop + 3) + "px";
            // 
            else {
                elDownloadPanel.style.height = "";
            }
        }
    }

    function resetannotationpanelHeight(scrolled) {
        const elAnnatationPanel = document.querySelector(".annotation-panel-wrapper");
        const elbreadcrumb = document.querySelector(".crumbtrail");
        const heightofcrumb = 36 - elbreadcrumb?.clientHeight;

        if (elAnnatationPanel !== null) {
            if (scrolled > 80 && scrolled < 167) {
                elAnnatationPanel.style.top = "5px";
            }
            if (scrolled > 167) {
                elAnnatationPanel.style.top = "";
                elAnnatationPanel.style.height = ''
            }

            if (scrolled > 0 && scrolled < 167) {
                if (scrolled < 85) {
                    elAnnatationPanel.style.height = scrolled - 105 + window.innerHeight + "px";
                }

                if (scrolled > 85) {
                    elAnnatationPanel.style.height = -25 + window.innerHeight + "px";
                }
            }

            if (scrolled === 0) {
                elAnnatationPanel.style.height = "";
                elAnnatationPanel.style.top = "";
            }

            if (scrolled >= 0 && scrolled < 81) {
                elAnnatationPanel.style.top = '';
                if (heightofcrumb !== 0) {
                    elAnnatationPanel.style.top = 88 + (-scrolled) + 'px';
                }
            }
        }
    }

    function setFixButtonOverlap() {
        const crumbtrail = document.querySelector(".crumbtrail");

        if (
            crumbtrail !== null &&
            crumbtrail.clientHeight &&
            crumbtrail.clientHeight > 25
        ) {
            heighttoset = crumbtrail.clientHeight;
        }
        setFixedToolContainerStyleTop(heighttoset / 2 + 220);
    }

    function handleCloseClick() {
        setBookmarkOpen((prevVal) => !prevVal);
        setshouldShowDarkOverlay(false);
        handlePickerClose();
        dispatch({ type: ANN_ACTIONS.MODAL_HIDE });
    }

    const closeNotification = () => {
        setIsArticleUpdated(false);
    }
 
    String.format = function (format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };

    return (
        <>
            <ShowIf condition={hasFetchedArticleData}>
                <HorizontalScrollbar shouldBeFixed={_shouldBeFixed} />
                <ShowIf condition={showSearch && !annotationPanelOpen && !downloadPanelOpen}>
                    <ArticleSearch
                        className={
                            cx({
                                'searcbar-container-on-article-page': !_shouldBeFixed,
                                'annotation-on': !shouldBeFixed && annotationPanelOpen,
                            })}
                        isExpanded={isSearchExpanded}
                        setIsExpanded={setIsSearchExpanded}
                        isFixed={_shouldBeFixed}
                    />
                </ShowIf>
            </ShowIf>
            <ShowIf condition={_shouldBeFixed}>
                <FixedNavArticle
                    isWideMode={isWideMode}
                    setIsWideMode={setIsWideMode}
                    isJumpLinksOpen={isJumpLinksOpen}
                    setIsJumpLinksOpen={setIsJumpLinksOpen}
                    title={articleTitle}
                    navTitle={navTitle}
                    pubLandingUrl={pubLandingUrl}
                    shouldBeFixed={_shouldBeFixed}
                    pubData={pubData}
                    showInArticleTOC={showInArticleTOC}
                    removeInArticleTOC={false}
                />
            </ShowIf>
            <div
                className={cx("article-container cf-right",
                    {
                        "article-container-wide-mode": isWideMode,
                        'invisible': !hasFetchedArticleData,
                        "article-annotation": annotationPanelOpen,
                        'article-container-wide-annotation-on': annotationPanelOpen && isWideMode
                    }
                )}
            >
                <ShowIf condition={hasFetchedArticleData}>
                    <ShowIf condition={isWideMode}>
                        <IconWideModeTOC
                            publicationType={publicationType}
                            onClick={() => setIsWideMode(false)}
                            className={cx({ "icon-closed-sidebar-container-fixed-nav": _shouldBeFixed })}
                            shouldBeFixed={_shouldBeFixed}
                        />
                    </ShowIf>
                    <div className={cx("d-flex article-container-inner", {
                        'article-container-inner-for-pdf': isArticlePdf,
                        'm-auto wide-margin': isWideMode,
                    })}
                        onMouseDown={interactiveToolbarClear}
                        onMouseUp={interactiveToolbar}
                    >
                        <div style={annotationUpdateStyling}>
                            <div className="">
                                <div className={isArticleUpdated ? "annotation-article-update-message delete-notification" : "annotation-article-update-message hide"}>
                                    <div className="d-flex">
                                        <span className="warning">&nbsp;</span>
                                        <div className="annotation-article-update-message-text">
                                            <p>{getLabel("hh_HeaderMessageAnnotationPaneFirst", "Please visit the ")}
                                                {/*eslint-disable-next-line*/}
                                                <a className="annotation-panel-linkk" onClick={(e) => annotationPanelHandler(e)}>Annotation panel</a>
                                                {
                                                    getLabel("hh_HeaderMessageAnnotationPaneFirst", " or see the annotations that has been affected by an article update")
                                                }</p>
                                        </div>
                                    </div>
                                    <button className="close-warning" onClick={closeNotification}>&nbsp;</button>
                                </div>
                                <HiddenMetadata metadata={props.pubData.metadata}></HiddenMetadata>
                                <div className="d-flex flex-grow">
                                    <ShowIf condition={!isArticlePdf}>
                                        <ArticleTOC
                                            isOpen={isJumpLinksOpen}
                                            setIsOpen={setIsJumpLinksOpen}
                                            // Conditionally rendering this component instead of making it invisible
                                            // was causing a small performance issue where the layout would adjust for a split second
                                            className={cx({ 'invisible': _shouldBeFixed })}
                                            classNameContainer={cx({ 'invisible': !showInArticleTOC })}
                                            pubData={pubData}
                                        />
                                    </ShowIf>
                                    <div className="d-flex flex-grow">
                                        <ArticleTitle
                                            title={articleTitle}
                                            className={_shouldBeFixed ? "article-title-fixed" : ""}
                                            style={articleTitleStyle}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ShowIf condition={isGuidebook && isFiltered && ("|templates|rp-templates|examples|disclosures|questions-and-answers|other|".indexOf(`|${context.pageSelectedSection}|`) < 0)}>
                                <div style={articleNotificationStyle} >
                                    <ArticleNotification />
                                </div>
                            </ShowIf>
                            <FootnotePopup dependenciesToRecalculatePosition={[isWideMode]} />
                            <div
                                className={cx("article-content-container", {
                                    "article-content-container-pdf": isArticlePdf,
                                    "article-content-container-annotation": annotationPanelOpen
                                })}
                            >
                                {renderEmbeddedOrNormal()}
                                <PrevNextArticle articleHasLoaded={articleHasLoaded} />
                            </div>
                        </div>
                        <ShowIf condition={annotationPanelOpen && hasFetchedArticleData}>
                            <AnnotationPanel page={props} shouldBeFixed={shouldBeFixed} id={selectedId} articleAnnotations= {articleAnnotation} />
                        </ShowIf>
                        <ShowIf condition={showFixedTools && !annotationPanelOpen}>
                            <div className="tools-container">
                                <div className="fixed-tools-container sticky-tools">
                                    {/*isDownloadableArticle*/}
                                    <ShowIf condition={isBookmarksEnabled() && !isRestrictedAccessMemberfirm() && !categoryOn}>
                                          {/*eslint-disable-next-line*/}
                                        <button role="button" className="icon-article icon-bookmark" onClick={(e) => bookmarkHandler(e)} title={getLabel('hh_Bookmark', 'bookmark')}></button>
                                    </ShowIf>
                                    <ShowIf condition={categoryOn && isBookmarksEnabled() && !isRestrictedAccessMemberfirm()}>
                                        <BookmarkIcon fillcolor={fillColors} onClick={bookmarkIcon} />
                                    </ShowIf>
                                    <ToggleReadingMode handleClick={() => { setIsWideMode(true); }} showInArticleTOC={showInArticleTOC} isFixed={_shouldBeFixed} fixedToolContainerStyleTop={FixedToolContainerStyleTop} />
                                    <ShowIf condition={(isDownloadableArticle && !isArticlePdf) || isArticlePdf}>
                                          {/*eslint-disable-next-line*/}
                                        <button role="button" className="icon-article icon-download" onClick={(e) => (!checkIfDisplayDownloadEnable && isArticlePdf) ? pdfHandler(e) : wordHandler(e)} title={getLabel('a_DownloadToWord', 'Download to Word')}></button>
                                    </ShowIf>
                                    <button className="icon-article icon-share" onClick={(e) => shareHandler(e)} title={getLabel('a_Share', 'Share')}></button>
                                    <ShowIf condition={!isArticlePdf && searchText.trim().length < 3 && isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()}>
                                        <button
                                            className={cx("icon-article icon-annotation", { "selected": annotationPanelOpen })}
                                            onClick={(e) => annotationPanelHandler(e)} title={getLabel('hh_AnnotationPannel', 'Annotation Panel')}></button>
                                    </ShowIf>
                                    <ShowIf condition={(isCompareEnabled()) && !isArticlePdf && !!secondMostRecentVersion}>
                                        <div className="compare-version-body">
                                            <Link to={`?compareVersion=${secondMostRecentVersion}`} className="compare-versions-link" target="_blank">
                                                <button className="icon-article icon-compare-versions"></button>
                                            </Link>
                                            <div className="compare-versions-linktext">
                                                <div>{getLabel('a_Compare', 'Compare')}</div>
                                                <div>{getLabel('a_Versions', 'Versions')}</div>
                                            </div>
                                        </div>
                                    </ShowIf>
                                </div>
                                <div className="fixed-tools-container-bottom" style={{ display: visible ? 'inline' : 'none' }} >
                                    <div>
                                        <button className='icon-scroll-to-top' onClick={scrollToTop}></button>
                                        <div className='icon-scroll-top-label'>{getLabel("a_TopOfArticle", "Top of Article")}</div>
                                    </div>
                                </div>
                            </div>
                        </ShowIf>
                    </div>
                </ShowIf>
                <Toolbar
                    docsPubDisplayTitle={publicationDisplayTitle}
                    setShouldShowShareModal={setShouldShowShareModal}
                    setShouldShowOverlay={setShouldShowOverlay}
                    setIsSectionPath={setIsSectionPath}
                    setClosedToolbar={setClosedToolbar}
                    closedToolbar={closedToolbar}
                    isPdf={isArticlePdf}
                    isBinary={isArticleBinary}
                    isDupAnnotation={isDupAnnotation}
                    isSearchActive={isArticleSearchActive}
                />
                <Modal modalHeader={getLabel("a_Copied", "Copied!")} modalBody={getLabel("a_CopiedToYourClipboard", "Formatted content has been copied to your clipboard. You may now paste content into documents.")} />
                <ShareModal
                    articleTitle={articleTitle}
                    setShouldShowShareModal={setShouldShowShareModal}
                    shouldShowShareModal={shouldShowShareModal}
                    setShouldShowOverlay={setShouldShowOverlay}
                    shouldShowOverlay={shouldShowOverlay}
                    setIsSectionPath={setIsSectionPath}
                    isSectionPath={isSectionPath}
                    setClosedToolbar={setClosedToolbar}
                />
                <ShowIf condition= {shouldShowArticlePubModal}>
                <PublicationDetailsModal
                shouldShowArticlePubModal = {shouldShowArticlePubModal}
                setShouldShowArticlePubModal = {setShouldShowArticlePubModal}
                publicationData = {publicationModalDetails}
                />
                </ShowIf>
                
                {annState.isModalVisible && <AnnotationModal {...annState.tempAnnotation} />}
                <RedirectModal
                    setShouldShowRedirectModal={setIsRedirectModalVisible}
                    shouldShowRedirectModal={isRedirectModalVisible}
                    redirectURL={redirectURL}
                    setShouldShowOverlay={setShouldShowOverlayRedirect}
                    shouldShowOverlay={shouldShowOverlayRedirect}
                    messageRedirectTL={messageRedirectTL}
                />
                <ShowIf condition={downloadPanelOpen}>
                    <DownloadPanel page={props} shouldBeFixed={shouldBeFixed} />
                </ShowIf>
                <ShowIf condition={bookmarkOpen && !categoryOn}>
                    <div>
                        {shouldShowDarkOverlay &&
                            <div className='full-screen-overlay-fixed' onClick={handleCloseClick} />
                        }
                        <div className="category-bookmark" >
                            <BookmarkCategory
                                selected={selectedCategory}
                                onSelect={handleCategorySelect}
                                onAddClick={handleCategoryAddClick}
                                active={bookmarkOpen}
                                id={bookmarkId}
                            />
                            {isPickerShown && (
                                <ColorPicker
                                    onUpdate={handlePickerUpdate}
                                    onSave={handlePickerSave}
                                    onClose={handlePickerClose}
                                    colour={editableCategory.category.color ?? "#FFFF00"}
                                    labelTxt={editableCategory.category.label ?? ""}
                                    isUpdate={editableCategory.isEditable}

                                />
                            )}
                        </div>
                    </div>
                </ShowIf>
            </div>
        </>
    )
}

Article.defaultProps = {
    setHasFetchedArticleData: () => { },
    setIsJumpLinksOpen: () => { },
    setIsWideMode: () => { },
    setShouldBeFixed: () => { },
    setScrollPosition: () => { },
    showInArticleTOC: true,
    showSearch: true,
    showFixedTools: true,
};

export default Article;
