
import React, { useState, createContext, useEffect, useContext } from 'react'
import { useMatch } from 'react-router-dom'
import UTILITIESHELPER from '../helpers/UtilitiesHelper';

export const ThemeContext = createContext()
export const useThemeContext = () => useContext(ThemeContext)

const ThemeContextProvider = ({ children }) => {
    const [hasDarkModePreference, setHasDarkModePreference] = useState(UTILITIESHELPER.getDarkModePrefLocalStorage);
    const routeMatchInfo = useMatch('/:memberFirm/:language/:knowledgeDomain/*')
    const shouldPreventDarkMode = !routeMatchInfo?.params?.knowledgeDomain;
    const isDarkMode = hasDarkModePreference && !shouldPreventDarkMode;
    useEffect(() => {
        document.documentElement.dataset.theme = isDarkMode ? 'dark' : null;
    }, [isDarkMode])

    useEffect(() => {
        UTILITIESHELPER.setDarkModePrefLocalStorage(hasDarkModePreference);
    }, [hasDarkModePreference])

    const store = {
        isDarkMode,
        setHasDarkModePreference,
        hasDarkModePreference,
    }

    return (
        <ThemeContext.Provider value={store}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContextProvider;
