
import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import throttle from 'lodash.throttle';
import cx from 'classnames';
import {get} from 'lodash';
import ShowIf from '../Common/ShowIf';
import NavigableSearchbar from '../Common/NavigableSearchbar';
import Loading from '../Loading/Loading';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import SearchHelpers from '../../helpers/SearchHelpers';
import { appInsightUIMapping, logs,page } from "../../helpers/log";

import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import { setMaxHeightTocBody } from './Article';
import HorizontalScrollbar from '../Article/HorizontalScrollbar';
import FixedNavArticle from './FixedNavArticle';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import SearchResult from '../Common/SearchResult';
import { useArticleContext } from '../../contexts/ArticleContext';
import parse from 'html-react-parser';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ErrorCodes, ErrorMessages, ErrorTypes } from '../Constants/Errors';
import useSearchService from '../../services/useSearchService';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';

const PublicationListingResults = ({
    isWideMode,
    setIsWideMode,
    pubData,
    publicationType,
    hasAllTocDataLoaded,
    pubLandingUrl,
    navTitle
}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const navigate = useNavigate();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const currentSearch = SearchHelpers.toHumanReadable(SearchHelpers.fromQueryString(location?.search));
    const { searchText, setSearchText } = useArticleContext();
    //const [searchPhrase, setSearchPhrase] = useState(currentSearch ? SearchHelpers.toHumanReadable(currentSearch) : '');
    const [searchTerms, setSearchTerms] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [updateRecentSearches, setUpdateRecentSearches] = useState(0);    
    const { toc, flattenedToc, selectedItemTOC } = useTocContext();
    const { getGuidebookSectionData } = useGuidebookTocContext();
    const { context, isJournalListing, isSpinePage, isFolioSectionlisting, searchPhrase, setSearchPhrase, setNotification } = usePageContext();
    const {
        searchResults,
        setSearchResults,
        activeFilters,
        setActiveFilters,
        areSearchResultsLoading,
        setAreSearchResultsLoading,
        searchTimeStamp,
        setSearchTimeStamp,
        latestRetrieveSearchResultsTimeStampRef
    } = useSearchResultContext();

    const [isValidSearchPhrase, setIsValidSearchPhrase] = useState(false); 
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    const searchService = useSearchService();

    // Set SearchPhraseParam SessionStorage effect
    useEffect(() => {
        if (!UTILITIESHELPER.isNullOrUndefined(currentSearch)) {
            if (SearchHelpers.IsSearchPrefixWithWildcards(currentSearch)) {
                setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'PublicationListingResults' });
                setIsValidSearchPhrase(false);
                setAreSearchResultsLoading(false);
                setSearchResults([]);
                return;
            }
            else {
                setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
                setIsValidSearchPhrase(true);
            }
            setSearchPhrase(currentSearch);
            setSearchText(currentSearch);
        }
        const searchPhraseParam = queryParams.get('searchPhrase') === null ? searchText : queryParams.get('searchPhrase');
        
        if (searchPhraseParam !== null) {
            sessionStorage.setItem('searchPhraseParam', searchPhraseParam);
        }
        else {
            let paramSessStore = sessionStorage.getItem('searchPhraseParam');
            if (paramSessStore !== null) {               
                const searchVal = paramSessStore;
                setSearchText(SearchHelpers.toHumanReadable(searchVal));
            }
        }     
    }, []);//eslint-disable-line

    // set Scroll w.r.t to Search Results
    useEffect(() => {
        const articleContainerElem = document.querySelector('.article-container');
        if (articleContainerElem) {
            setMaxHeightTocBody(articleContainerElem.getBoundingClientRect());
            const handleScroll = (ev) => {
                if (!isWideMode) {
                    setMaxHeightTocBody(articleContainerElem.getBoundingClientRect())
                }
                setIsFixed(!isScrolledIntoView(articleContainerElem));
            }
            const throttledScroll = throttle(handleScroll, 16)
            window.addEventListener('scroll', throttledScroll, { passive: true })

            return () => {
                window.removeEventListener('scroll', throttledScroll);
                const sidePannelBody = document.querySelector('.side-pannel-body');
                if (sidePannelBody) {
                    sidePannelBody.style.maxHeight = 'none';
                }
            }
        }
    }, [isWideMode, areSearchResultsLoading])//eslint-disable-line

    // Retrieve Search Results
    useEffect(() => {
        let isCancelled = false;

        function getpubIdsToQuery() {
            let pubIdsToQuery = [];
    
            // This is for Search at PublicationListing Page
            if (isJournalListing || isFolioSectionlisting) {
                if (selectedItemTOC.item?.tableOfContents?.length > 0) {
                    pubIdsToQuery = selectedItemTOC.item.tableOfContents.map(x => x.id);
                }
                else if (selectedItemTOC.item?.subItems?.length > 0) {
                    pubIdsToQuery = selectedItemTOC.item.subItems.map(x => x.id);
                }
            }
            else if (isSpinePage) {
                //if publication is a manual spine pub, we need to send the spine publication ids and no spine
                pubIdsToQuery = flattenedToc                    
                    .filter(({ tocHash }) => (tocHash.includes(selectedItemTOC.item.id)))
                    .map(x => x.tocHash.split('_').pop());
               
                //if not and the pubIdsToQuery is empty
                if(pubIdsToQuery.length === 0) {
                    pubIdsToQuery = flattenedToc
                        .filter(({ url }) => (url.match(/GUID/g) || []).length === 1)
                        .map(x => "GUID-" + x.url.split('GUID-')[1]);
                }
            }           
            // This is only at Publication Level.
            else {
                //Should be the same as context.pageSelectedPublicationGUID
                pubIdsToQuery.push(selectedItemTOC.item.id);
            }
    
            if (!pubIdsToQuery.length) {                
                logs.debug(page.Publication, 'PublicationSearch', 'Publication ID Array is empty. Awaiting required data', {memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain})
                return null;
            }      
    
            // Get unique list of publication ids
            pubIdsToQuery = [...new Set(pubIdsToQuery)];
            let pubIds = 'publication_id/' + pubIdsToQuery.join('/');
    
            return pubIds;
        }
        
        const retrieveSearchResults = async (searchPhrase) => {
            const currentReqTime = Date.now();
            latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;

            if (UTILITIESHELPER.isStringNullorEmpty(searchPhrase) 
                || UTILITIESHELPER.isArrayNullorEmpty(flattenedToc) 
                || !hasAllTocDataLoaded
                || isCancelled 
                || currentReqTime !== latestRetrieveSearchResultsTimeStampRef.current) {
                return;
            }

            try {
                if (isCancelled) {
                    // asynchornous call is still in progress
                    return;
                }        

                //Reset the search dependencies
                setAreSearchResultsLoading(true);
                setSearchResults([]);
                setActiveFilters([]);
                      
                let pubIds = getpubIdsToQuery();
                //let results = await RETRIEVALSERVICES.retrieveSearchResults(searchPhrase, pubIds);
                let results = await searchService.getPublicationSearchResponses(context.pageType, searchPhrase, get(window,'DART.ENV.REACT_APP_COLLECTION_SEARCH_LIMIT',0), pubIds);  
                if (!UTILITIESHELPER.isArrayNullorEmpty(results)) { 
                    let searchresults = results.filter(x => x.highlightsCount > 0);
                    const extendedResults = ARTICLEHELPERS.mapSearchResultsToSelectedToc(searchresults, context, isJournalListing, flattenedToc, isSpinePage, getMFLabel, toc, getGuidebookSectionData, currentSearch);
                    
                    if(!UTILITIESHELPER.isArrayNullorEmpty(extendedResults)) {
                        setSearchResults(extendedResults);
                    }
                }  
                setAreSearchResultsLoading(false);
            }
            catch (err) {
                logs.error(page.Publication, 'PublicationListingPage',ErrorMessages.publicationSearch , err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication });
                navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
            }
        }

        if ((!UTILITIESHELPER.isStringNullorEmpty(searchPhrase) || !isCancelled) && isValidSearchPhrase) {
            retrieveSearchResults(searchPhrase);
        }

        return () => {
            isCancelled = true;
            setSearchResults([]);
            setActiveFilters([]);
        }
        //TODO: Need another listener for the search toggle?
        //eslint-disable-next-line
    }, [hasAllTocDataLoaded, searchTimeStamp, toc, flattenedToc, isValidSearchPhrase]);

    //Update SearchTerms after Search Results are fetched
    useEffect(() => {
        const resultsContainer = document.querySelector('.search-results');
        const searchTerms = resultsContainer?.querySelectorAll('em');

        setSearchTerms(searchTerms);

        return () => {
            setSearchTerms([]);
        }
    }, [searchResults]);

    const getSearchTitle = () => {
        return currentSearch ? parse(`${getLabel("s_SearchResults", "Search Results")} ${getLabel("s_SearchResultsFor", "for")} <em>${SearchHelpers.toHumanReadable(currentSearch)}</em>`) : '';
    }

    function isScrolledIntoView(el) {
        if (!el) return;
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        if (!isWideMode) {
            setMaxHeightTocBody(rect)
        }
        // Only completely visible elements return true:
        const progressBarOffset = 8
        var isVisible = (elemTop >= progressBarOffset)

        return isVisible;
    }

    const handleSubmit = (event, searchPhraseFromNavigationBarRecentSearches) => {
        if (event) {
            event.preventDefault()
        }

        const _searchPhrase = (searchPhraseFromNavigationBarRecentSearches ? searchPhraseFromNavigationBarRecentSearches : searchPhrase)?.trim();
       
        setSearchTimeStamp(Date.now())
        if (UTILITIESHELPER.isStringNullorEmpty(_searchPhrase) || _searchPhrase.length < 3) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }

        if (SearchHelpers.IsSearchPrefixWithWildcards(_searchPhrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'PublicationListingResults' });
            setIsValidSearchPhrase(false);
            return;
        }
        else {
            setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
            setIsValidSearchPhrase(true);
        }

        setUpdateRecentSearches(updateRecentSearches + 1)

        const currentLocation = window.location.pathname;
        if ((currentLocation.indexOf("/collection") > 0) ||
            (currentLocation.indexOf("/manual/") > 0) ||
            (currentLocation.indexOf("/standards/") > 0) ||
            (currentLocation.indexOf("/guidance/") > 0) ||
            (currentLocation.indexOf("/roadmap/") > 0) ||
            (currentLocation.indexOf("/folio/") > 0)) {

            const parsedSearchPhrase = SearchHelpers.parse(_searchPhrase);
            const readableSearchPhrase = SearchHelpers.toHumanReadable(parsedSearchPhrase);

            setSearchPhrase(readableSearchPhrase);

            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: _searchPhrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }

        //alert(getLabel("s_SearchNotAvailableWhereYouAreCurrently", "Search not available where you are currently (for now)"));
        return;
    };

    function handleClose() {
        setSearchResults([]);
        setSearchPhrase('');
        sessionStorage.removeItem('searchPhraseParam');
        navigate(location.pathname);
    }

    // Loading trapdoor
    if (areSearchResultsLoading) return <Loading />;

    const filteredResults = searchResults.filter(result => {
        if (activeFilters.length === 0) return true;
        const matchedFilters = activeFilters.filter(x => result.tocHash.startsWith(x));
        return matchedFilters.length === 0
    })

    function isAllOnOffButtonExist(){
        return document.querySelectorAll('.toc-side-pannel-all-on-off-toggle').length > 0
    }

    return (
        <>
            <ShowIf condition={!areSearchResultsLoading && searchResults?.length > 0}>
                <HorizontalScrollbar shouldBeFixed={isFixed} />
            </ShowIf>
            <ShowIf condition={isFixed}>
                <FixedNavArticle
                    isWideMode={isWideMode}
                    setIsWideMode={setIsWideMode}
                    isJumpLinksOpen={false}
                    title={getSearchTitle()}
                    navTitle={navTitle}
                    pubLandingUrl={pubLandingUrl}
                    shouldBeFixed={true}
                    pubData={pubData}
                    removeInArticleTOC={true}
                />
            </ShowIf>
            <div
                className={cx("article-container cf-right", {
                    "article-container-wide-mode": isWideMode,
                    'invisible': false,
                })}
            >
                <div className={cx("search-results-container", { "search-results-container-wide-mode": isWideMode })}>
                    <form onSubmit={handleSubmit}>
                        <NavigableSearchbar
                            placeholder={`${getLabel("s_Search", "Search")} ${publicationType}`}
                            value={searchPhrase}
                            setValue={setSearchPhrase}
                            terms={searchTerms}
                            style={isFixed ? { top: '1.75rem' } : { top: '.5rem', right: '17px' }}
                            isExpanded={true}
                            isFixed={isFixed}
                            onClose={handleClose}
                            submitFromRecentSearch={handleSubmit}
                            triggerUpdateRecentSearches={updateRecentSearches}
                            calledFrom={page.Article}
                        />
                    </form>
                    <h1  className="publication-listing-titles">{getSearchTitle()}</h1>
                    <ShowIf condition={!filteredResults.length && !isAllOnOffButtonExist()}>
                        <div>{getLabel("s_SearchReturnedNoResults", "Your search returned no results")}</div>
                    </ShowIf>
                    <ShowIf condition={!filteredResults.length && isAllOnOffButtonExist()}>
                        <div className='search-message-ontoggle-alloff'>{getLabel("s_SearchReturnedNoResultsDueToAllOnOff", "Please turn on a toggle in the Table of Contents to view results.")}</div>
                    </ShowIf>
                    <div className="search-results">
                        {filteredResults.map((result, idx) => (<SearchResult key={idx + '_' + result.tocHash} {...result} />))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicationListingResults;
