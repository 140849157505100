import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { useTocContext } from '../../contexts/TocContext';

import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { useArticleContext } from '../../contexts/ArticleContext';
import HTMLContent from '../Common/HTMLContent';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ShowIf from '../Common/ShowIf';

export const StructuredLabels = (props) => {

    const { pubInfo } = useTocContext();

    const getDisplayNameOfIndustry = (pubInfo, _industry) => {
        if (!pubInfo?.industries?.items) return ''
        var foundIndustry = pubInfo.industries.items.find(item => item.id === _industry);
        return get(foundIndustry, 'name', '')
    }

    const getIndustries = (industryMetaData) => {
        var hasIndustries = industryMetaData && industryMetaData.length
        if (!hasIndustries) return [];

        const industriesArr = industryMetaData.map(value => {
            // Don't display 'Common'
            if ( (value === '17584830' || value === "10001")) return ''
            return getDisplayNameOfIndustry(pubInfo, value)
        })

        return industriesArr.filter(i => i);

    }

    const _industryMetaData = get(props, 'item.metadata.industry', []);
    const industries = getIndustries(_industryMetaData, pubInfo);
    const hasIndustries = industries.length;

    const getDisplayNameOfMemberFirm = (pubInfo, _memberFirm) => {
        if (!pubInfo?.memberFirms?.items) return ''
        var foundMemberFirm = pubInfo.memberFirms.items.find(item => item.id === _memberFirm);
        return get(foundMemberFirm, 'name', '')
    }

    const getMemberFirms = (industryMetaData) => {
        var hasMemberFirms = industryMetaData && industryMetaData.length
        if (!hasMemberFirms) return [];

        const memberFirmsArr = industryMetaData.map(value => {
            // Don't display 'DTT'
            if ( (value === '17573177' || value === "10001")) return ''
            return getDisplayNameOfMemberFirm(pubInfo, value)
        })

        return memberFirmsArr.filter(i => i);

    }

    const _memberFirmMetaData = get(props, 'item.metadata.member_firm', []);
    const memberFirms = getMemberFirms(_memberFirmMetaData, pubInfo);
    const hasMemberFirm = memberFirms.length;
    const { searchText } = useArticleContext();

    if (!hasIndustries && !hasMemberFirm) return null;

    return (
        <>
        <div className="applicability-tag">
            {/* Don't display Member Firm tags at this juncture for Pilot */}
            {/* {memberFirms.map((memberFirm, i) => {
                return <span key={i}><HTMLContent>{getTextWithSearchHighlight(memberFirm, searchText)}</HTMLContent></span>
            })} */}
            {industries.map((industry, i) => {
                return <span key={i} className="applicability-tag-span"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(industry)), searchText)}</HTMLContent></span>
            })}
        </div>

            {/* This table/section will only render on .Docx creating, Class 'applicability-tag-table' has a display: none for web at the _article.scss*/}
            <ShowIf condition={industries.length > 0}>
            <table border={'none'} align='left' className="applicability-tag-table applicability-tag-table--no_border">
            <tbody  align='left'  className='applicability-tag-table--no_border'>
                <tr border={'none'} align='left'   className='applicability-tag-table--no_border'>
                {industries.map((industry, i) => {
                    return <td  border={'none'} style={{borderColor:'white', paddingRight:'5px'}} align='left'  className='applicability-tag-table--no_border'  key={i}> 
                            <table  align='left'  className='applicability-tag-table--no_border' >
                             <tbody   className='applicability-tag-table--no_border' >
                                <tr  className='applicability-tag-table--no_border' >
                                <td  style={{ whiteSpace:'nowrap', textAlign:'center', fontSize: '10px', borderRadius:'5px', padding:'1px 2px', marginRight:'5px' }}  className='applicability-tag-table__item' ><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(industry)), searchText)}</HTMLContent></td>
                                </tr>
                             </tbody>
                            </table>
                            </td>
                             
                })}
                 <td border={'none'} style={{width:'100%', borderColor:'white'}} className='applicability-tag-table--no_border'>

                 </td>
                 </tr>
            </tbody>
                </table>
            </ShowIf>
        {/* This table/section will only render on .Docx creating, Class 'applicability-tag-table' has a display: none for web at the _article.scss */}
        </>
    );


}

StructuredLabels.propsTypes = {
    title: PropTypes.string,
    typeName: "StructuredLabels"
}

export default StructuredLabels;
