
import React, { useCallback } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import TocBody from './TocBody';
import SidebarTocTitle from './SidebarTocTitle';
import ShowIf from '../Common/ShowIf';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';

const SidebarTOC = ({
    setIsWideMode,
    children,
    style,
    className,
    isHidden,
    navTitle,
    shouldBeFixed,
    pubLandingUrl,
    yAxisOfToc,
    tocMaxHeight
}) => {

    const toggleOneWideMode = useCallback(() => setIsWideMode(true), [])
    const { setAllButtonsOn, allButtonText, isTriggerAllOnOff, setIsTriggerAllOnOff, isShowAllOnOffButton } = useSearchResultContext();
    const { searchResults } = useSearchResultContext();
    const isSearchActive = searchResults && searchResults?.length > 0;
    const location = useLocation();
	const inArticle = !location.search.includes('searchPhrase');
    const { isReadingMode } = useReadingModeContext();
    
    const handleToggleAllButtons = useCallback(() =>{
        if(allButtonText){
            setAllButtonsOn(false);
            setIsTriggerAllOnOff(!isTriggerAllOnOff);    
        }else{
            setAllButtonsOn(true);
            setIsTriggerAllOnOff(!isTriggerAllOnOff);       
        }
    }, [allButtonText]);

    return (
        <div style={style} className={cx(
            "side-pannel-small",
            className,
            { "sidebar-toc-hidden": isHidden ,
            "side-pannel-small-reading-mode": isReadingMode },
        )}>
            <div style={{ height: isHidden ? 0 : '' }} className={cx({"side-pannel-inner-container": !isHidden})}>
                <div
                    className={cx("side-pannel-heading sidebar-toc-title-and-icon-container", {
                        'opacity-zero': shouldBeFixed,
                    })}
                >
                    <SidebarTocTitle
                        shouldBeFixed={shouldBeFixed}
                        navTitle={navTitle}
                        pubLandingUrl={pubLandingUrl}
                    />
                    <ShowIf condition={!isHidden}>
                        <button className="toc-item-icon-collapse-sidebar-toc"  onClick={toggleOneWideMode}></button>
                    </ShowIf>
                </div>
                <TocBody yAxisOfToc={yAxisOfToc} tocMaxHeight={tocMaxHeight}>
                    <ShowIf condition={isSearchActive && !isHidden && !inArticle && isShowAllOnOffButton}>
                        <div id="toc-all-on-off-toggle" className="flex-container toc-side-panel-all-on-off-div">
                                <button className="toc-side-pannel-all-on-off-toggle" onClick={handleToggleAllButtons}>
                                    { allButtonText ? 'All On' : 'All Off'}
                                </button>
                        </div>
                    </ShowIf>
                    <ShowIf condition={!isHidden}>
                    
                        {children}
                    </ShowIf>
                </TocBody>
            </div>
        </div>
    )
}

SidebarTOC.defaultProps = {
    setIsWideMode: () => { },
    navTitle: '',
}

export default SidebarTOC;
