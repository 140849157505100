import React from 'react';

const Modal = (props) => {
	function closeModal(){
		const modalContainer = document.querySelector('.modal');
		modalContainer.classList.add('hide');
	}
	return (
		<div className="modal hide">
			<div className="modal-header">
				<p>{props.modalHeader}</p>
				<button className="modal-close" onClick={closeModal}></button>
			</div>
			<div className="modal-body">
				<p>{props.modalBody}</p>
			</div>
		</div>
	)
}

export default Modal;