
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { get, replace } from 'lodash';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import RETRIEVALSERVICES from '../../services/rp-service';

import { logs, page } from '../../helpers/log';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';

import TocItemRelatedContent from '../../components/RelatedContent/TocItemRelatedContent';
import TableOfContentsItem from '../Publication/TableOfContentsItem';
import TocAnnotationBadge from '../Publication/TocAnnotationBadge';
import TocSearchBadge from '../Publication/TocSearchBadge';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';


const GuidebookSectionTOC = ({ basePath, sectionFriendlyPath }) => {
    const { context, isFolioSectionlisting } = usePageContext();
    const { getMFLabel } = usePageContextLabels();
    const { selectedItemTOC, setSelectedItemTOC } = useTocContext();
    const { isAnnotationsEnabled , isRestrictedAccessMemberfirm} = usePageContextSettings();
    const { setGuidebookSectionsLoaded, getGuidebookSectionData, setGuidebookSectionData } = useGuidebookTocContext();

    //This updates when section is replaced...
    const currentSectionData = getGuidebookSectionData(sectionFriendlyPath);
    const hasBeenFetched = get(currentSectionData, 'hasBeenFetched', false);
    const sectionTitle = getMFLabel(get(currentSectionData, 'title.Content.title'), "title.Content.title");
    const isSelected = (context.pageSelectedSection === sectionFriendlyPath) && (context.pageSelectedPublicationGUID === "");
    const [isExpanded, setIsExpanded] = useState(context.pageSelectedSection === sectionFriendlyPath);

    const { searchResults } = useSearchResultContext();
    const [isSearchActive,setIsSearchActive] = useState(false);

    useEffect(()=> {
        if(searchResults && searchResults?.length > 0){
            setIsSearchActive(true)
        } else {
            setIsSearchActive(false)
        }
    },[searchResults])

    const relatedContentToc = (sectionDataContext) => {
        var allRelatedContent=[];
         sectionDataContext.forEach(item => {
            if (item.publicationLists && Array.isArray(item.publicationLists[item.publicationType.Key])) {
                allRelatedContent.push(<TocItemRelatedContent key={item.publicationType.Key} {...item} basePath={basePath} expandTocSection={() => setIsExpanded(true)} />)
            }
        });
        return allRelatedContent;
    }

    const renderTocBody = (_sectionData) => {
        if (!_sectionData || !_sectionData.tableOfContents?.length) {
            return null;
        }

        if (sectionFriendlyPath === "related") {
            return relatedContentToc(_sectionData?.context);
        }

        return _sectionData.tableOfContents.filter(tableOfContentsItem=>tableOfContentsItem!==null).map((tableOfContentItem, index) => {
            return (
                <TableOfContentsItem
                    key={`TOC_${tableOfContentItem.id}_${index}`}
                    item={tableOfContentItem}
                />
            );
        })
    }

    const href = useMemo(() => {
        return [replace(basePath, '.cshtml', ''), sectionFriendlyPath].join('/');
    }, [sectionFriendlyPath]);//eslint-disable-line

    const sectionItem = useMemo(() => {
        return {
            basePath: href,
            hrefOverride: href,
            title: sectionTitle,
            sectionTitle,
            sectionFriendlyPath: sectionFriendlyPath,
            item: currentSectionData,
            includeSubItems: true,
            isFolioSection: true,
            isRelatedContentListingPage: (sectionFriendlyPath === 'related')
        }
    }, [sectionFriendlyPath, currentSectionData]);//eslint-disable-line

    
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await RETRIEVALSERVICES.retrieveGuidebookSection(currentSectionData);
                
                setGuidebookSectionData(sectionFriendlyPath, res);
                setGuidebookSectionsLoaded(prev => prev + 1);   //Increment the section-loaded count (to check wormhole)

                //Only when we are ON the same section that we have just retreived within a folio, we need to set the current selectedItemTOC so that the listing page shows.
                //The setSelectedItemTOC is otherwise set in the index of the page right after guidebookTocLoaded is done (useEffect)
                if (isFolioSectionlisting && sectionFriendlyPath === context.pageSelectedSection) {
                    setSelectedItemTOC({ item: res });
                }
            }
            catch (err) {        
                logs.error(page.GuidebookSectionTOC,'getData', ErrorMessages.guidebookSection +' '+ sectionFriendlyPath,err,{eventId:ErrorCodes.GuidebookSectionTOC});
            }
        };

        getData();
    }, []);//eslint-disable-line

    useEffect(() => {
        if (hasBeenFetched && selectedItemTOC && selectedItemTOC.item) {
            //If the selectedItemToc is a section itself, we can ignore
            if (!selectedItemTOC?.item?.sectionFriendlyPath) {
                //If the current selected item is a child of the current section
                if (selectedItemTOC?.item?.tocHash?.indexOf(sectionFriendlyPath) > -1) {
                    if (!isExpanded) {
                        setIsExpanded(true);
                    }
                }
            }
		}
    }, [hasBeenFetched, selectedItemTOC]);//eslint-disable-line


    //While the Section Data is loading, we will present the section's "loading" TOC...
    if (!hasBeenFetched) {
        return (
            <div className="d-flex tocWrapper">
                <div className="tocSpinnerWrapper">
                    <svg className="spinner" width="14px" height="14px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="5" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
                <div className="tocSpinnerText">{sectionTitle}</div>
            </div>
        )
    }
    else {
        
        if (currentSectionData && Array.isArray(currentSectionData.tableOfContents) && currentSectionData.tableOfContents.length > 0) {
            //TODO:BDP: When the pageContext has the functions to check what sections/url-parts have what features
            //  this should be moved to one of them (in the existing enabler ticket!)
            //  Sections, are either badgable/searchable etc so this is part of that!
            const isBadged = !"related".includes(sectionFriendlyPath);

            return (
                <>
                    <div className="d-flex">
                        <button onClick={() => setIsExpanded(prevVal => !prevVal)} className={cx("toc-item-icon cursor-pointer", isExpanded ? 'guidebook-section-toc-open' : 'guidebook-section-toc-closed')}></button>
                        <Link to={href} className="my-auto link-guidebook-section-toc" id={sectionItem.title}>
                            <div
                                onClick={() => { setIsExpanded(prevVal => !prevVal); setSelectedItemTOC({ item: currentSectionData }) ; }}
                                className={cx("toc-item-text-container", { "toc-item-text-container-selected": isSelected })}>
                                {sectionTitle}
                            </div>
                        </Link>
                        <div className="badge-section-wrapper">
                            <ShowIf condition={isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()&&  !isSearchActive}>
                                <TocAnnotationBadge key={`badgeAnno_${sectionItem.id}`} tocItem={sectionItem} />
                            </ShowIf>
                            <ShowIf condition={isBadged}>
                                <TocSearchBadge key={`badge_section_${sectionFriendlyPath}`} tocItem={{ tocHash: sectionFriendlyPath }} />
                            </ShowIf>
                        </div>
                    </div>
                    <ul className={cx('toc-section-children', isExpanded ? 'show' : 'hide')}>
                        {renderTocBody(currentSectionData)}
                    </ul>
                </>
            )
        }
        //else, we have loaded section data and we have no content
        return null;
    }
}

export default GuidebookSectionTOC;
