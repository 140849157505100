import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';
import CONTEXTHELPERS from '../helpers/ContextHelpers'
import UTILITIESHELPER from '../helpers/UtilitiesHelper';
import { logs, page } from '../helpers/log.js';
import { InteractionRequiredAuthError } from "@azure/msal-browser";


//TODO:
//  let context = CONTEXTHELPERS.generateContext(window.location.href);
//  Some functions have the context as a param, we should use that instead of RE-generating it every single time we make a call!

const cache = {};
const UPSEndPoint = window.DART && window.DART.ENV ? window.DART.ENV.REACT_APP_UPS_ENDPOINT_API : null;

function cleanEndpoint(endpoint) {
    return endpoint.replace(/[^\x20-\x7E]/g, ''); //removes hidden characters that were being picked up and causing issues with the url. If no hidden characters this does nothing.
}

function defaultHeaders() {
    let defaultheaders = new Headers();
    defaultheaders.append("Content-Type", "application/json");
    defaultheaders.append("Access-Control-Allow-Origin", window.location.origin);
    defaultheaders.append("api-version", "1");
    return defaultheaders;
}

function defaultPostRequestOptions(urlOverride = "", searchPhrase, searchParams, searchResults, pubtype) {
    if (urlOverride !== "") {
        let contextOverride = CONTEXTHELPERS.generateContext(urlOverride);
        return contextPostRequestOptions(JSON.stringify(contextOverride));
    }
    else {
        const context = CONTEXTHELPERS.generateContext(window.location.href);
        return contextPostRequestOptions(JSON.stringify(context));
    }
}

const UPSUserID = () => {
    const userIdentifier = UTILITIESHELPER.isStringNullorEmpty(localStorage.getItem("globalPersonUid")) ? CONTEXTHELPERS.debugUserId : localStorage.getItem("globalPersonUid");
    return userIdentifier
}

function contextPostRequestOptions(contextAsJSONOverride) {
    var requestOptions = {
        method: 'POST',
        headers: defaultHeaders(),
        mode: 'cors',
        body: contextAsJSONOverride
   
    };
    return requestOptions;
}

function contextPutRequestOptions(contextAsJSONOverride) {
    return {
        method: 'PUT',
        headers: defaultHeaders(),
        mode: 'cors',
        body: contextAsJSONOverride
    };
}

function contextDeleteRequestOptions(contextAsJSONOverride) {
    return {
        method: 'DELETE',
        headers: defaultHeaders(),
        mode: 'cors',
        body: contextAsJSONOverride
    };
}

function contextGetRequestOptions() {
    return {
        method: 'GET',
        headers: defaultHeaders(),
        mode: 'cors',
    };
}


function guidebookSectionPostOptions(currentGuidebookSection) {
    let context = CONTEXTHELPERS.generateContext(window.location.href);
    var guidebookSectionRequest = { guidebookSection: currentGuidebookSection, pageContext: context };
    var contextAsJSON = JSON.stringify(guidebookSectionRequest);

    //To not have decerialization issues on the BE, we Need to change the format of the request to match more of what Sites returned...
    /*  wrap the contects:
            "context": [
                { .. }

        -->
            "context": {
                "$type": "ContentModelData[]",
                "$values": [
                    { ... }
    */
    contextAsJSON = contextAsJSON
        .replace('"context":[{', '"context":{"$type":"ContentModelData[]","$values":[{')
        .replace('}],', '}]},');

    return contextPostRequestOptions(contextAsJSON);
}

async function resolveLink(target, targetElement = "", publicationId = null, overrideMemberFirm = null, overrideKnowledgeDomain = null) {
    if (!target) { return null; }

    let context = CONTEXTHELPERS.generateContext(window.location.href);
    context.pageHashTag = targetElement;
    context.pageRequestURL = target;

    if (!UTILITIESHELPER.isStringNullorEmpty(overrideMemberFirm)) {
        context.memberFirm = overrideMemberFirm;
    }

    if (!UTILITIESHELPER.isStringNullorEmpty(overrideKnowledgeDomain)) {
        context.knowledgeDomain = overrideKnowledgeDomain;
    }

    if (publicationId) {
        context.pageSelectedPublicationGUID = publicationId;
        context.pageSelectedContentItemGUID = ""
        context.pageSearchPhrase = "publication-context";
    }

    return fetchAndHandleErrorsWithStatusCode("/api/link/resolveLink", contextPostRequestOptions(JSON.stringify(context)), true);
}

async function resolveTopic(target, topicId = null) {
    if (!target) { return null; }

    let context = CONTEXTHELPERS.generateContext(window.location.href);
    context.pageHashTag = "";
    context.pageRequestURL = target;

    if (topicId) {
        //BE Looks at this: pageContext.PageRequestURL
        context.pageSelectedPublicationGUID = "";
        context.pageSelectedContentItemGUID = ""
        context.pageRequestURL = topicId
        //context.pageSearchPhrase = "publication-context";
    }

    return fetchAndHandleErrors("/api/link/resolvetopic", contextPostRequestOptions(JSON.stringify(context)), true);
}


//retrieval functions
async function retrieveContentArticle() {
    return fetchAndHandleErrors("/api/article", defaultPostRequestOptions());
}

async function retrieveLanding(context) {
    return fetchAndHandleErrors("/api/landing", contextPostRequestOptions(JSON.stringify(context)), false);
}

async function retrieveCatalog() {
    return fetchAndHandleErrors("/api/catalog", defaultPostRequestOptions(), false);
}

async function retrieveCollection() {
    return fetchAndHandleErrors("/api/collection", defaultPostRequestOptions(), false);
}

async function retrieveManual() {
    return fetchAndHandleErrors("/api/manual", defaultPostRequestOptions(), true);
}

async function retrieveManualPublication() {
    return fetchAndHandleErrorsWithStatusCode("/api/manual/publication", defaultPostRequestOptions(), true)
}

async function retreiveSettings() {
    return fetchAndHandleErrors("/api/settings",contextGetRequestOptions());
}

async function retreiveCollectionSearchLimit() {
    return fetchAndHandleErrors("/api/settings/CollectionSearchLimit");
}

//Note: this retrieves the whole publication and all of its contents.
async function retrieveAllContentsManual(selectedSpinePubGuidList) {
    let context = CONTEXTHELPERS.generateContext(window.location.href);

    //We clear all the GUIDS except for the SPine (which we need to then ge the whole publication content for)
    context.pageSelectedSpineTargetPublicationGUID = context.pageSelectedPublicationGUID;
    context.pageSelectedPublicationGUID = null;
    context.PageSelectedPublicationTitle = "";
    context.pageSelectedContentItemGUID = null;
    context.PageSelectedContentItemTitle = "";
    context.pageSearchPhrase = "";
    context.pageSelectedSpineTargetPublicationGUIDList = selectedSpinePubGuidList;

    var requestOptions = contextPostRequestOptions(JSON.stringify(context))
    return fetchAndHandleErrors("/api/manual/publication", requestOptions, true);
}

//Note: this retrieves the whole publication and all of its contents.
async function retrieveAllContentsGuidebookPublication() {
    let context = CONTEXTHELPERS.generateContext(window.location.href);

    //We clear all the GUIDS except for the SPine (which we need to then ge the whole publication content for)
    context.pageSelectedSpineTargetPublicationGUID = "";
    context.pageSelectedPublicationGUID = context.pageSelectedPublicationGUID;
    context.PageSelectedPublicationTitle = "";
    context.pageSelectedContentItemGUID = "";
    context.PageSelectedContentItemTitle = "";
    context.pageSearchPhrase = "";
    context.pageSelectedSpineTargetPublicationGUIDList = "";

    var requestOptions = contextPostRequestOptions(JSON.stringify(context));
    return fetchAndHandleErrors("/api/guidebook/publication", requestOptions, true);
}

async function retrieveManualPublicationVersions() {
    return fetchAndHandleErrors("/api/manual/publicationversions", defaultPostRequestOptions(), true)
}

async function retrieveJournal() {
    return fetchAndHandleErrors("/api/journal", defaultPostRequestOptions(), true);
}

async function retrieveJournalPublication() {
    return fetchAndHandleErrors("/api/journal/publication", defaultPostRequestOptions(), true);
}

async function retrieveGuidebook() {
    return fetchAndHandleErrors("/api/guidebook", defaultPostRequestOptions(), true);
}

async function retrieveGuidebookSection(currentGuidebookSection) {
    return fetchAndHandleErrors("/api/guidebook/section", guidebookSectionPostOptions(currentGuidebookSection), true);
}

async function retrieveGuidebookPublication(url = "") {
    return fetchAndHandleErrors("/api/guidebook/publication", defaultPostRequestOptions(url), true);
}

async function retrieveEmbedded() {
    return fetchAndHandleErrors("/api/embedded", defaultPostRequestOptions(), true);
}

async function retrieveHeader() {
    return fetchAndHandleErrors("/api/globalcomponents/header", defaultPostRequestOptions(), false);
}

async function retrieveFooter() {
    return fetchAndHandleErrors("/api/globalcomponents/footer", defaultPostRequestOptions(), false);
}

async function retrieveBlob(fileUrl, testToken = "") {
    let context = CONTEXTHELPERS.generateContext(window.location.href);
    var splitUrl = fileUrl.split('/');
    //Need to strip out https://localhost:44330/
    if (splitUrl.length >= 7) {
        for (var i = 0; i < 3; i++) {
            splitUrl.shift();
        }
    }
    fileUrl = splitUrl.join('/');
    if (!fileUrl) { return ""; }

    context.pageRequestURL = fileUrl;

    //CHECK IF WE HAVE TO HAVE THE TOKEN HERE?
    if (testToken) {
        context.userToken = testToken;
    }

    // Fetch the original image
    return fetch('/api/file', contextPostRequestOptions(JSON.stringify(context)))
        // Retrieve its body as ReadableStream
        .then(response => response.body)
        .then(rs => {
            const reader = rs.getReader();
            return new ReadableStream({
                async start(controller) {
                    while (true) {
                        const { done, value } = await reader.read();
                        // When no more data needs to be consumed, break the reading
                        if (done) {
                            break;
                        }
                        // Enqueue the next data chunk into our target stream
                        controller.enqueue(value);
                    }
                    // Close the stream
                    controller.close();
                    reader.releaseLock();
                }
            })
        })
        // Create a new response out of the stream
        .then(rs => new Response(rs))
        // Create an object URL for the response
        .then(response => { return response.blob(); })
        .catch(error => {
            logs.error(page.RPService, 'RPService', ErrorMessages.fetchHandleError, error, { eventId: ErrorCodes.RPService });
        });
}

/*NO LONGER USED/COMMENTED OUT IN CODE
async function retrieveBlobAsUrl(fileUrl, testToken = "") {
    return retrieveBlob(fileUrl, testToken)
        .then(blob => {
            return URL.createObjectURL(blob);
        })
}*/

/*NO LONGER USED/COMMENTED OUT IN CODE
async function retrieveBlobAsBase64(fileUrl, testToken) {
    return retrieveBlob(fileUrl, testToken).then(blob => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.readAsDataURL(blob);

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;
        })
    })
}*/

async function retrieveResource(context, resource) {
    //For resources, we do NOT care about "knowledgeDomain" so we do not "need" it, but it has to be in the URL, to be disected correctly into the context object.
    let url = `/${context.memberFirm}/${context.language}/knowledgeDomain/resource/${resource}`;
    return fetchAndHandleErrors("/api/resource", defaultPostRequestOptions(url));
}

async function retrieveSearchResults(searchPhrase, searchParams) {
    var postRequestOptions = defaultPostRequestOptions("", searchPhrase, searchParams);
    return fetchAndHandleErrors("/api/search", postRequestOptions);
}

async function retrieveSearchResultsCatalog(searchPhrase, searchParams, pubtype) {
    var postRequestOptions = defaultPostRequestOptions("", searchPhrase, searchParams, null, pubtype);
    return fetchAndHandleErrors("/api/search", postRequestOptions);
}

async function createComment(comment) {
    //depreciated
    return null;
}

async function createHighlight(highlight) {
    //depreciated
    return null;
}

async function getBookmarksForUser(userId) {
    //depreciated
    return null;
}

async function updateRecentSearches(preference) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(preference));
    return fetchAndHandleErrors(UPSEndPoint + "/recentsearch/create", requestOptions, false);
}

async function getRecentSearches(preference) {
    const requestOptions = contextPostRequestOptions(JSON.stringify(preference));
    return fetchAndHandleErrors(UPSEndPoint + "/recentsearch/retrieve", requestOptions, false);
}

async function getRecentHistory(preference) {
    const requestOptions = contextPostRequestOptions(JSON.stringify(preference));
    return fetchAndHandleErrors(UPSEndPoint + "/RecentView/retrieve", requestOptions, false);
}

async function getRecentHistoryDashbaord(preference) {
    const requestOptions = contextPostRequestOptions(JSON.stringify(preference));
    return fetchAndHandleErrors(UPSEndPoint + "/RecentView/Dashboard", requestOptions, false);
}

async function updateRecentHistory(preference) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(preference));
    return fetchAndHandleErrors(UPSEndPoint + "/RecentView/create", requestOptions, false);
}

async function getCatagories(preference) {
    const reqturl = UPSEndPoint + "/Category?opm_user_identifier=24601&member_firm_id=17573163&knowledge_domain_id=17573941";
    const requestOptions = contextGetRequestOptions();
    return fetchAndHandleErrors(reqturl, requestOptions, false);
}

async function getTokenFromMsal() {
    const DO_NOT_LOGIN = (window.location.href.indexOf('localhost') > 0);

    if (DO_NOT_LOGIN) {
        return window.DART.ENV.REACT_APP_ACCESSTOKEN;
    }

    const msalInstance = window.msalInstance;
    const accounts = msalInstance?.getAllAccounts();

    if (accounts?.length > 0) {
        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`],
                account: accounts[0]
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                const response = await msalInstance.acquireTokenPopup({
                    scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`]
                });
                return response.accessToken;
            }
        }
    }

    return null
}

async function fetchAndHandleErrors(endpoint, requestOptions) {
    let token = await getTokenFromMsal();
    requestOptions.headers.append("Authorization", `Bearer ${token}`);

    //console.log("RP-Service::fetchAndHandleErrors:endpoint",endpoint,requestOptions);
    return fetch(endpoint, requestOptions)
        .then(async response => {
            //console.log("RP-Service::fetchAndHandleErrors:response", endpoint, response);
            if ((response.status === 204 || response.status > 400) && (!UTILITIESHELPER.isHistoryRequest(requestOptions))) {
                UTILITIESHELPER.setLocalStorage("isContentLoadError", true);
            }
            if (!response.ok) {
                const text = await response.text();
                //const error = (data && data.message) || response.status;
                return Promise.reject(text);
            }
            const data = await response.json();
            //console.log("RP-Service::fetchAndHandleErrors:response.json()", endpoint, JSON.stringify(data));
            return data;
        })
        .then(result => result)
        //.then(result => {
        //    console.log("RP-Service::fetchAndHandleErrors:Result", endpoint, result);
        //    return result;
        //})
        .catch(error => {
            //When we get a NULL response, we just want to continue. The caller needs to handle the "empty/null" response.
            if (error.toString() === "SyntaxError: Unexpected end of JSON input") {
                return null;
            }

            //console.error('RP-Service::fetchAndHandleErrors:Error', endpoint, error);
            logs.error(page.RPService, 'RPService', ErrorMessages.fetchHandleError, endpoint, { eventId: ErrorCodes.RPService });
            return Promise.reject(error);
        });
}

async function fetchAndHandleErrorsWithStatusCode(endpoint, requestOptions) {
    const token = await getTokenFromMsal();
    requestOptions.headers.append("Authorization", `Bearer ${token}`);

    return fetch(endpoint, requestOptions)
        .then(async response => {
            if (response.status !== 200) {
                return {
                    data: null,
                    responseStatus: response.status
                };
            }

            const data = await response.json();
            return {
                data: data,
                responseStatus: response.status
            };

        })
        .then(result => result)
        .catch(error => {
            //When we get a NULL response, we just want to continue. The caller needs to handle the "empty/null" response.
            if (error.toString() === "SyntaxError: Unexpected end of JSON input") {
                return null;
            }

            //console.error('RP-Service::fetchAndHandleErrors:Error', endpoint, error);
            logs.error(page.RPService, 'RPService', ErrorMessages.fetchHandleError, endpoint, { eventId: ErrorCodes.RPService });

            return Promise.reject(error);
        });
}

async function fetchDefaultAndHandleErrors(endpoint, requestOptions) {
    const token = await getTokenFromMsal();
    requestOptions.headers.append("Authorization", `Bearer ${token}`);

    return fetch(endpoint, requestOptions)
        .then(async response => {
            const data = await response.json();
            if (!response.ok) {
                // get error message from body or default to response status
                //console.error('RP-Service::fetchDefaultAndHandleErrors', error);
                return [];
            }
            //Logs too many times here!
            //console.log("RP-Service::fetchDefaultAndHandleErrors:data",data);

            return data;
        })
        .then(result => result)
        .catch(() => {
            //console.warn('RP-Service::fetchDefaultAndHandleErrors', error);
            return [];
        });
}

function checkCache(requestUrl) {
    if (cache[requestUrl]) {
        const data = cache[requestUrl];
        return data;
    }
    else {
        return null;
    }
}

function addToCache(requestUrl, data) {
    cache[requestUrl] = data;
}

async function convertWord(htmlPackage) {
    const token = await getTokenFromMsal();
    var requestOptions = contextPostRequestOptions(JSON.stringify(htmlPackage));
    requestOptions.headers.append("Authorization", `Bearer ${token}`);
    let endpoint = '/api/word/';
    //retrieve file and trigger download on user's side
    //Added a try catch and await ,since Promise  .then is not resolving here 
    try {
        var response = await fetch(cleanEndpoint(endpoint), requestOptions);
        if (response) {
            const blob = await response.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = htmlPackage.FileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        }
    }
    catch {
        logs.error(page.RPService, 'RPService', ErrorMessages.convertWord, endpoint, { eventId: ErrorCodes.RPService });
    }
    finally {
        return response.ok;
    }
}

async function getResponseObject(endpoint, requestOptions, fileName) {
    const token = await getTokenFromMsal();
    requestOptions.headers.append("Authorization", `Bearer ${token}`);
    try {
        var response = await fetch(cleanEndpoint(endpoint), requestOptions);
        if (response) {
            const blob = await response.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
        }
    }
    catch {
        logs.error(page.RPService, 'RPService', ErrorMessages.convertWord, endpoint, { eventId: ErrorCodes.RPService });
    }
    finally {
        return response.ok;
    }
}

//A generic file request function to be used in the downloadPanel or wherever else it needs to be used.
//TODO: Change file.js and elsewhere to use this
async function requestFile(fileUri, fileName) {
    let fileInfo = {
        fileName: fileName,
        fileUri: fileUri
    };
    var requestOptions = contextPostRequestOptions(JSON.stringify(fileInfo));
    return getResponseObject(cleanEndpoint('/secure/download/binaryfile'), requestOptions, fileName);
}

async function requestPdfLoading(fileUri, fileName) {
    const token = await getTokenFromMsal();
    let fileInfo = {
        fileName: fileName,
        fileUri: fileUri
    };
    let requestOptions = contextPostRequestOptions(JSON.stringify(fileInfo));
    requestOptions.headers.append("Authorization", `Bearer ${token}`)
    return await fetch(cleanEndpoint('/secure/download/binaryfile'), requestOptions)
        .then(response => response.blob())
        .then(blob => {
            return blob;
        });
}

async function requestZip(htmlPackage, fileList, fileName) {
    var zipPackage = {
        articleHtml: htmlPackage.HtmlBody,
        fileRequests: fileList,
        articleTitle: fileName,
        content: htmlPackage.content,
        comment: htmlPackage.comment
    };
    var requestOptions = contextPostRequestOptions(JSON.stringify(zipPackage));
    return getResponseObject(cleanEndpoint('/secure/download/zip'), requestOptions, htmlPackage.FileName);
}

async function checkWhitelistForUser(email) {
    var requestOptions = contextPostRequestOptions(`{"email":"${email}"}`);
    return fetchDefaultAndHandleErrors(cleanEndpoint('/api/Whitelist'), requestOptions, false);
}

//Post method to get opm information
async function getOpmInfoByEmail(email) {
    var requestOptions = contextPostRequestOptions(`{"email":"${email}"}`);
    return fetchDefaultAndHandleErrors(cleanEndpoint('/api/OPM/opminfo'), requestOptions, false);
}

//Get method to get OPM information
async function getOpmInfo() {
    return fetchDefaultAndHandleErrors(cleanEndpoint('/api/OPM/opminfo'), contextGetRequestOptions(), false);
}

async function retrieveFrameworkList(context) {
    let url = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/api/resource/taxonomy/framework`;
    return fetchAndHandleErrors("/api/resource/taxonomy/framework", defaultPostRequestOptions(url));
}

async function retrieveIndustryList(context) {
    //let url = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/api/resource/taxonomy/industry`;
    return fetchAndHandleErrors("/api/settings/industry", contextGetRequestOptions());
}

async function createOrUpdateRecentSearch(model) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(model));
    return fetchAndHandleErrors(UPSEndPoint + "/recentsearch/create", requestOptions, false);
}

//Category Start
async function retrieveCategories(request) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/category/retrieve", requestOptions, false);
}

async function createCategory(request) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/category/create", requestOptions, false);
}

async function updateCategory(request, category_guid) {
    var requestOptions = contextPutRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + `/category/update?category_guid=${category_guid}`, requestOptions, false);
}

async function deleteCategory(request) {
    var requestOptions = contextDeleteRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/category/delete", requestOptions, false);
}
//Category End

//Bookmark Start
async function retrieveBookmark(request) {
    const requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/Bookmark/retrieve", requestOptions, false);
}

async function createBookmark(request) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/Bookmark/create", requestOptions, false);
}

async function deleteBookmark(request) {
    var requestOptions = contextDeleteRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/Bookmark/delete", requestOptions, false);
}
//Bookmark End

//AnnotationStart
async function retrieveAnnotation(request) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/annotation/retrieve", requestOptions, false);
}

async function createAnnotation(request) {
    var requestOptions = contextPostRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/annotation/create", requestOptions, false);
}

async function updateAnnotation(request, annotation_guid) {
    var requestOptions = contextPutRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + `/annotation/update?annotation_guid=${annotation_guid}`, requestOptions, false);
}

async function deleteAnnotation(request) {
    var requestOptions = contextDeleteRequestOptions(JSON.stringify(request));
    return fetchAndHandleErrors(UPSEndPoint + "/annotation/delete", requestOptions, false);
}
//Annotation End

const RETRIEVALSERVICES = {
    addToCache,
    checkCache,
    checkWhitelistForUser,
    contextPostRequestOptions,
    convertWord,
    createAnnotation,
    createBookmark,
    createCategory,
    createComment,//depreciated-but still used in the code?!
    createHighlight,//depreciated-but still used in the code?!
    createOrUpdateRecentSearch,
    deleteAnnotation,
    deleteBookmark,
    deleteCategory,
    getBookmarksForUser,//depreciated-but still used in the code?!
    getCatagories,
    getOpmInfoByEmail,
    getOpmInfo,
    getRecentHistory,
    getRecentHistoryDashbaord,
    getRecentSearches,
    requestFile,
    requestPdfLoading,
    requestZip,
    resolveLink,
    resolveTopic,
    retreiveCollectionSearchLimit,
    retreiveSettings,
    retrieveAllContentsGuidebookPublication,
    retrieveAllContentsManual,
    retrieveAnnotation,
    retrieveBookmark,
    retrieveCatalog,
    retrieveCategories,
    retrieveCollection,
    retrieveContentArticle,
    retrieveEmbedded,
    retrieveFooter,
    retrieveFrameworkList,
    retrieveGuidebook,
    retrieveGuidebookPublication,
    retrieveGuidebookSection,
    retrieveHeader,
    retrieveIndustryList,
    retrieveJournal,
    retrieveJournalPublication,
    retrieveLanding,
    retrieveManual,
    retrieveManualPublication,
    retrieveManualPublicationVersions,
    retrieveResource,
    retrieveSearchResults,
    retrieveSearchResultsCatalog,
    updateAnnotation,
    updateCategory,
    updateRecentHistory,
    updateRecentSearches,
    UPSUserID, 
}

export default RETRIEVALSERVICES;
