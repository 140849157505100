
import React from 'react';
import cx from 'classnames'

const IconX = ({ className, onClick, style }) => {

    return (
        <button style={style} className={cx("icon-x", className)} onClick={onClick}></button>
    )
}

IconX.defaultProps = {
    onClick: () => { },
    style: {},
}

export default IconX
