import React, { useEffect, useState } from 'react';
import RecentlyViewedItem from './RecentlyViewedItem';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import ShowIf from '../Common/ShowIf';
import { usePageContext } from '../../contexts/PageContext';
import { useLocation } from 'react-router-dom';

const RecentlyViewed = () => {
    const [list,setList] = useState([]);
    const location = useLocation();
    const { context, getMemberFirmID } = usePageContext();
    const memberFirmId = getMemberFirmID();

    useEffect(() => {
        const fetchHistory = async () => {
            var list = await RecentViewedService.fetchHistoryDashboard(location.pathname, memberFirmId);
            setList(list);
        };
        fetchHistory();
    }, [memberFirmId]);//eslint-disable-line

    return (
        <>
            <div style={{ width: '66%' }}>
                <ShowIf condition={!list || list.length === 0}>
                    <div>
                        <div className="notification">
                            <span className="notification-warning">&nbsp;</span>
                            <p className="notification-label annot-notification-label">No history data available</p>
                        </div>
                    </div>
                </ShowIf>
                <div>
                    {list && list.length > 0 &&
                        list.map((item, p) => {
                            return (
                                <div key={item.title + p}>
                                    <h2>{item.title}</h2>
                                    <hr></hr>
                                    {
                                        item.data.map((element, i) => {
                                            return (
                                                <RecentlyViewedItem key={element.relative_url + i} item={element}></RecentlyViewedItem>
                                            )
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default RecentlyViewed;