import React, { useState, useLayoutEffect,useEffect } from 'react';
import ShowIf from '../Common/ShowIf';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext } from '../../contexts/PageContext';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

const TocAnnotationBadge = ({ tocItem }) => {	
	const [tocAnnotationcount, setTocAnnotationCount] = useState(0);
	const { tocAnnotations } = useTocContext();
	const [isSearchActive, setIsSearchActive] = useState(false);
	const { searchPhrase } = usePageContext();

	useEffect(() => {
	setIsSearchActive(!UTILITIESHELPER.isStringNullorEmpty(searchPhrase) ? true : false);
	}, [searchPhrase])

	useLayoutEffect(() => {
		if (tocAnnotations) {

			const isFolioSection = (tocItem.isFolioSection !== undefined && tocItem.isFolioSection === true);

			//Instead of getting the "tree" dom from TOC href's like seach does, we do it simpler, search should be upadted to be more efficient like this?

			const checkIfAnnotationHasMatchAndAddToCount = (tocItemUrl, referenceIdentifier, tocItemChildren, incrementCount) => {
				//referenceIdentifier is just the GUID, tocItemUrl is the "full" url
				if (tocItemUrl.includes(referenceIdentifier)) {
					return newCount += incrementCount;
				}

				//if we have children, we then count how many matches are within the children (recursivly)...
				if (tocItemChildren !== undefined && tocItemChildren !== null && tocItemChildren.length > 0) {
					(tocItemChildren || []).forEach(tocItemChild => {
						checkIfAnnotationHasMatchAndAddToCount(decodeURI(tocItemChild.url), referenceIdentifier, tocItemChild.subItems, 1);
					})
				}
			}

			let newCount = 0;
			let tocItemUrl = '';
			let children = null;

			//If we are at the folio/section - level.
			if (isFolioSection) {
				tocItemUrl = tocItem.basePath;
				//Section, we do not need children, as we have the base path to match.
				children = tocItem.item.tableOfContents;
			}
			else {
				tocItemUrl = decodeURI(tocItem.url);
				//WE put the kids in here (as they will be looped through if this is a publication/parent in the TOC (and will have a "total" count for all children))
				children = tocItem.subItems;
			}

			try {
				//Try find the current tocItem URL in the list of Annotation results ...			
				(tocAnnotations || []).forEach(item => {
					// To avoid undefined nad null errors 
					// the variable values are fetched individually
					// below conditions are added to correct the tocannotation count with memberFirm & knowledgeDomain 
					var annUrlArr = item.page_url.split('/') ?? []
					if (annUrlArr.length > 0) {
						checkIfAnnotationHasMatchAndAddToCount(tocItemUrl, decodeURI(item.reference_identifier), children, 1);
					}
				})
			} catch (err) {
				setTocAnnotationCount(0);
			}
			setTocAnnotationCount(newCount);
		}
	}, [tocAnnotations]);//eslint-disable-line

	return (
		<>
			<ShowIf condition={tocAnnotations && tocAnnotationcount > 0 && !isSearchActive && tocItem.sectionFriendlyPath !=='related'} >
				<button className='annotation-badge'>
					{tocAnnotations ? tocAnnotationcount : "..."}
				</button>
			</ShowIf>
		</>
	)
}

export default TocAnnotationBadge;