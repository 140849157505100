import React, { useEffect, useState } from 'react';
import './styles/indexApp.scss';
import App from './App';
import SH from './helpers/StorageHelpers'
import RETRIEVALSERVICES from './services/rp-service';
import { initializeProfile } from './helpers/ProfileHelpers';
import { logs, page } from './helpers/log';
import { useMsal } from "@azure/msal-react";
import {
    InteractionRequiredAuthError,InteractionStatus
} from "@azure/msal-browser";
import NoAccess from './noAccess';
import { ErrorCodes, ErrorMessages } from './components/Constants/Errors';
require('dotenv').config();



const IndexApp = ({ DoNotLogin }) => {
    const { instance, accounts, inProgress } = useMsal();
    let accountData = accounts?.length > 0 ? accounts[0] : null;
    const [isValidLogin, setIsValidLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {

        if (!accessToken && inProgress === InteractionStatus.None && !DoNotLogin) {
            const accessTokenRequest = {
                account: accounts[0],
                scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`]
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    // Call your API with token
                    logs.debug(page.Login, 'TokenAquisition','Aquired Access Token');
                    logs.event('Token', 'Aquired Access Token');
                    sessionStorage.setItem("IsValidToken", (accessToken && accessToken.length > 0) ? true : false)
                    setAccessToken(accessToken);

                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                // Acquire token interactive success
                                let accessToken = accessTokenResponse.accessToken;
                                // Call your API with token
                                
                                sessionStorage.setItem("IsValidToken", (accessToken && accessToken.length > 0) ? true : false)

                                logs.debug(page.Login, 'TokenAquisition','Aquired Access Token After Redirection');
                                setAccessToken(accessToken);
                            })
                            .catch(function (error) {
                                logs.error(page.Login, 'TokenAquisition', ErrorMessages.tokenAquisitionError, error, { eventId: ErrorCodes.LoginPage });                                
                                // Acquire token interactive failure
                                console.log(error);
                            });
                    }                   
                    logs.critical('indexApp','TokenAquisition',"Couldn't Aquire Token for logged in user");
                });
        }
    }, [DoNotLogin, instance, accounts, inProgress, accessToken]);


    useEffect(() => {
        const restrictAccess = async () => {
            if (DoNotLogin) {
                initializeProfile("dtt");
                setIsValidLogin(true);
                setIsLoading(false);
                return;
            }

            const isValidLoggedInUser = accountData?.username !== null;

            if (!isValidLoggedInUser || userRoles().length === 0) {
                setIsValidLogin(false);
                setIsLoading(false);
                return;
            }
            if (String(window.DART.ENV.RP_ISRESTRICTENV).toUpperCase() === "TRUE") {
                if (userRoleExists(window.DART.ENV.RP_ISEXCEPTIONLIST)) {
                    const isValidLogin = await isUserProfileEnabled();
                    setIsValidLogin(isValidLogin);
                } else {
                    setIsValidLogin(false);
                }
            } else if (userRoleExists(window.DART.ENV.RP_ISCIAMACCESS)) {
                initializeProfile("dtt");
                setIsValidLogin(true);
            } else {
                const isValidLogin = await isUserProfileEnabled();
                setIsValidLogin(isValidLogin);
            }

            setIsLoading(false);
        };

        if (accessToken || DoNotLogin)
            restrictAccess();
    }, [DoNotLogin, accountData, accessToken]);//eslint-disable-line

    const userRoles = ()=>{
        try {
            const currentAccount = instance.getActiveAccount();

            if (currentAccount) {
              const roles = currentAccount.idTokenClaims?.roles;
              if (roles && roles.length > 0) {
                return roles;
              }
            }
            return [];
          } catch (error) {
            console.error("Error fetching user roles:", error);
            logs.error(page.Index, "FetchingUserRoles", ErrorMessages.userRoles, error, { eventId: ErrorCodes.LoginPage })
            return [];
          }
    }

    const userRoleExists = (roleIdentiFier) => {
        var currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            var roles = currentAccount.idTokenClaims?.roles;
            if (roles && roles.length > 0) {
                return roles.includes(roleIdentiFier);
            }
        }
        return false;
    };

    const isUserProfileEnabled = async () => {
        try {

            const userInfoPromise = await RETRIEVALSERVICES.getOpmInfo();

            if (userInfoPromise) {

                const {globalPersonUid, memberFirmCode, organizationCode, jobTitle, countryDesc, countryCode, levelGlobalDesc} = userInfoPromise;
                // const isAllowedAccess = await shouldAllowUserAccess(useremailid, memberFirmCode);
                const opmProps = { opmId: globalPersonUid, levelGlobalDesc: levelGlobalDesc, countryCode: countryCode, countryDesc: countryDesc, jobTitle: jobTitle, memberFirm: memberFirmCode, pageUrl: window.location.href }
                initializeProfile(memberFirmCode, organizationCode, jobTitle, countryCode);
                SetUserInfoInLocalStorage(userInfoPromise);
                logs.event('opm', opmProps);
                return true;
            }

            return false;
        }
        catch (err) {
            logs.error(page.Login, 'isUserProfileEnabled', ErrorMessages.opmInfo, err, { eventId: ErrorCodes.LoginPage });
        }

        function SetUserInfoInLocalStorage(userInfoPromise) {
            if (userInfoPromise?.firstName?.length > 0) {
                localStorage.setItem('firstName', userInfoPromise.firstName);
            }
            if (userInfoPromise?.lastName?.length > 0) {
                localStorage.setItem('lastName', userInfoPromise.lastName);
            }

            if (userInfoPromise?.globalPersonUid) {
                SH.setLocalStorage("globalPersonUid", userInfoPromise.globalPersonUid);
            }

            if (userInfoPromise?.countryCode) {
                SH.setSessionStorage('MemberFirm', userInfoPromise.countryCode);
            }
        }
    };

    if (isLoading) {

        return (
            <div className='loading-index'>
                <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
        )
    }

    return (
        <>
            {isValidLogin ? (
                <App />
            ) : (
                <NoAccess />
            )}
        </>
    );
};
export default IndexApp;

