import SH from './StorageHelpers';

const STORAGE_KEY = 'tier';

function getLocalStorage() {
    return SH.getLocalStorageJSON(STORAGE_KEY);
}

function setLocalStorage(value) {
    SH.setLocalStorageJSON(STORAGE_KEY, value);
}

function removeLocalStorage() {
    SH.removeLocalStorage(STORAGE_KEY);
}

function getSessionStorage() {
    return SH.getSessionStorageJSON(STORAGE_KEY);
}

function setSessionStorage(value) {
    SH.setSessionStorageJSON(STORAGE_KEY, value);
}

function removeSessionStorage() {
    SH.removeSessionStorage(STORAGE_KEY);
}

function removeLocalStorageWithValue(kd, mf) {
    const tier = JSON.parse(localStorage.getItem(STORAGE_KEY))
    if (tier !== null) {
        const finalTier = { ...tier, [mf]: { ...tier[mf], [kd]: [] } };
        setLocalStorage(finalTier)
        return finalTier
    }
    return { [mf]: { [kd]: [] } }
}

export default {
    getSessionStorage,
    setSessionStorage,
    removeSessionStorage,
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    removeLocalStorageWithValue
}