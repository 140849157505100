import React, { useState, createContext, useRef, useContext } from 'react'

export const ArticleContext = createContext()
export const useArticleContext = () => useContext(ArticleContext)

const ArticleContextProvider = ({ children }) => {
    const [isArticle, setIsArticle] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedFootnotePopup, setSelectedFootnotePopup] = useState();
    const footNotesContentRef = useRef();
    const [expandedSections, setExpandedSections] = useState({});
    const [pdfPage,setPdfPage]=useState(false);
	const [isArticleDeleted, setIsArticleDeleted] = useState(false);
	const [articleVersion, setArticleVersion] = useState(0);
    
    const setOpenMultipleExpandedSections = (sectionIds, val) => {
        setExpandedSections(prevExpandedSections => {
            const newExpandedSections = { ...prevExpandedSections };
            sectionIds.forEach(sectionId => {
                newExpandedSections[sectionId] = (val === 'toggle' ? !newExpandedSections[sectionId] : val);
            })
            
            return newExpandedSections;
        })
    }

    const getIsSectionExpanded = (sectionId) => expandedSections[sectionId];

    const removeExpandedSectionKey = (uuid) => {
        setExpandedSections(prevExpandedSections => {
            const newExpandedSections = { ...prevExpandedSections };
            delete newExpandedSections[uuid];
            return newExpandedSections;
        })
    }

    const store = {
        isArticle,
        setIsArticle,
        searchText,
        pdfPage,
        setPdfPage,
        setSearchText,
        selectedFootnotePopup,
        setSelectedFootnotePopup,
        footNotesContentRef,
        getIsSectionExpanded,
        setOpenMultipleExpandedSections,
        removeExpandedSectionKey, 
        isArticleDeleted, 
        setIsArticleDeleted,
        articleVersion, 
        setArticleVersion
    }

    return (
        <ArticleContext.Provider value={store}>
            {children}
        </ArticleContext.Provider>
    )
}

export default ArticleContextProvider;
