import React, { useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { usePageContextLabels } from '../../contexts/PageContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import cx from 'classnames';
import { useEffect } from 'react';

function ColorPicker({ onUpdate, onSave, onClose, labelTxt, colour, isUpdate, classNames, isSidePanel, category_guid }) {
    const [label, setLabel] = useState(labelTxt);
    const [color, setColor] = useState(colour);
    const [isValidHex, setIsValidHex] = useState(true)
    const { getLabel } = usePageContextLabels();
    const ref = useRef();
    let btnRef=useRef();
    const regToCheckHex = /^#([0-9a-f]{3}){1,2}$/i;

    // close on outside click
    useOutsideClick(ref, handleOutsideClick);

    useEffect(() => {
        regToCheckHex.test(color) ? setIsValidHex(true) : setIsValidHex(false)
    }, [color]);//eslint-disable-line

    function handleOutsideClick() {
        onClose();
    }

    function handleLabelChange(e) {
      setLabel(e.target.value);
    }

    function handleAddClick() {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        onSave({
            label,
            color
        });
    }

    function handleUpdateClick() {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        onUpdate({
            category_guid,
            label,
            color
        });
    }

    function setChangedColor(changedColor){
        if(changedColor === '#NaNNaNNaN'){
            setColor('#FFFFFF')
        } else {
            setColor(changedColor)
        }   
    }

    function handleCloseClick() {
        onClose();
    }

    function onInputChangeHandler(e){
        const value = e.target.value
        setColor(value)
    }

    return (
        <div className={cx("an-color-picker-modal", classNames)} ref={ref}>
            <div className={cx("an-color-picker-header", { "an-color-picker-header-sidepanel": isSidePanel })}>
                <input
                    type='text'
                    value={label}
                    maxLength="30"
                    className={cx('an-color-picker-input',
                        { 'an-color-picker-input-sidepanel': isSidePanel })}
                    placeholder={getLabel('hh_TypeCategoryTitle', 'Type category title...')}
                    onChange={handleLabelChange} />
                <button
                    type="button"
                    className={cx('an-color-picker-closer', { 'an-color-picker-closer-sidepanel': isSidePanel })}
                    onClick={handleCloseClick}
                />
            </div>
            <div className={cx("an-color-picker-content",
                { 'an-color-picker-content-sidepanel': isSidePanel })}
            >
                <HexColorPicker color={color} onChange={setChangedColor} />
            </div>
            <div className={cx("an-color-picker-footer",
                { 'an-color-picker-footer-sidepanel': isSidePanel })}>
                <div className={cx("an-color-picker-label", { 'an-color-picker-label-sidepanel': isSidePanel })}>
                    <div className={cx("an-color-picker-label-name", { "an-color-picker-label-name-sidepanel": isSidePanel })}><strong>Hex Code</strong></div>
                    <input className={cx('an-color-picker-user-input', { 'an-color-picker-user-input-sidepanel': isSidePanel })} type="text" value={color} onChange={onInputChangeHandler}></input>
                </div>
                <button
                    type='button'
                    ref={btnRef}
                    className={(isValidHex && label.length > 0) ? "an-color-picker-add" : "hide" }
                    onClick={!isUpdate ? handleAddClick : handleUpdateClick}
                >{!isUpdate ? getLabel('hh_Add', 'Add') : 'Update'}</button>
            </div>
        </div>
    );
}

export default ColorPicker;
