import React, {useContext} from 'react';
 import { usePageContextLabels } from '../../contexts/PageContext';
 import { useMsal } from '@azure/msal-react';
 import LogoutContext from '../../contexts/LogoutContext';

const SessionExpiredModal = ({
    isModalOpen,
    SetIsModalOpen,
    shouldShowOverlay,
    setShouldShowOverlay,

}) => {
    const { getLabel } = usePageContextLabels();
    const { instance } = useMsal();
    const { setLogoutState } = useContext(LogoutContext);

    if (isModalOpen === true) {
        document.body.style.overflow = 'hidden';
    }
    function closeModal() {
        if (isModalOpen) {
            setShouldShowOverlay(false);
            SetIsModalOpen(false);
            document.body.style.overflow = 'unset';
            //adding logout msal
            var currentAccount = instance.getActiveAccount();
                setLogoutState(currentAccount.idTokenClaims.login_hint);
                localStorage.setItem('logout-event', 'started');
                localStorage.removeItem('logout-event');
                instance.logoutRedirect({ logoutHint: currentAccount.idTokenClaims.login_hint });
        }
    }

    return (
        <div>
            {shouldShowOverlay &&
                <div className='full-screen-darken-overlay-fixed-session' />
            }
            <div className="session-modal">
                <div className="session-modal-header">
                    {getLabel("Session-Timeout", "Session timeout warning")}
                </div>
                <div className="session-modal-body">
                    <p> {getLabel("Session-Timeout-msg", "Your session has expired. Click 'Refresh page' to continue")}  </p>
                </div>
                <div align="center">
                    <button
                        className="btn btn-primary btn-session-no"
                        onClick={closeModal}
                        title={getLabel("Refresh", "Refresh Page")}
                    >
                        {getLabel("Refresh", "Refresh Page")}
                    </button>

                </div>
            </div>
        </div>
    );
}

export default SessionExpiredModal;