import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Trigger from './Trigger';
import { useLocation } from 'react-router';

function Dropdown({
    children,
    ddClassNames,
    triggerText,
    triggerEl,
    triggerClassNames,
    triggerDot,
    containerClassNames,
    onChange
}) {
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();


    // event handlers
    function handleTriggerClick(e) {
        e.preventDefault();
        onChange(!open);
        setOpen(!open);
        setTimeout(() => {
            const nextIndex = '#option-0';
            const nextOptionItem = document.querySelector(nextIndex);
            if (nextOptionItem) {
                nextOptionItem.focus();
            }
        }, 500)
    }

    function onKeyDown(e) {
        if (e.key === 'Enter' || (e.key === 'ArrowDown' && e.target.id === 'category-dropdown')) {
            handleTriggerClick(e);
        }
    }

    function handleOutsideClick() {
        onChange(false);
        setOpen(false);
    }

    // close on outside click
    useOutsideClick(ref, handleOutsideClick);

    // Custom event listener for enabling sub components
    // to request the downdown close upon an interaction.
    useEffect(() => {
        document.addEventListener('dd:requestClose', handleOutsideClick);

        return () => {
            document.removeEventListener('dd:requestClose', handleOutsideClick);
        }
    })

    useEffect(() => {
        handleOutsideClick();
    }, [pathname])//eslint-disable-line


    

    // memoize trigger
    const trigger = useMemo(() => {
        function getTrigger(text, altEl) {
            if (altEl) return altEl;
            return <Trigger value={text} condition={triggerDot}/>;
        }
        return getTrigger(triggerText, triggerEl)
    }, [triggerText, triggerEl]);//eslint-disable-line

    // build trigger classes
    const triggerClasses = cx({
        'dd__trigger': true,
        'dd__trigger--active': open,
    }, [triggerClassNames]);

    return (
        <div className={"dd " + ddClassNames} ref={ref} tabIndex="0" id="category-dropdown" onKeyDown={onKeyDown}>
            <div className={triggerClasses} onClick={handleTriggerClick}>
                {trigger}
            </div>
            {open && <div className={"dd__container " + containerClassNames}>{children}</div>}
        </div>
    );
}


Dropdown.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    ddClassNames: PropTypes.string,
    triggerEl: PropTypes.node,
    triggerText: PropTypes.string,
    triggerClassNames: PropTypes.string,
    triggerDot: PropTypes.bool,
    containerClassNames: PropTypes.string,
    onChange: PropTypes.func
}

Dropdown.defaultProps = {
    ddClassNames: '',
    triggerText: 'Trigger Title',
    triggerEl: null,
    triggerClassNames: '',
    triggerDot:false,
    containerClassNames: '',
    onChange: () => { }
}




export default Dropdown;