import React from 'react';
import { usePageContextLabels } from '../../contexts/PageContext';


const HomeHeader = () => {
	const { getLabel } = usePageContextLabels();

	return (
		<header className="header-homepage">
			<a className="skipnavigation" href="#main">{getLabel("h_SkipNavigation", "Skip Navigation")}</a>
			
		</header>
	)
}

export default HomeHeader;