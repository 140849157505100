import React from 'react';
import StructuredLabels from './StructuredLabels';
import HTMLContent from '../Common/HTMLContent';

import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { useArticleContext } from '../../contexts/ArticleContext';

export const Figure = (props) => {
    const { markup } = props;
    const getDecoratorClass = () => {
        var decoratorType = props.item.content_type;

        switch (decoratorType) {
            case '48693746':
                return 'figure-block variation-beta'
            case '60194857':
            // Pitfall
                return 'figure-block variation-gamma'
            case 'delta':
                return 'figure-block variation-delta'
            default:
                return 'figure-block';
        }
    }

    function createMarkup(html) {
        if (props.item.containsBinary) {
            var htmlContent = "";
            if (props.item.content.format === ".pdf") {
                htmlContent += "<embed src=\"" + props.item.binaryLocation + "\" />";
            }
            return  htmlContent;
        }
        else {
            return  html;
        }
    }

    const { searchText } = useArticleContext();

    return (
        <div className={getDecoratorClass()}>
                <div 
                    className="figure-block-head"
                ><HTMLContent>{getTextWithSearchHighlight(createMarkup(props.item.content.rich_text_title), searchText)}</HTMLContent></div>
            
            <StructuredLabels item={props.item} />
            <div className="figure-block-body"><HTMLContent>{getTextWithSearchHighlight(markup, searchText) || getTextWithSearchHighlight(createMarkup(props.item.content.text), searchText)}</HTMLContent></div>
        </div>
    );
}

Figure.propsTypes = {
    typeName: "Figure"
}

export default Figure;