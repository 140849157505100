import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import LinkHelpers from '../../helpers/LinkHelpers';
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContextLabels } from '../../contexts/PageContext';
import { logs, page } from "../../helpers/log";

import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useLocation } from 'react-router-dom';
import { generateMiniCrumbtrailText } from '../Breadcrumb/Crumbtrail';
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';

const ShareModal = ({
    shouldShowShareModal,
    setShouldShowShareModal,
    shouldShowOverlay,
    setShouldShowOverlay,
    setIsSectionPath,
    isSectionPath,
    setClosedToolbar
}) => {
    const shareModalRef = useRef();
    const [shouldShowSuccessMessage, setShouldShowSuccessMessage] = useState(false);
    const { getLabel } = usePageContextLabels();
    const { toc, selectedItemTOC } = useTocContext();
    const location = useLocation();
    const { guidebookToc } = useGuidebookTocContext();
    var context = CONTEXTHELPERS.generateContext(location.pathname);
    var shareProps = {
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        eventType: 'share',
        pageUrl: window.location.href,
        selectedTocItem: UTILITIESHELPER.getSessionStorage("SelectedContent"),
        jobTitle:UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage('research-portal-member-firm'),
    }


    function closeModal() {
        setShouldShowShareModal(false);
        setShouldShowSuccessMessage(false);
        setShouldShowOverlay(false);
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        return () => {
            closeModal();
        }
    }, []) //eslint-disable-line

    function copyText() {
        function listener(e) {
            let sharedUrl = sessionStorage.getItem('shareUrl');
            let url = UTILITIESHELPER.ReplaceSpecialCharacterInURL(sharedUrl);
            if (url.includes('reading-mode=true')) {
                url = url.replace(/\?reading-mode=true/g, '');
            }
            e.clipboardData.setData('text/html', `<a href="${url}">${url}</a>`);
            e.clipboardData.setData('text/plain', url);
            e.preventDefault();
        }

        try {
            document.addEventListener('copy', listener, { once: true });
            document.execCommand('copy');
            const selection = window.getSelection();
            selection.removeAllRanges();
            return true;
        }
        catch (err) {
            logs.error(page.Article, "ShareModal", ErrorMessages.shareModalCopyText, err,{eventId:ErrorCodes.Article});
            return false;
        }

    }

    if (shouldShowShareModal === true) {
        setClosedToolbar(true);
        document.body.style.overflow = 'hidden';
    }

    let sharedLink = window.location.href;
    let shareUrl;
    let tempShareUrl;
    const selection = document.getSelection();
    const selectionStr = selection.toString();
    const hashExists = window.location.hash;
    if (hashExists) {
        tempShareUrl = sharedLink.split('#')[0];
    } else {
        tempShareUrl = sharedLink;
    }
    if (isSectionPath === true && selectionStr.length > 0) {
        const outerParentId = selection.baseNode.parentNode.offsetParent.id.toString();
        const sectionParentId = selection.baseNode.parentNode.tagName.includes('MARK') ? selection.baseNode.parentNode.parentNode.id : selection.baseNode.parentNode.id.toString()
        if (sectionParentId.length > 0) {
            shareUrl = tempShareUrl + '#' + sectionParentId;
            sessionStorage.setItem('shareUrl', shareUrl);
        } else if (sectionParentId.length <= 0 && outerParentId.length > 0) {
            shareUrl = tempShareUrl + '#' + outerParentId;
            sessionStorage.setItem('shareUrl', shareUrl);
        } else {
            shareUrl = tempShareUrl;
            sessionStorage.setItem('shareUrl', shareUrl);
        }
    } else if (isSectionPath === true && selectionStr.length <= 0) {
        shareUrl = tempShareUrl;
        tempShareUrl = sessionStorage.getItem('shareUrl');
    } else {
        shareUrl = tempShareUrl;
        sessionStorage.setItem('shareUrl', shareUrl);
    }

    if (tempShareUrl.includes('reading-mode=true')) {
        shareUrl = tempShareUrl.replace(/\?reading-mode=true/g, '');
    }
    const shareModalField = UTILITIESHELPER.ReplaceSpecialCharacterInURL(shareUrl);

    

    function displaySuccessMessage() {
        logs.event('share-copy', shareProps)
        if (copyText()) {
            setShouldShowSuccessMessage(true);
        }
    }

    function handleMailClick() {
        logs.event('share-email', shareProps)
        let url = sessionStorage.getItem('shareUrl');
        if (url.includes("reading-mode=true")) {
            url = url.replace(/\?reading-mode=true/g, '');
        }
        url = UTILITIESHELPER.ReplaceSpecialCharacterInURL(url);
        var encodedurl = encodeURIComponent(url);
        var encodedTitle = encodeURIComponent(generateMiniCrumbtrailText(context, toc, guidebookToc, selectedItemTOC));
        var encodedCopyright = encodeURIComponent(getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`));
        var encodedBody = encodeURIComponent(getLabel("share_MessageIntro", "Your colleague wants to share this Research Portal article with you: "));
        
        encodedurl= encodedurl.replace(/%20/g, "_");
        const composedUrl = `mailto:?subject=${encodedTitle}&body=${encodedBody} ${encodedurl} ${encodedCopyright}`;
        // XSS Link Sanitization
        const sanitizedUrl =  LinkHelpers.sanitizeUrl(composedUrl);

        window.location.href = sanitizedUrl;
    }


    useOutsideClick(shareModalRef, () => {
        closeModal();
    }, ['.icon-share', '.toolbar']);

    return (
        <div>
            {shouldShowOverlay &&
                <div className='full-screen-darken-overlay-fixed' />
            }
            <div ref={shareModalRef} className={cx("share-modal", { 'hide': !shouldShowShareModal })}>
                <div className="share-modal-header">
                    <p>{getLabel("share_Share", "Share")}</p>
                    <button className="share-modal-close" onClick={closeModal} title="Close"></button>
                </div>
                <div className="share-modal-body">
                    <div className="share-modal-block">
                        <p id="copy-text-field">{shareModalField}</p>
                    </div>
                    <div className="d-flex items-center flex-gap-1">
                    <button
                        className="btn btn-secondary"
                        onClick={displaySuccessMessage}
                        title={getLabel('a_Copy', 'Copy')}
                    >{getLabel('a_Copy', 'Copy')}</button>
                    <button
                        className="btn btn-secondary"
                        onClick={handleMailClick}
                        title={getLabel('a_Email', 'Email')}
                    >
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3333 0.666687H0.666667C0.266667 0.666687 0 0.933354 0 1.33335V14.6667C0 15.0667 0.266667 15.3334 0.666667 15.3334H19.3333C19.7333 15.3334 20 15.0667 20 14.6667V1.33335C20 0.933354 19.7333 0.666687 19.3333 0.666687ZM18.6667 13.0667L13.4 7.80002L18.6667 2.86669V13.0667ZM17.6667 2.00002L10 9.06669L2.33333 2.00002H17.6667ZM1.33333 2.86669L6.6 7.80002L1.33333 13.0667V2.86669ZM7.6 8.66669L9.53333 10.4667C9.66667 10.6 9.8 10.6667 10 10.6667C10.2 10.6667 10.3333 10.6 10.4667 10.4667L12.4 8.66669L17.7333 14H2.26667L7.6 8.66669Z" />
                        </svg>
                    </button>
                    </div>
                </div>
                <div className={cx("share-modal-footer", { 'hide': !shouldShowSuccessMessage })}>
                    <div className="share-modal-success">
                        <p>{getLabel("s_LinkSuccessfullyCopied", "Link successfully copied")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

ShareModal.defaultProps = {
    setShouldShowShareModal: () => { },
    setShouldShowOverlay: () => { },
}

export default ShareModal;
