import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import ShowIf from '../Common/ShowIf';
import {getIconSrc} from '../../components/Article/File';
import SearchHelpers from '../../helpers/SearchHelpers';

function getHighlightedText(highlights) {
	var result = highlights.find(function (obj) {
		return obj.propertyName === "Content";
	});
	if (result) {
		return result.highlightedText; 
	} else {
		return highlights[0]?.highlightedText;
	}
}
  
function toCrumbString(crumb) {
    if (crumb?.length === 0) return (<span>[placeholder]</span>)
    return crumb?.map((x,i) => (<span key={`${x}_${i}`}>{x}</span>));
}

const SearchResult = React.memo(({ document, highlights, url, crumb, searchPhrase = null }) => {
  const processedHighlights = useMemo(() => ReactHtmlParser(getHighlightedText(highlights)), [highlights]);
  const processedCrumb = useMemo(() => toCrumbString(crumb), [crumb]);

    const isFormatBinary = document.format!=="";
	const documentFormatArray = useMemo(() => isFormatBinary ? document.format.split(',') : [], [document.format]);//eslint-disable-line

	//Adding on the search phrase onto the end of the search results target link will maintain the search toggle "on" 
	//	with the search phrase being highlighted in the target article / topic that is loaded.
	let urlWithSearchPhrase = (searchPhrase && url.indexOf("searchPhrase") === -1) ? `${url}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}` : url;

	return (
		<div className="search-block">
			<h2 className="result-title-icon" style={{ marginLeft: isFormatBinary ? "-6px" : "0px", }}>
				<ShowIf condition={isFormatBinary}>
				{
					documentFormatArray.map((element, index) => {
						return (
							<img className="icon-images" src={getIconSrc(element)} key={index} alt="Binary File" />
						)
					})
				}
				</ShowIf>
				<Link id={document.publicationId} to={urlWithSearchPhrase}>
					{document.fullTitle}
				</Link>
			</h2>
			<p className="crumb">{processedCrumb}</p>
			<p className="snippet">{processedHighlights}</p>
		</div>
	);
});

export default SearchResult;