

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { get } from 'lodash';
import { logs, page } from '../../helpers/log';

import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import TOCHELPERS from '../../helpers/TocHelpers';

import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import GuidebookSectionTOC from './GuidebookSectionTOC';
import GuidebookArticle from './GuidebookArticle';
import Loading from '../Loading/Loading';
import Publication from '../Publication';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import { useLocation } from 'react-router-dom';
import BookmarkService from '../../helpers/BookmarkServices';
import ANNSERVICES from '../../helpers/AnnotationServices';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';

const Guidebook = () => {
    const [hasAllTocDataLoaded, setHasAllTocDataLoaded] = useState(false);
    const [hasLogged, setHasLogged] = useState(false);

    const { context, memberFirm, language, knowledgeDomain, currentTier, currentIndustry, isFolioSectionlisting, getMemberFirmID } = usePageContext();
    const { getGuidebookSectionData, guidebookPublication, setGuidebookPublication, setGuidebookSectionsLoaded,guidebookSections, setGuidebookSections, guidebookToc, setGuidebookToc, guidebookTocLoaded, setGuidebookTocLoaded, setGuidebookArticle } = useGuidebookTocContext();//eslint-disable-line
    const { setPubInfo, toc, setToc, setTocAnnotations, setSelectedItemTOC, setTocIconBookmark} = useTocContext();//eslint-disable-line
    const { setSearchResults, setFilteredSearchResults, setActiveFilters,setTocControl } = useSearchResultContext();//eslint-disable-line
    const { isAnnotationsEnabled, isRecentlyViewedEnabled, isBookmarksEnabled ,isRestrictedAccessMemberfirm} = usePageContextSettings();

    const {guidebookFileName} = useParams();
    const path = '/:memberFirm/:language/:knowledgeDomain/guide/:guidebookFileName';
    const url = `/${memberFirm}/${language}/${knowledgeDomain}/guide/${guidebookFileName}`;

    const { state: annState, dispatch } = useUserPreferencesContext();
    const location = useLocation();

    //path == /:memberFirm/:language/:knowledgeDomain/folio/:guidebookFileName 
    //url  == /global/en-US/auditing/folio/Group-Audit_66019141-66029838


    //Handling of dynamic Folios, we need to pull the title from the url rather than the toc in this case.
    function getFolioTitle(folioTitle) {
      
        if (!UTILITIESHELPER.isStringNullorEmpty(context?.pageFileName) && context?.pageFileName !== "Test" && context?.pageFileName !== "test") {
            folioTitle = context.pageFileName
                .replace(".cshtml", "")
                .split('_')[0]
                .replaceAll("_", " ")
                .replaceAll("---", "~") //temporary change so that the --- can be ignored by the - removal
                .replaceAll("-", " ")
                .replaceAll("~", " - ");

            //Dynamic folio title comes from teh URL now (we maintain it from the catalog page)
            //folioTitle = UTILITIESHELPER.titleCase(folioTitle);
        }

        return UTILITIESHELPER.isStringNullorEmpty(folioTitle) ? "" : decodeURI(folioTitle);
    }


    const getGuidebookTocBody = (_guidebookSections) => {
        return (
            _guidebookSections.map(currentSection => {
                const sectionFriendlyPath = get(currentSection, 'sectionFriendlyPath');

                return (
                    <GuidebookSectionTOC
                        key={`gbs_${sectionFriendlyPath}`}
                        basePath={url}
                        sectionFriendlyPath={sectionFriendlyPath}
                    />
                )
            })
        )
    }


    useEffect(() => {
        const retreiveAnnotationList = async (_url) => {
            try {
                setTocAnnotations([]);
                const annotations = await ANNSERVICES.fetchAnnotations(location.pathname, getMemberFirmID());
                if (annotations) {
                    setTocAnnotations(annotations);
                }
            }
            catch (err) {
                logs.error(page.GuidebookSectionTOC,'Guidebook', ErrorMessages.annotationTOC,err,{eventId:ErrorCodes.GuidebookSectionTOC});
            }
        }
        const retreiveBookmarkList = async (_url) => {
            try {
                setTocIconBookmark([]);
                const bookmarks = await BookmarkService.fetchBookmarks(location.pathname, getMemberFirmID());
                if (bookmarks) {
                    setTocIconBookmark(bookmarks);
                }
            }
            catch (err) {
                logs.error(page.GuidebookSectionTOC,'Guidebook', ErrorMessages.bookmarkTOC,err,{eventId:ErrorCodes.GuidebookSectionTOC});
            }
        }

        const retrieveGuidebook = async (_url) => {
            setHasLogged(false);
            setHasAllTocDataLoaded(false);
            setGuidebookPublication({});
            setPubInfo({});
            

            try {
                if (Array.isArray(guidebookSections) && guidebookSections.length > 0) {
                    setGuidebookSections([]);
                    setGuidebookToc({});
                    setGuidebookTocLoaded(false);
                    setToc(null);

                    if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
                        setTocAnnotations([]);
                    }
                    if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
                        setTocIconBookmark([]);
                    }
                }
                //getGuidebookSectionData(...MORE...);
                //var startTime = new Date().getTime();
                const res = await RETRIEVALSERVICES.retrieveGuidebook(_url);
                //var endTime = new Date().getTime();

                setGuidebookPublication(res);
                setPubInfo(res);
            }
            catch (err) {
                logs.error(page.GuidebookSectionTOC,'Guidebook', ErrorMessages.guidebookPublication,err,{eventId:ErrorCodes.GuidebookSectionTOC});
            }
        }

        const retriveRecentlyViewed = async () => {
            if( memberFirm && knowledgeDomain){
                if(UTILITIESHELPER.isNullOrUndefined(annState.recentHistory) || annState?.recentHistory?.length === 0) {
                    // fetch latest records from DB when list is empty
                    const freshHistoryPayload = await RecentViewedService.fetchHistory(location.pathname, getMemberFirmID());
                    // state create reducer from history from DB
                    dispatch({
                        type: ACTIONS.RECENT_HISTORY_CREATE,
                        payload: freshHistoryPayload
                    });
                }
            }
        }

        (async () => {
            await retrieveGuidebook(url);
             if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
                await retreiveAnnotationList(url);
             }
            if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
                await retreiveBookmarkList(url);
            }
            if (isRecentlyViewedEnabled() && !isRestrictedAccessMemberfirm()) {
                await retriveRecentlyViewed();
            }
        })()

        return () => {
            //Unload Search
            sessionStorage.removeItem('searchPhraseParam');
            setSearchResults([]);
            setFilteredSearchResults([]);
            setActiveFilters([]);
           // setTocControl([]);
            //Unload Guidebook Folio
            setGuidebookSections([]);
            setGuidebookToc({});
            setGuidebookTocLoaded(false);
            setToc(null);
            setGuidebookSectionsLoaded(0);
            setGuidebookArticle({});


            if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
                setTocAnnotations([]);
            }
            if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
                setTocIconBookmark([]);
            }
        }
        //eslint-disable-next-line
    }, [memberFirm, language, knowledgeDomain, currentIndustry, currentTier])

    useEffect(() => {
        if (Array.isArray(guidebookSections) && guidebookSections.length > 0) {
            const currentGuidebookTocBody = getGuidebookTocBody(guidebookSections)
            setGuidebookToc(currentGuidebookTocBody);
        }

        return() => {
            setGuidebookToc();
        }
        //eslint-disable-next-line
    }, [guidebookSections])


    useEffect(() => {
        if (guidebookTocLoaded) {
            setToc(guidebookSections);
            //We only want to check if we have loaded the toc item that the user is currently "on" if we are not on the listing page.
            //When we are on a section within a folio, the setSelectedItemTOC is set right after the section is loaded in GuidebookSectionTOC
            if (!isFolioSectionlisting) {
                let selected = null;
                guidebookSections.forEach(currentSection => {
                    if (Array.isArray(currentSection.tableOfContents) && currentSection.tableOfContents.length > 0) {
                        currentSection.tableOfContents.forEach(currentToc => {
                            const item = TOCHELPERS.getSelectedTocNode(currentToc);
                            if (item) {
                                selected = item;
                                return;
                            }
                        })
                    }
                });

                if (selected) {
                    setSelectedItemTOC({ item: selected });
                }
            }

            setHasAllTocDataLoaded(true);

            //We only log if we have not already, and when we land ONLY on the Folio itself... NOT in a section or have an article selected
            //SO - if we land on an article/content, or if we refresh out page, we will NOT be re-logging (as the logging of that article is logged on the article itself!)
            if (!hasLogged && context.pageSelectedSection === "") {
                setHasLogged(true);

                const folioTitle = getFolioTitle(get(guidebookPublication, 'title.title', ''));

                logs.trackPageView({
                    name: 'Folio - ' + folioTitle,
                    properties: {
                        memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                        serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                        level: UTILITIESHELPER.getSessionStorage('Level')
                    }
                })
            }
        }
        //eslint-disable-next-line
    }, [guidebookTocLoaded]);

    const pageProps = {
        basePath: path,
        publicationType: 'folio',
        //INHERIT:
        tocBody: guidebookToc,
        //INHERIT:
        pubData: guidebookPublication,
        url,
        pubLandingUrl: url, //WHY Do we need both of these?!!
        isExact: (context.pageSelectedSection === ""),
        navTitle: getFolioTitle(get(guidebookPublication, 'title.title', '')),
        pubLandingTitle: getFolioTitle(get(guidebookPublication, 'title.title', '')),
        preface: "",
        hasAllTocDataLoaded,
        renderArticle: (props) => {
            return <GuidebookArticle {...props} />
        },
    };
   
    if (!Array.isArray(guidebookToc) || guidebookToc.length === 0) {
        return <Loading />;
    }

    return (
        <Publication {...pageProps} />
    )

};

export default Guidebook;
