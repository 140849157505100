import {usePageContext, usePageContextLabels} from "../../contexts/PageContext";
import React, {useState} from "react";
import ShowIf from "../Common/ShowIf";
import OptionList from "../Common/Layout/DropdownMemberFirm/OptionList";
import Option from "../Common/Layout/DropdownMemberFirm/Option";
import ProfileHelpers from "../../helpers/ProfileHelpers";
import {useNavigate, useLocation} from "react-router-dom";
import CONTEXTHELPERS from "../../helpers/ContextHelpers";
import RedirectModal from "../Article/RedirectModal";

export function MemberFirmItem({memberFirm, active = false, onClick, onClose, collapsible = false, activeDelete = true}) {
    const {getLabel} = usePageContextLabels();
    const [showKnowledgeDomains, setShowKnowledgeDomains] = useState(false);
    const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [messageRedirectTL, setMessageRedirectTL] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const {context} = usePageContext();

    function handleClick() {
        onClick(memberFirm.reference.toLowerCase());
    }

    function handleCloseClick() {
        onClose(memberFirm.reference.toLowerCase());
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onClick(memberFirm.reference.toLowerCase());
        }
    }

    const options = () => {
        const handleOptionClick = (kd) => {
            if (kd.url.startsWith('http')) {
                setMessageRedirectTL(getLabel("l_LinkModalViewLinkDART", "The Accounting and Reporting Catalog currently resides in the Deloitte Accounting Research Tool. Would you like to continue there?"))
                setRedirectURL(kd.url);
                setShouldShowOverlayRedirect(true);
                setIsRedirectModalVisible(true);
                return;
            }
            ProfileHelpers.setLastMemberFirm(memberFirm.reference.toLowerCase());
            const targetPath = CONTEXTHELPERS.resolveMemberFirmPath(location.pathname, memberFirm.reference.toLowerCase(), kd.url, context);
            navigate(targetPath);
        }

        if (memberFirm.memberFirmSettings && memberFirm.memberFirmSettings.knowledgeDomain) {
            return memberFirm.memberFirmSettings.knowledgeDomain.map(kd => {
                const fallback = kd.name[0].toUpperCase() + kd.name.slice(1);

                return (
                    <Option
                        key={kd.name}
                        label={getLabel(kd.label, fallback, true, memberFirm.reference.toLowerCase())}
                        onClick={() => handleOptionClick(kd)}
                    ></Option>
                )
            })
        } else {
            return null
        }
    }

    const handleShowKnowledgeDomains = () => setShowKnowledgeDomains(!showKnowledgeDomains)

    return (
        collapsible ? (
            <div className="mfdd__card" style={{width: '100%'}}>
                <div className="mfdd__card-header">
                    <div onClick={handleShowKnowledgeDomains}>
                        <ShowIf
                            condition={memberFirm.memberFirmSettings && memberFirm.memberFirmSettings.knowledgeDomain && memberFirm.memberFirmSettings.knowledgeDomain.length}>
                            {
                                !showKnowledgeDomains ? <i className='fa fa-caret-right'></i> :
                                    <i className='fa fa-caret-down'></i>
                            }
                        </ShowIf>
                        <span className="mfdd__card-title">
                            {getLabel(`mf_Library_${memberFirm.reference.toLowerCase()}`, memberFirm.label, true)}
                        </span>
                    </div>
                    <ShowIf condition={activeDelete}>
                        <button type="button" className="mfdd__card-closer" onClick={handleCloseClick}></button>
                    </ShowIf>
                </div>

                <ShowIf condition={showKnowledgeDomains}>
                    <OptionList>
                        {options()}
                    </OptionList>
                </ShowIf>

                <RedirectModal
                    setShouldShowRedirectModal={setIsRedirectModalVisible}
                    shouldShowRedirectModal={isRedirectModalVisible}
                    redirectURL={redirectURL}
                    setShouldShowOverlay={setShouldShowOverlayRedirect}
                    shouldShowOverlay={shouldShowOverlayRedirect}
                    messageRedirectTL={messageRedirectTL}
                />
            </div>
        ) : (
            <div className={"home-mf__item-container " + (active ? 'active' : '')} tabIndex="0"
                 onKeyPress={handleKeyPress}>
                <div className={"home-mf__item"} onClick={handleClick}>
                    {getLabel(`mf_Library_${memberFirm.reference.toLowerCase()}`, memberFirm.name)}
                </div>
                <button type="button" className={"home-mfdd__card-closer " + (active ? 'active' : '')}
                        onClick={handleCloseClick}></button>
            </div>
        )
    )
}
