import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import ShowIf from '../Common/ShowIf';
import {getIconSrc} from '../../components/Article/File';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import SearchTemplateModal from './SearchTemplateModal';


const CollectionSearchResult = (props) => {

	const [showModal, setShowModal] = useState(false);
	const { document, highlights, url } = props;
	const isFormatBinary = document.format !== "";
	const { getLabel } = usePageContextLabels();
	const { pageControllerType, knowledgeDomain, setIsNavigatedFromCollectionSearch } = usePageContext();
	const { getMFLabel } = usePageContextLabels();
	let documentformatarray = document.format.split(',');
	var urlArray = url.split('/');
	let searchParam = new URLSearchParams(useLocation().search);

	function getDocumentTitle() {
		// fallback to publicationTitle last, then document.publicationNavTitle but document.navTitle is priority
		let documenttitle = UTILITIESHELPER.isStringNullorEmpty(document.navTitle) ?
			(UTILITIESHELPER.isStringNullorEmpty(document.publicationNavTitle) ? ReactHtmlParser(document.publicationTitle) : ReactHtmlParser(document.publicationNavTitle))
			: ReactHtmlParser(document.navTitle);

		return documenttitle;
	}

	function getHighlightedText() {
		var result = highlights.find(function (obj) {
			return obj.propertyName === "Content";
		});
		if (result) {
			return result.highlightedText;
		} else {
			return highlights[0]?.highlightedText;
		}
	}


	function GenerateCrumbFromURL() {
		if (pageControllerType === 'folio') {
			return GenerateCrumbForFolios();
		}

		let processedCrumb = [];
		var MfKd = getLabel(`mf_${knowledgeDomain}Catalog`, "Catalog...");
		processedCrumb.push(MfKd);
		urlArray.forEach((urlItem, index) => {
			if (index > 4 && !urlItem?.toLowerCase()?.includes('guid')) {
				processedCrumb.push(urlItem);
			}
		});
		return processedCrumb;
	}

	function GenerateCrumbForFolios() {
		const generateLabel = (text, isDynamicFolio) => {
			if (text === undefined) {
				return "";
			}

			if (text.startsWith("lbl_")) {
				return getMFLabel(text, "CrumbTrail::generateLabel:text");
			}

			if (isDynamicFolio) {
				text = CONTEXTHELPERS.generateFolioTitleFromFilename(text, false);
			}
			else {
				text = text
					.replace(".cshtml", "")
					.replaceAll("_", " ")
					.replace(" - - ", " - ");
			}

			return text;
		};

		var crumnArray = [];
		var MfKd = getLabel(`mf_${knowledgeDomain}Catalog`, "Catalog...");
		crumnArray.push(MfKd);

		urlArray.forEach((urlItem, index) => {
			if (index > 4 && !urlItem?.toLowerCase()?.includes('searchphrase') && !urlItem?.toLowerCase()?.includes('guid-')) {

				if (index === 5) {
					var a = generateLabel(urlItem, true);
					crumnArray.push(a);
				}
				else if (index !== 6) {
					crumnArray.push(urlItem);
				}
			}
		});

		return crumnArray;
	}

	function setNavigateFromCollectionSearch() {
		setIsNavigatedFromCollectionSearch(true);
	}

	const closeModal = () => {
		setShowModal(false);
	}
	function GenerateSearchResultIteam()
	{
		var crumtrailDecoded=[];
		GenerateCrumbFromURL().forEach((item, index) => {
			if (!UTILITIESHELPER.isObjectNullorEmpty(item)) {
				const decoded = decodeURIComponent(item);
				crumtrailDecoded.push (
					<p key={`para-crumb-${index}`} className="crumb">
						{ReactHtmlParser(decoded)}
					</p>
				);
			}
		})
		return crumtrailDecoded;
	}
	const resultTitle= { marginLeft: isFormatBinary ? "-6px" : "0px" }

	return (
		<div className="search-block">
			<h2
				className="result-title-icon"
				onClick={setNavigateFromCollectionSearch}
				style={resultTitle}
			>
				<ShowIf condition={isFormatBinary}>
					{documentformatarray.map((element, index) => {
						return (
							<img
								key={`binary-icon-image${index}`}
								className="icon-images"
								src={getIconSrc(element)}
								alt="Binary File"
							/>
						);
					})}
				</ShowIf>
				{showModal && (
					<SearchTemplateModal closeModal={closeModal} pageUrl={url} isScrollDisabled={true} searchParam={searchParam.toString()}/>
				)}
				{pageControllerType === "folio" &&
					document.contentType === "17323604" &&
					["66029858", "58035381", "68489711", "68169717"].includes(document.publicationType) ? (
						 // eslint-disable-next-line
					<a id={document.publicationId} onClick={() => { setShowModal(true); }}>
						{getDocumentTitle()}
					</a>
				) : (
					<Link id={document.publicationId} to={url}>
						{getDocumentTitle()}{" "}
					</Link>
				)}
			</h2>
			<p className="snippet">{ReactHtmlParser(getHighlightedText())}</p>
			{document.contentType === "17323604" && ["66029858", "58035381", "68489711", "68169717"].includes(document.publicationType) ? null : (
				<div className="crumb-list">
					{GenerateSearchResultIteam()}
				</div>
			)}
		</div>
	);
}

export default CollectionSearchResult;