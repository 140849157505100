export const MEMBERFIRM_CONSTANTS = {
    global: "global",
    dtt: "dtt",
    us:"us"
}

export const KNOWLEDGE_DOMAIN = {
    accounting: "accounting",
    assurance:"assurance",
    auditing: "auditing"
}

export const KNOWLEDGE_DOMAIN_ID = {
    accountingId: "17573178",
    assuranceId: "68489712",
    auditingId: "17573941"
}

//querySpec.AuditWorkflowAreaAccount
export const FOLIO_SUBJECT_ACCOUNT = {
    accountBalance: "17653684",
    accountingFolios: "66029487",
    auditingAccountingFolios: "66019138"
}

export const PUBLICATIONTYPE_IDS = {
    manual : "60194866",
    standards : "60194865",
    deloitteCommunications:"60194864",
    professionalNews : "65511719"

}

//querySpec.AuditWorkflowAreaNonAccount
export const FOLIO_SUBJECT_NON_ACCOUNT = {
    assuranceFolios: "69200678",
    auditingNonAccountFolios: "66019141",
    nonAccountBalance: "48082103"
}

export const PAGE_CONTROLLER_TYPE = {
    deloitteCommunications: "deloitte-communications",
    folio: "folio",
    journals: "journals",
    manual: "manual",
    professionalNews: "professional-news",
    roadmap: "roadmap",
    standards: "standards",
    guidance:"guidance"

}

export const FOLIO_SECTION_TYPE = {
    alerts: "alerts",
    guidance: "guidance",
    qna: "qna",
    templates: "templates",
    other: "other",
    related: "related",
    //Potentially depretiated, but these are the ones that we had in folios...
    disclosures: "disclosures",
    modelfs: "modelfs",
    dww: "dww",
    roadmap: "roadmap",
    gra: "gra",
    investment: "investment",
    objectives: "objectives",
    embedded: "embedded",
    examples: "examples",
    questionsandanswers: "questions-and-answers",
    scenarios: "scenarios",
    omniatemplates: "omnia-templates"
}

export const PUBTYPES_CATALOG_PAGE = ["folio", "manual", "standards", "deloitte-communications", "professional-news"]

export const TEMPLATE_REPOSITORY_PUBLICATION_TYPES = {
    template_repository_publicaton_type: "68169717",
    template_repository_omnia_publicaton_type: "58035381",
    template_repository_account_publicaton_type : "68489711"
}

export const REGEX_PATTERN = {
    doubleQuoted: /^"([^"]*)"$/,
    wildcards: /^[\*,\/,\?].*/, //eslint-disable-line
    homePageUrl: /^\/{1}[a-z]{1,}\/{1}[a-z \- A-Z]+$/,
    DoubleForwardSlash: /(\/{2,3})/g
}

export const ARTICLE_DOWNLOAD = {
    HEADER: "Download Alert",
    BODY: "Recommended download size has exceeded. Please select fewer items to download.",
    SIZE_LIMIT : 40428800
}

export const APPLICATION_INSIGHTS_CONSTANTS = {
    annotation_name: "ANNOTATION",
    annotation_eventType: "annotation",
    bookmark_name: "BOOKMARK",
    bookmark_eventType: "bookmark"
}

export const SESSION_STORAGE_CONSTANTS = {
    selected_content: "SelectedContent",
    page_type: "PageType",
    level: "Level"
}

export const LOCAL_STORAGE_CONSTANTS = {
    research_portal_memberfirm: "research-portal-member-firm"
}