import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import ANNSERVICES from "../../helpers/AnnotationServices";
import ProgressBar from "@ramonak/react-progress-bar";
import { useTocContext } from '../../contexts/TocContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserPreferencesContext } from "../../contexts/AnnotationsContext";
import BookmarkService from '../../helpers/BookmarkServices';


const AnnotationsDeletedArticlesModal = ({
    bookmarkPage
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const DeleteModalRef = useRef();
    const { getLabel } = usePageContextLabels();
    const [deleteStarted, setDeleteStarted] = useState(false);
    const [deleteProgress, setDeleteProgress] = useState(0);
    const [deleteProgressChange, setDeleteProgressChange] = useState(0);
    const [deletedCount, setDeletedCount] = useState(0);
    const [deletedAnnotations, setDeletedAnnotations] = useState([]);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(true);
    const [shouldShowOverlay, setShouldShowOverlay] = useState(true);

    const [deletedAnnotationsCount, setDeletedAnnotationsCount] = useState(0);
    const deleteInProgress = 'Delete in Progress!';
    const deleteCompleted = 'Deleted Successfully!';
    const { tocAnnotations, tocIconBookmark } = useTocContext();
    const { memberFirm, language, knowledgeDomain, getMemberFirmID } = usePageContext();
    const { viewedAnnotation, setViewedAnnotation, setViewClickedAnnotation, viewedBookmark, setViewedBookmark } = useUserPreferencesContext();
    const viewCount = bookmarkPage ? getLabel("hh_DeletedButtonCountBook", "Delete {0} Bookmark(s).") :
        getLabel("hh_DeletedButtonCount", "Delete {0} Annotations.");
    const deleteCount = bookmarkPage ? getLabel("hh_ViewButtonCountBook", "View {0} Bookmark(s). ") :
        getLabel("hh_ViewButtonCountBook", "View {0} Annotation(s).");
    useEffect(() => {
        if (bookmarkPage) {
            let deletedAnnot = tocIconBookmark.filter(ele => ele.reference_identifier === viewedBookmark);
            debugger;
           if (deletedAnnot.length !== deletedAnnotationsCount) {
            setDeletedAnnotations(prev => [...deletedAnnot]);
            setDeletedAnnotationsCount(deletedAnnot.length);
        }
        }
        else {
            let deletedAnnot = tocAnnotations.filter(ele => ele.reference_identifier === viewedAnnotation);
            if (deletedAnnot.length !== deletedAnnotationsCount) {
            setDeletedAnnotations(prev => [...deletedAnnot]);
            setDeletedAnnotationsCount(deletedAnnot.length);
            }
        }
    }, [tocAnnotations, tocIconBookmark,viewedBookmark, viewedAnnotation, bookmarkPage, deletedAnnotationsCount]);//eslint-disable-line

    function navigateToDashboard() {
        if (bookmarkPage) {
            setViewClickedAnnotation(viewedBookmark);
            navigate(`/${memberFirm}/${language}/${knowledgeDomain}/dashboard/bookmarks`);
        }
        else {
            setViewClickedAnnotation(viewedAnnotation);
            navigate(`/${memberFirm}/${language}/${knowledgeDomain}/dashboard/annotations`);
        }
    }
    const setDialogClosed = () => {
        setViewedAnnotation(null);
        setViewedBookmark(null);
        closeModal();
    }

    function closeModal() {
        setShouldShowOverlay(false);
        setShouldShowDeleteModal(false);
        document.body.style.overflow = 'unset';
        if (deleteProgress === 100) {
            setDeleteProgress(0);
            setDeleteStarted(false);
        }
    }

    useEffect(() => {
        return () => {
            setShouldShowOverlay(false);
            setShouldShowDeleteModal(false);
            closeModal();
        }
    }, [])//eslint-disable-line

    function deleteAnnotation() {
        setDeleteProgress(0)
        setDeleteStarted(true);
        var counter = 0;
        deletedAnnotations.forEach(singledelete => {
            counter++;
            deleteSingleAnnotation(singledelete.annotation_guid, counter);
        });
    }

    async function deleteSingleAnnotation(annId, counter) {
        if (bookmarkPage) {
            const bookDelete = await BookmarkService.deleteBookmarks(location.pathname, [annId]);
            if (bookDelete) {
                let deletedAnnot = deletedAnnotations.filter(ele => ele.bookmark_guid !== annId)
                setDeletedAnnotations(prev => [...deletedAnnot]);
                setDeletedCount(counter);
            }
            return bookDelete;
        }
        else {
            const annDelete = await ANNSERVICES.deleteAnnotation(location.pathname, [annId], getMemberFirmID());
            if (annDelete) {
                let deletedAnnot = deletedAnnotations.filter(ele => ele.annotation_guid !== annId)
                setDeletedAnnotations(prev => [...deletedAnnot]);
                setDeletedCount(counter);
            }
            return annDelete;
        }
    }


    if (shouldShowDeleteModal === true) {
        document.body.style.overflow = 'hidden';
    }

    useEffect(() => {
        if (deletedAnnotationsCount > 0 && deleteProgress <= 100) {
            incrementProgress(((deletedCount) / deletedAnnotationsCount) * 100)
        }
    }, [deletedCount]);//eslint-disable-line

    function incrementProgress(progress) {
        setDeleteProgressChange(progress);
    }

    useEffect(() => {
        if (deleteProgress < 100) {
            setDeleteProgress(Math.round(deleteProgressChange));
        }
    }, [deleteProgressChange]);//eslint-disable-line



    return (
        <div>
            {shouldShowOverlay &&
                <div className='full-screen-darken-overlay-fixed' />
            }

            <div ref={DeleteModalRef} className={cx("delete-modal", { 'hide': !shouldShowDeleteModal })}>
                <div className="delete-modal-header delete-modal-div">
                    <img src="/images/alert.svg" className="delete-icon" alt="calendar" />
                    {getLabel("hh_DeletedArticle", "Deleted Article")}
                    <button className="delete-modal-close" onClick={setDialogClosed} title={getLabel("g_Close", "Close")}></button>
                </div>
                {!deleteStarted &&
                    <div align="center">
                        <div className="delete-modal-body">
                            <p>{getLabel("hh_DeletedArticleMesage", "The exact item you are looking for is no longer available.")}</p>
                            {bookmarkPage ?
                                <p>{getLabel("hh_DeletedArticleCountBook", "You made {0} bookmark(s) for this item:").replace('{0}', deletedAnnotationsCount)}</p> :
                                <p>{getLabel("hh_DeletedArticleCount", "You made {0} annotations for this item.").replace('{0}', deletedAnnotationsCount)}</p>
                            }
                        </div>

                        <div>
                            <button
                                className="btn btn-success btn-delete-confirm"
                                onClick={deleteAnnotation}
                            >
                                {viewCount.replace('{0}',
                                    deletedAnnotationsCount)}

                            </button>
                        </div>
                        <div className='delete-container'> { /* eslint-disable-next-line */}
                            <a className='delete-cancel' onClick={navigateToDashboard}>{deleteCount.replace('{0}', deletedAnnotationsCount)}</a>
                        </div>
                    </div>
                }
                {deleteStarted &&
                    <div>
                        <div className="delete-modal-body">
                            {deleteProgress < 100 &&
                                <p>{deleteInProgress}</p>
                            }
                            {deleteProgress === 100 &&
                                <p>{deleteCompleted}</p>
                            }
                            <ProgressBar completed={deleteProgress} bgColor="#0076a8" />
                        </div>
                        {deleteProgress === 100 &&
                            <div align="right" className='delete-close-container'>
                                {/*eslint-disable-next-line*/}
                                <a className='delete-close' onClick={setDialogClosed}>{getLabel("g_Close", "Close")}</a>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

AnnotationsDeletedArticlesModal.defaultProps = {
    setShouldShowDeleteModal: () => { },
    setShouldShowOverlay: () => { },
}

export default AnnotationsDeletedArticlesModal;
