import React, { useLayoutEffect } from 'react'; // , useRef
import { useLocation } from 'react-router';
import { get } from 'lodash';
import { usePageContext } from '../../contexts/PageContext';
import File from './File';
import ShowIf from '../Common/ShowIf';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import Loading from '../Loading/Loading';
import Footnotes from '../Article/Footnotes';
import ContentItem from '../Article/ContentItem';
import Copyright from '../Article/Copyright';
import StructuredArticle from '../Article/StructuredArticle';
import { isFile, isSection, isStructuredArticle } from '../../helpers/getComponentInfo';
import { getIsArticlePdf } from '../Publication/Article';
import HTMLContent from '../Common/HTMLContent';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

export const getOffsetTop = element => {
    let offsetTop = 0;
    while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

export const RenderAllContainer = ({ pubData, excludeBinaries, isScrollDisabled = false }) => { 

    const location = useLocation();
    const content = get(pubData, 'content', []);
    const pubContext = get(pubData,'context',[]);
    const { context } = usePageContext();
    const isSingleTopic = content.length && content.length < 2 && content[0].subItems.length === 0 ? true : false;

    //This is where the code to scroll the page down when we have a # hastag on the page is fired.
    useLayoutEffect(() => {
        if (!pubData?.content) return;

        if (!isScrollDisabled) {
            if (location.hash) {
                //Cancel out the # scroll that the browser would do by default...so we do not get a flash if we actully do not find the target on the page.
                //  the browser will scroll down regardless randomly if there is a # in the path!
                setTimeout(function () {
                    window.scrollTo(0, 0);
                }, 1);
                //Then we attempt to find the # on the page ourselves
                setTimeout(() => { ARTICLEHELPERS.scrollToHashTag(location.hash) }, 10);
            }
            else {
                //There was no #, or it was removed, so we scroll to top by default
                window.scrollTo(0, 0);
            }
        }

    }, [pubData, location.pathname, location.hash]);//eslint-disable-line


    if (!pubData?.content) {
        return (
            <Loading />
        )
    }

    const getComponentArray = () => {

        // declare working arrays
        let footnoteArray = [];
        let calloutArray = [];
        let componentArray = [];

        //rich text title fn tag array
        let footnoteRttArray = [];

        // number generator for footnote extraction
        // this ensure a consistent numbering pattern
        // for all footnotes across all content items
        const fnNumberGenerator = ARTICLEHELPERS.footnoteNumberGenerator();

        //preparing footnotes for rich text title
        if("manual|standards".indexOf(context.pageType) > -1)
        {
           if (pubContext !== null && pubContext.rich_text_title !== null && UTILITIESHELPER.IsTextHavingFnTag(pubContext.rich_text_title)) 
           {
            footnoteRttArray = ARTICLEHELPERS.extractFootnotesFromRTT(pubContext.rich_text_title, fnNumberGenerator);
            footnoteRttArray.forEach((val) => footnoteArray.push(val));
           } 
        }
        else{
            if (content.length > 0 && content[0].content !== null && content[0].content.rich_text_title !== null && UTILITIESHELPER.IsTextHavingFnTag(content[0].content.rich_text_title)) 
            {
             footnoteRttArray = ARTICLEHELPERS.extractFootnotesFromRTT(content[0].content.rich_text_title, fnNumberGenerator);
             footnoteRttArray.forEach((val) => footnoteArray.push(val));
            } 
        }

        // loop through content and extract all footnote callouts
        content.forEach(item => calloutArray = ARTICLEHELPERS.extractFootnoteCallouts(item, calloutArray))

        // loop throught content and populate the componentArray
        content.forEach((item) => {

            if (excludeBinaries && item.containsBinary === true) {
                //For the download, we do not want to incude binary items in the output!
                // extract footnotes and processed content item
                let [
                    extractedFootnotes, //eslint-disable-line
                    processedItem
                ] = ARTICLEHELPERS.extractFootnotes(item, footnoteArray, calloutArray, fnNumberGenerator);

                processedItem?.content && componentArray.push(
                    <File
                        binaryItemGUID={processedItem.id}
                        fileTitle={processedItem?.content?.display_name || processedItem?.content?.title}
                        key={processedItem.id}
                        format={processedItem?.content?.format}
                        date={processedItem.content.current_date}
                        displayName={processedItem.content.display_name === null?"":processedItem.content.display_name}
                        description={processedItem.content.display_name_desc}
                        templateNote={processedItem.content.template_note}
                        bytes={processedItem.content.binary_length}
                        uri={processedItem.content.uri}
                        shouldIndent={false}
                        text={processedItem.content?.text}
                        displayDownload={processedItem.content.display_download}
                    />
                )
            }
            else {
                // extract footnotes and processed content item
                let [
                    extractedFootnotes,
                    processedItem
                ] = ARTICLEHELPERS.extractFootnotes(item, footnoteArray, calloutArray, fnNumberGenerator);
                
                // flatten extracted footnotes and assign
                    footnoteArray = extractedFootnotes.flat();

                //If we're dealing with a file component
                if (isFile(item.content_type, item.containsBinary) && !isSection(item.content_type)) {
                    if (isSingleTopic === false) {

                        if (isStructuredArticle(item.content_type)) {
                            componentArray.push(<StructuredArticle
                                key={processedItem.id}
                                item={processedItem}
                                isEmbedded={false}/>)
                        }
                        else{
                            processedItem?.content &&  componentArray.push(
                                <File
                                    binaryItemGUID={processedItem.id}
                                    fileTitle={processedItem?.content?.display_name || processedItem?.content?.title}
                                    key={processedItem.id}
                                    format={processedItem?.content?.format}
                                    date={processedItem.content.current_date}
                                    displayName={processedItem.content.display_name}
                                    description={processedItem.content.display_name_desc}
                                    templateNote={processedItem.content.template_note}
                                    bytes={processedItem.content.binary_length}
                                    uri={processedItem.content.uri}
                                    shouldIndent={false}
                                    text={processedItem.content.text}
                                    displayDownload={processedItem.content.display_download}
                                />
                            )
                        }

                        
                        for (let i = 0; i < processedItem.subItems.length; ++i) {
                            if (isFile(processedItem.subItems[i].content_type, processedItem.subItems[i].containsBinary) && !isSection(processedItem.subItems[i].content_type)) {
                                componentArray.push(
                                   processedItem && <File
                                        binaryItemGUID={processedItem.subItems[i].id}
                                        fileTitle={processedItem.subItems[i].content.display_name || processedItem.subItems[i].content.title}
                                        key={processedItem.subItems[i].id}
                                        format={processedItem.subItems[i].content.format}
                                        date={processedItem.subItems[i].content.current_date}
                                        displayName={processedItem.subItems[i].content.display_name}
                                        description={processedItem.subItems[i].content.display_name_desc}
                                        templateNote={processedItem.subItems[i].content.template_note}
                                        bytes={processedItem.subItems[i].content.binary_length}
                                        uri={processedItem.subItems[i].content.uri}
                                        hasSubItemsOrSiblings={processedItem.subItems.length > 0 || processedItem.subItems.length > 1}
                                        shouldIndent={false}
                                        text={processedItem.subItems[i].content.text}
                                        displayDownload={processedItem.subItems[i].content.display_download}
                                    />
                                )
                                //Handle subItems of the file, which are more files
                                for (let j = 0; j < processedItem.subItems[i].subItems.length; ++j) {
                                    var parent = processedItem.subItems[i];
                                    if (isFile(parent.subItems[j].content_type, parent.subItems[j].containsBinary) && !isSection(parent.subItems[j].content_type)) {
                                        componentArray.push(
                                            <File
                                                binaryItemGUID={parent.subItems[j].id}
                                                fileTitle={parent.subItems[j].content.display_name || processedItem.subItems[i].content.title}
                                                key={parent.subItems[j].id}
                                                format={parent.subItems[j].content.format}
                                                date={parent.subItems[j].content.current_date}
                                                displayName={parent.subItems[j].content.display_name}
                                                description={parent.subItems[j].content.display_name_desc}
                                                templateNote={parent.subItems[j].content.template_note}
                                                bytes={parent.subItems[j].content.binary_length}
                                                uri={parent.subItems[j].content.uri}
                                                hasSubItemsOrSiblings={processedItem.subItems.length > 0 || processedItem.subItems.length > 1}
                                                shouldIndent={true}
                                                text={parent.subItems[j].content.text}
                                                displayDownload={parent.subItems[j].content.display_download}
                                            />
                                        )
                                    }
                                    else if (isStructuredArticle(parent.subItems[j].content_type)) {
                                        componentArray.push(<StructuredArticle
                                            key={parent.subItems[j].id}
                                            item={parent.subItems[j]}
                                            isEmbedded={false} />)
                                    }
                                    else {
                                        componentArray.push(
                                            <div key={parent.subItems[j].id}><HTMLContent>{parent.subItems[j].content.text}</HTMLContent></div>
                                        )
                                    }
                                }
                            }
                            else if (isStructuredArticle(processedItem.subItems[i].content_type)) {
                                componentArray.push(<StructuredArticle
                                    key={processedItem.id}
                                    item={processedItem}
                                    isEmbedded={false} />)
                            }
                            else {
                                componentArray.push(
                                    <div key={processedItem.subItems[i].id}><HTMLContent>{processedItem.subItems[i].content.text}</HTMLContent></div>
                                )
                            }
                        }
                    }
                    //Search-FTEO--WIP
                    else if (isSingleTopic === true && processedItem.containsBinary === true) {
                        if (isStructuredArticle(item.content_type)) {
                            componentArray.push(<StructuredArticle
                                key={processedItem.id}
                                item={processedItem}
                                isEmbedded={false}
                                footnoteArray={footnoteArray} />)
                        }
                        else {
                            componentArray.push(
                                <File
                                    binaryItemGUID={processedItem.id}
                                    fileTitle={processedItem.content.display_name || processedItem.content.title}
                                    key={processedItem.id}
                                    format={processedItem.content.format}
                                    date={processedItem.content.current_date}
                                    displayName={processedItem.content.display_name}
                                    description={processedItem.content.display_name_desc}
                                    templateNote={processedItem.content.template_note}
                                    bytes={processedItem.content.binary_length}
                                    uri={processedItem.content.uri}
                                    shouldIndent={false}
                                    text={processedItem.content.text}
                                    displayDownload={processedItem.content.display_download}
                                />
                            )
                        }
                    }
                }
                else if (isStructuredArticle(item.content_type)) {
                    componentArray.push(<StructuredArticle
                        key={processedItem.id}
                        item={processedItem}
                        isEmbedded={false}
                        footnoteArray={footnoteArray} />)
                }
                else {
                    componentArray.push(<ContentItem key={processedItem.id} subItem={processedItem} />)
                }
            }
        });

        if (footnoteArray.length > 0) {
            componentArray.push(<Footnotes key="00003" footnoteArray={footnoteArray} />);
        }

        return componentArray;
    }

    return (
        <>
            {getComponentArray()}
            <ShowIf condition={!getIsArticlePdf(pubData)}>
                <Copyright text={pubData.context?.pub_notices_text} />
            </ShowIf>
        </>
    )
};

RenderAllContainer.propsTypes = {
    typeName: "RenderAllContainer"
}

export default RenderAllContainer;
